<template>
  <div class="flex-row content mb-3 " v-loading="loading">
    <div class="mobile-hidden">
      <CustomCard
          v-for="data in seminar_detail_list"
          :key="data.id"
          :data="data"
          :detail_ansicht="true"
      />
    </div>
    <div class="course full-width">
      <div class="fz-25 fw-bold text-start pb-md-3">
        {{ seminar_detail_list[0] ? seminar_detail_list[0].seminar : "" }}
      </div>
      <Table
          :data="seminar_detail_list"
          :showSwitches="false"
          :columns="columns"
          :detailColumns="detailColumns"
          :details_course="true"
      />
     
       <div class="text-start pb-md-3" v-if="zl">
       Zoom Link: <a target="_blank" :href="seminar_detail_list[0].zoom_link">{{seminar_detail_list[0].zoom_link}}</a>
       </div>
       <div class="text-start pb-md-3" v-if="zd" style="white-space: pre-line;">
       <p v-html="seminar_detail_list[0].zoom_daten"></p>
       </div>
    </div>
  </div>
   <div  v-if="seminar_detail_list[0] && seminar_detail_list[0].forum_anzeige===1">
  <CourseQuestion :title="seminar_detail_list[0] ? seminar_detail_list[0].seminar : ''" v-loading="loading"/>
</div>
</template>

<script>
// Home.vue
import _ from "lodash";
import Table from "@/components/Table.vue";
import CourseQuestion from "./CourseQuestion.vue";
import Resource from "@/api/seminare";
import {formatDateDMY} from "@/filters/index";
import CustomCard from "@/components/CustomCard.vue";

const seminareResource = new Resource();

export default {
  components: {
    CourseQuestion,
    Table,
    CustomCard
  },
  data() {
    return {
      
      seminar_detail_list: [],
      columns: [
        {field: "Color", text: ""},
        {field: "datum", text: "Beginn"},
        {field: "endedatum", text: "Ende"},
        {field: "ort", text: "Ort"},
       // {field: "angemeldet", text: "Status"},
      ],
      detailColumns: [
        {field: "Color", text: ""},
        {field: "datum", text: "Beginn"},
        {field: "endedatum", text: "Ende"},
        {field: "ort", text: "Ort"},
        {field: "angemeldet", text: "angemeldet"},
      ],
      loading: true,
      array_color: {yellow: "#f49a01", green: "#007f49", blue: "#2a3579", red: "#bf2327"},
    };
  },
  mounted() {
    if (this.$route.params.dozentId) {
      this.$store.dispatch("user/checkCourseDozent", this.$route.params.dozentId);
    }
    if (this.$route.params.ds_id) {
      this.$store.dispatch("user/checkCourseDsId", this.$route.params.ds_id);
    }
    if (this.$route.params.angemeldet) {
      this.$store.dispatch("user/checkCourseAngemeldet", this.$route.params.angemeldet);
    }
   // this.fetchData();
    this.getSeminarDetailByKurs(this.$route.params.id);
  },
  computed: {
    // username() {
    //   return this.$route.params.username;
    // },
  }, 
  created() {
    if (typeof this.$store.state.user.token == 'undefined' || this.$store.state.user.token === '') {
      this.$router.push({name: "login"});
    }
  },
  methods: {
   /* fetchData() {
      seminareResource.get(this.$route.params.id).then((res) => {
        console.log(res);
      }).catch((error) => {
        console.log('er', error);
        const code = _.get(error, 'response.data.messages[0].code', 0);
        if (code == 952) {
          this.$router.push({name: "login"});
        }
      });
    },*/
    getSeminarDetailByKurs(kurs_id) {
      this.loading = true;
      seminareResource.getSeminarByKurs({kurs_id}).then((res) => {
        if (res.response && res.response.data) {
          
          this.zl= res.response.data[0].fieldData['zoom_link'];
          this.zd= res.response.data[0].fieldData['zoom_daten'];
          this.seminar_detail_list = res.response.data.map((value) => {
            return {
              seminar: value.fieldData['Bezeichnung'],
              seminarart: value.fieldData['seminarart'],
              seminarleiter: value.fieldData['Seminarleiter'],
              datum: formatDateDMY(value.fieldData['Datum']),
              endedatum: formatDateDMY(value.fieldData['Endedatum']),
              ort: value.fieldData['Ort'],
              zoom_link: value.fieldData['zoom_link'],
              zoom_daten: value.fieldData['zoom_daten'],
              forum_anzeige: value.fieldData['online_forum'],
              status_color: value.fieldData['seminar_search_filter::color'] ? this.array_color[value.fieldData['seminar_search_filter::color']] : "",
              id: value.fieldData['ID_Kurs'],
              image: value.fieldData['felder_web::pfad'],
              angemeldet: this.$store.state.user.course_angemeldet,
              warteliste: this.$store.state.user.warteliste
            };
            
          });

          this.$store.dispatch("user/setEndDateCourse", res.response.data[0].fieldData['Endedatum']);
        }
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        console.log('er', error);
        const code = _.get(error, 'response.data.messages[0].code', 0);
        if (code == 952) {
          this.$router.push({name: "login"});
        }
      });
    },
    goToQA() {
      // this.$router.push({
      //   name: "courseQuestion",
      //   params: { id: this.$route.params.id },
      // });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.course {
  padding: 0 2rem;
}

.course div.col-6 {
  padding: 0rem 0 0.5rem 2rem;
  margin-top: -0.3rem;
}

div.card {
  width: 15rem;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .mobile-hidden {
    display: none;
  }
}
</style>