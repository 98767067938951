<template>
  <div class="courses content mb-4" v-loading="loading">
    <h2 class="fw-bold d-sm-block d-none mb-3 font-Cavolini">Stunden für alle meine Kurse</h2>
    <h3 class="fw-bold d-sm-none d-block font-Cavolini">Stunden für alle meine Kurse</h3>

    <Table_noclick
        :data="data"
        :columns="columns"
        :pagination="query"
        :detailColumns="detailColumns"
        :showSwitches=false
        @fetchData="fetchData"
        @changeQuery="changeQuery"
        :showPrice=true
        :showSizes=true
    />
  </div>
  <div class="dozent content" v-loading="loadingDozent" v-if="$store.state.user.dozent_id">
    <h2 class="fw-bold d-sm-block d-none mb-3 font-Cavolini">Termine in denen ich Dozent bin</h2>
    <h3 class="fw-bold d-sm-none d-block font-Cavolini">Termine in denen ich Dozent bin</h3>
    <Table_noclick
        :data="dataDozent"
        :columns="columns"
        :showSwitches=false
        @onClick="onClick"
        :detailColumns="detailColumns"
        :pagination="queryDozent"
        @fetchData="fetchDataDozent"
        @changeQuery="changeQuery"
        :showPrice=false
        :showSizes=true
    />
  </div>
</template>
<script>
// Home.vue
import _ from "lodash";
import Table_noclick from "@/components/Table_noclick.vue";
import Resource from "@/api/seminare";
import {formatDateDMY, formatDateHI} from "@/filters/index";

const seminareResource = new Resource();

export default {
  components: {
    Table_noclick,
  },
  data() {
    return {
      data: [],
      dataDozent: [],
      query:
          {
            page: 1,
            limit: 50,
            total: 0,
            teilnehmer_id: this.$store.state.user.id_person,
          },
      queryDozent:
          {
            page: 1,
            limit: 50,
            total: 0,
            dozent_id: this.$store.state.user.dozent_id
          },
      columns: [
        {field: "Color", text: ""},
        {field: "datum", text: "Datum"},
        {field: "seminar", text: "Seminar"},
        {field: "thema_lang", text: "Thema"},
        {field: "dozent", text: "Dozent"},
        {field: "uhrzeit", text: "Beginn"},
        {field: "zeit2", text: "Ende"},
        {field: "raum", text: "Raum"},
      ],
      detailColumns: [
        {field: "Color", text: ""},
        {field: "datum", text: "Datum"},
        {field: "seminar", text: "Seminar"},
        {field: "thema_lang", text: "Thema"},
        {field: "dozent", text: "Dozent"},
        {field: "uhrzeit", text: "Beginn"},
        {field: "zeit2", text: "Ende"},
        {field: "raum", text: "Raum"},
      ],
      loading: true,
      loadingDozent: true,
      array_color:
          {
            yellow: "#f49a01",
            green: "#007f49",
            blue: "#2a3579",
            red: "#bf2327"
          },
    };
  },
  computed: {
    username() {
      return this.$route.params.username;
    },
  },
  created() {
    if (typeof this.$store.state.user.token == 'undefined' || this.$store.state.user.token === '') {
      this.$router.push({name: "login"});
    }
    this.fetchData(this.query);
    this.fetchDataDozent(this.queryDozent);
  },
  methods: {
    changeQuery(limit){
      this.query.limit = limit;
      this.queryDozent.limit = limit;
    },
    fetchData(query) {
      this.loading = true;
      const offset = (query.page - 1) * query.limit + 1;
      query.offset = offset;
      seminareResource.getAppointmentByUser(query).then((res) => {
        this.query = _.merge(this.query, query);
        this.query.total = _.get(res, "response.dataInfo.foundCount", 0);
        console.log(res);
        if (res.response && res.response.data) {
          var dataarray = res.response.data[0].portalData.termine;
          this.data = dataarray.map((value) => {
            return {
              id: value['SV_Termine::ds_id'],
              status_color: value['SV_Termine::color'] ? this.array_color[value['SV_Termine::color']] : "",
              datum: formatDateDMY(value['SV_Termine::datum']),
              seminar: value['SV_Termine::seminar'],
              thema_lang: value['SV_Termine::thema_lang'],
              dozent: value['SV_Termine::dozent'],
              uhrzeit: formatDateHI(value['SV_Termine::zeit']),
              zeit2: formatDateHI(value['SV_Termine::zeit2']),
              raum: value['SV_Termine::raumname'],
            };
          });
        }
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        console.log("er", error);
        const code = _.get(error, "response.data.messages[0].code", 0);
        if (code == 952) {
          this.$router.push({name: "login"});
        }
      });
    },
    fetchDataDozent(query) {
      this.loadingDozent = true;
      const offset = (query.page - 1) * query.limit + 1;
      query.offset = offset;
      if (this.queryDozent.dozent_id) {
        seminareResource.getAppointmentByDozent(query).then((res) => {
          this.queryDozent = _.merge(this.queryDozent, query);
          this.queryDozent.total = _.get(res, "response.dataInfo.foundCount", 0);
          if (res.response && res.response.data) {
            this.dataDozent = res.response.data.map((value) => {
              return {
                'id': value.fieldData['ds_id'],
                'status_color': value.fieldData['seminar_search_filter::color'] ? this.array_color[value.fieldData['seminar_search_filter::color']] : "",
                'datum': formatDateDMY(value.fieldData['datum']),
                'seminar': value.fieldData.seminar,
                'thema_lang': value.fieldData.thema_lang,
                'dozent': value.fieldData['dozent'],
                'uhrzeit': formatDateHI(value.fieldData['zeit']),
                'zeit2': formatDateHI(value.fieldData['zeit2']),
                'ort': value.fieldData['raum']
              };
            });
          }
          this.loadingDozent = false;
        }).catch((error) => {
          this.loadingDozent = false;
          console.log('er', error);
          const code = _.get(error, 'response.data.messages[0].code', 0);
          if (code == 952) {
            this.$router.push({name: "login"});
          }
        });
      }
    },
    onClick(row) {
      console.log("data", row);
      // this.$router.push({ name: "courseDetail", params: { id: row.id } });
    },
  },
};
</script>
