<template>

  <div v-for="item in data" :key="item.id" class="inhalt mb-2 p-3 px-md-5">
    <div class="align-content-start row p-1 px-4">
      <span class="col-auto fs-4 p-0 fw-bold">
        {{ item.seminar }}
      </span>
    </div>

    <div class="row mt-2 mx-0">
      <div class="row col-12 col-lg-6 mx-0">
        <div class="col-4 px-0">
          <div class="grey p-2 text-start h-38px w-100">
          <span class="">
            Kursdaten
          </span>
          </div>
        </div>
        <div class="col-8 px-0">
          <div class="p-2 grey-border mb-2 h-38px white-background w-100">
          <span class="fw-bold">
            {{ item.Kursdaten }}
          </span>
          </div>
        </div>

        <div class="col-4 px-0">
          <div class="grey p-2 text-start h-38px w-100">
          <span class="">
            Ort
          </span>
          </div>
        </div>
        <div class="col-8 px-0">
          <div class="p-2 grey-border mb-2 h-38px white-background w-100">
          <span class="fw-bold">
            {{ item.ort }}
          </span>
          </div>
        </div>

        <div class="col-4 px-0">
          <div class="grey p-2 text-start h-38px w-100">
          <span class="font-size-mobile">
            Unterrichtszeit
          </span>
          </div>
        </div>
        <div class="col-8 px-0">
          <div class="p-2 grey-border mb-2 h-38px white-background w-100">
          <span class="fw-bold">
            {{ item.unterrichtszeiten }} Uhr
          </span>
          </div>
        </div>

        <div class="col-4 px-0">
          <div class="grey p-2 px-2 text-start mb-2 h-38px">
          <span>
            Freie Plätze
          </span>
          </div>
        </div>
        <div class="col-8 px-0">
          <div class="p-2 grey-border mb-2 h-38px px-sm-3 white-background">
            <div class="fw-bold">
              <div v-if="item.online == '1'" class="row align-items-center justify-content-center">
                <div class="col-12 col-sm-6 text-center">
                  <span class="ps-1"> Hybrid:</span>
                  <span class="dot green ms-2"
                        v-if="item.teilnehmer_hybrid_max - item.teilnehmer_hybrid_number > 0">&nbsp;</span>
                 <!-- <span class="dot yellow text-center ms-2"
                        v-else-if="item.teilnehmer_hybrid_max - item.teilnehmer_hybrid_number <= 5 && item.teilnehmer_hybrid_max - item.teilnehmer_hybrid_number >= 1">{{
                      item.teilnehmer_hybrid_max - item.teilnehmer_hybrid_number
                    }}</span>-->
                  <span 
                        v-else-if="item.teilnehmer_hybrid_max - item.teilnehmer_hybrid_number <= 0">WL</span>
                </div>
                <div class="col-12 col-sm-6 text-lg-start text-center">
                  <span class="ps-1">Online:</span>
                  <span class="dot green ms-2"
                        v-if="item.teilnehmer_online_max - item.teilnehmer_online_number > 5">&nbsp;</span>
                <!--  <span class="dot yellow text-center ms-2"
                        v-else-if="item.teilnehmer_online_max - item.teilnehmer_online_number <= 5 && item.teilnehmer_online_max - item.teilnehmer_online_number >= 1">{{
                      item.teilnehmer_online_max - item.teilnehmer_online_number
                    }}</span>-->
                  <span 
                        v-else-if="item.teilnehmer_online_max - item.teilnehmer_online_number <= 0">WL</span>
                </div>
              </div>
              <div v-else-if="item.online == '2'">
                Online:
                <span class="dot green ms-2"
                      v-if="item.teilnehmer_online_max - item.teilnehmer_online_number > 5">&nbsp;</span>
                <!--<span class="dot yellow text-center ms-2"
                      v-else-if="item.teilnehmer_online_max - item.teilnehmer_online_number <= 5 && item.teilnehmer_online_max - item.teilnehmer_online_number >= 1">{{
                    item.teilnehmer_online_max - item.teilnehmer_online_number
                  }}</span>-->
                <span 
                      v-else-if="item.teilnehmer_online_max - item.teilnehmer_online_number <= 0">WL</span>
              </div>
              <div v-else-if="item.online == '3'">
                Vor Ort:
                <span class="dot green ms-2"
                      v-if="item.teilnehmer_hybrid_max - item.teilnehmer_hybrid_number > 5">&nbsp;</span>
                <!--<span class="dot yellow text-center ms-2"
                      v-else-if="item.teilnehmer_hybrid_max - item.teilnehmer_hybrid_number <= 5 && item.teilnehmer_hybrid_max - item.teilnehmer_hybrid_number >= 1">{{
                    item.teilnehmer_hybrid_max - item.teilnehmer_hybrid_number
                  }}</span>-->
                <span 
                      v-else-if="item.teilnehmer_hybrid_max - item.teilnehmer_hybrid_number <= 0">WL</span>
              </div>
            </div>
          </div>
        </div>

      </div>

      <!--      Zweite Spalte-->
      <div class="row col-12 col-lg-6 mx-0">
        <div class="col-4 px-0">
          <div class="grey p-2 text-start h-38px w-100">
          <span class="">
            Vor Ort/ Online
          </span>
          </div>
        </div>
        <div class="col-8 px-0">
          <div class="mb-2" v-if="item.online == '1'">
            <select v-model="item.online_check_value"
                    class="w-100 grey-border p-2 h-38px fw-bold text-center">
              <option value="0">Bitte auswählen*</option>
              <option value="1">Hybrid</option>
              <option value="2">Online</option>
            </select>
          </div>
          <div class="mb-2 grey-border p-2 h-38px fw-bold text-center white-background"
               v-else-if="item.online == '2'">
            <span>Online Veranstaltung</span>
          </div>
          <div class="mb-2 grey-border p-2 h-38px fw-bold text-center white-background"
               v-else-if="item.online == '3'">
            <span>Vor Ort Veranstaltung</span>
          </div>
        </div>

        <div class="col-4 px-0">
          <div class="grey p-2 px-2 text-start mb-2 h-38px w-100">
          <span>
            Zahlart
          </span>
          </div>
        </div>
        <div class="col-8 px-0">
          <div class="fw-bold">
            <select v-model="item.payment_value" class="w-100 grey-border p-2 h-38px fw-bold text-center">
              <option value="0">Bitte auswählen*</option>
              <option value="invoice">Rechnung</option>
              <option value="ratenzahlung" v-if="item.rate_gesamt !== ''">Ratenzahlung</option>
              <option value="no-credits" v-if="item.credits !== '' && item.check_payment_credits === 'invoice'"
                      :disabled="item.check_payment_credits === 'invoice'">
                Nicht genügend Credits
              </option>
              <option value="credits" v-else-if="item.credits !== '' && item.check_payment_credits === 'credits'">
                Credits
              </option>
            </select>
          </div>
        </div>
        <div class="col-4 px-0">
          <div class="grey p-2 px-2 text-start mb-2 h-38px">
          <span class="">
            Kosten
          </span>
          </div>
        </div>
        <div class="col-8 px-0">
          <div class="p-2 grey-border mb-2 h-38px white-background">
            <div class="fw-bold">
              <div v-if="item.payment_value === 'invoice'">
                <div v-if="item.online_check_value == '1' || item.online == '3'">
                <span>
                  {{ item.gebuehr }} €
                </span>
                </div>
                <div v-else-if="item.online_check_value == '2' || item.online == '2'">
                <span>
                  {{ item.gebuehr - (item.gebuehr * item.rabatt / 100) }} €
                </span>
                </div>
                <div v-else>
                <span>
                  Bitte Zahlart auswählen
                </span>
                </div>
              </div>
              <div v-else-if="item.payment_value === 'ratenzahlung'">
                <div v-if="item.online_check_value == '1' || item.online == '3'">
                <span>
                  {{ item.rate_anzahl }} x {{ item.rate_monatlich }} € = {{ item.rate_gesamt }} €
                </span>
                </div>
                <div v-else-if="item.online_check_value == '2' || item.online == '2'">
                <span>
                   {{ item.rate_anzahl }} x {{ item.rate_monatlich - (item.rate_monatlich * item.rabatt / 100) }} € = {{
                    item.rate_gesamt - (item.rate_gesamt * item.rabatt / 100)
                  }} €
                </span>
                </div>
                <div v-else>
                <span>
                  Bitte Zahlart auswählen {{ item.online }}
                </span>
                </div>
              </div>
              <div v-else-if="item.payment_value === 'credits'">
                Credits: {{ item.credits }}
              </div>
              <div v-else>
                Bitte zuerst Optionen auswählen
              </div>
            </div>
          </div>
        </div>

        <div v-if="item.register_check === 'unregistered'">
          <div v-if="item.payment_value !== '0' && item.online_check_value !== '0'">

            <input class="w-100 h-38px fw-bold btn-color"
                   type="button" value="In den Warenkorb" @click="addtoCart(item)">
          </div>
          <div v-else>
            <input class="w-100 h-38px fw-bold btn-color"
                   type="button" value="Bitte Option auswählen" disabled>
          </div>
        </div>
        <div v-else>
          <input class="w-100 h-38px fw-bold btn-color"
                 type="button" value="Sie sind diesem Seminar bereits zugewiesen" disabled>
        </div>
      </div>
    </div>
    <details v-if="item.terminliste_online != '' && item.online_termine===1">
      <summary class="text-start px-3 fs-5">Termine</summary>
      <div v-html="item.terminliste_online" class="text-start px-3 mt-2 fs-5">
      </div>
    </details>
  </div>
  <el-config-provider :locale="locale">
    <div v-if="pagination!=null" class="block pt-3">
      <el-pagination v-if="showSizes"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     v-model:currentPage="currentPage"
                     :page-sizes="[10, 20, 50, 100]"
                     :page-size="pagination.limit"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="pagination.total"/>
      <el-pagination v-else
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     v-model:currentPage="currentPage"
                     :page-sizes="[10, 20, 50, 100]"
                     :page-size="pagination.limit"
                     layout="total, prev, pager, next, jumper"
                     :total="pagination.total"/>
    </div>
  </el-config-provider>
</template>

<script>

import {defineComponent} from 'vue';
import deLang from 'element-plus/lib/locale/lang/de';

export default defineComponent({
  name: "FurtherCoursesPricingTable",
  components: {
    // SvgIcon,
    // CloseIcon,
    // CustomSwitch
  },
  setup() {
    return {
      locale: deLang,
    }
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    detailColumns: {
      type: Array,
      required: true,
    },
    showSwitches: {
      type: Boolean,
      default: true,
    },
    fetchData: {
      type: Function,
      default: null,
    },
    pagination: {
      type: Object,
      default: () => {
        return null;
      },
    },
    showPrice: {
      type: Boolean,
      default: true,
    },
    showSizes: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      listView: true,
      selectedIndex: -1,
      currentPage: 1,
    };
  },
  methods: {
    handleSizeChange(val) {
      // console.log('this: page', this.pagination);
      this.$emit('fetchData', {...this.pagination, limit: val});
    },
    handleCurrentChange(val) {
      // console.log('this: page', this.pagination);
      this.$emit('fetchData', {...this.pagination, page: val});
    },
    unSelected() {
      this.selectedIndex = -1;
    },
    onClick(row, column, index) {
      console.log("selected : ", row, column, index, window.innerWidth);
      if (window.innerWidth > 575) {
        if (column.field == "seminar") {
          this.$emit("onClick", row);
        }
      } else {
        this.selectedIndex = index;
        console.log("selected Index: ", this.selectedIndex);
      }
    },
    emitEvent(row) {
      this.$emit("onClick", row);
    },
    changeView(view = false) {
      this.listView = view;
    },
    removeCart(id) {
      this.$emit("onRemove", id);
    },
    addtoCart(row) {
      this.$emit("addCourseToCart", row);
    }
  },
});
</script>

<style scoped>

.inhalt {
  border-radius: 4rem;
  border: solid 0.8rem #464b5c;
}

.col-auto {
  padding-right: 0;
}

.dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
}

.green {
  background-color: #007f49;
}

.yellow {
  background-color: #F7D358;
}

.orange {
  background-color: #ff8800;
}

.grey {
  background-color: #464b5c;
  color: #FFFFFF;
}

.grey-border {
  border: solid #464b5c 1.5px;
}

.h-38px {
  height: 38px;
}

.btn-color {
  background-color: #c4c08e;
  border: #c4c08e;
}

.white-background {
  background-color: #FFFFFF;
}

@media (max-width: 767px) {
  .font-size-mobile {
    font-size: 7px;
  }

}

@media (max-width: 425px) {
  .dot {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
  }
}

</style>
