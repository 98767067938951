<template>
  <div class="content-weitere-kurse">
    <div class="text-end pb-3">
      <button type="button" class="btn dark-label btn-secondary" style="border-radius: 8px" title="zurück"
              @click="returnToFurtherCourse(online_filter)">
        <i class="bi bi-arrow-return-left"/>
      </button>
    </div>
    <div>
      <OnlineGroupDetailView
          id="onlineGroupDetails"
          :data="onlineGroupData"
          :dozenten="dozentenData"
      />
    </div>

    <div class="course full-width pt-4 pb-2" v-loading="loading">
      <div class="courses mb-4">

        <!--        <input type="text" id="inputSearchSeminar" @keyup="mySearchTableFunction()" class="form-control"-->
        <!--               placeholder="Suche nach seminar.." title="Geben Sie einen Seminarnamen ein">-->

        <FurtherCoursesPricingTable
            id="register_course"
            :data="data_list_seminar"
            :columns="columns"
            :pagination="null"
            :detailColumns="detailColumns"
            :showSwitches=false
            @fetchData="getSeminarOnlineGroupId"
            :showPrice=false
            :showSizes=false
            @addCourseToCart="addCourseToCart"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Home.vue
import _ from "lodash";
import Resource from "@/api/seminare";
// import Table from "@/components/Table.vue";
import FurtherCoursesPricingTable from "@/views/courses/components/FurtherCoursesPricingTable";
import OnlineGroupDetailView from "./components/OnlineGroupDetailView";
import {formatDateDMY, formatDateHI, getCurrentDate} from "@/filters/index";
// import {formatDateDMY, formatDateHI, getCurrentDate, convertDate} from "@/filters/index";

const seminareResource = new Resource();

export default {
  components: {
    // Table,
    FurtherCoursesPricingTable,
    OnlineGroupDetailView
  },
  data() {
    return {
      query: {
        page: 1,
        limit: 50,
        total: 0,
        online_gruppe_id: this.$route.params.online_gruppe_id,
        tn_id: this.$store.state.user.id_person
      },
      onlineGroupId: this.$route.params.online_gruppe_id,
      current_cart: this.$store.state.user.cart_course,
      current_date: getCurrentDate(),
      array_color: {green: "#007f49", blue: "#2a3579", yellow: "#f49a01", red: "#bf2327"},
      data: [],
      onlineGroupData: [],
      dozentenData: [],
      data_list_seminar: [],
      columns: [
        // { field: "id", text: "Id" },
        {field: "seminar", text: "Seminar"},
        {field: "dauer", text: "Dauer"},
        {field: "unterrichtszeiten", text: "Unterrichtszeiten"},
        {field: "ort", text: "Ort"},
        {field: "Kursdaten", text: "Kursdaten"},
        {field: "online", text: "Online"},
        {field: "gebuehr", text: "Gebühr"},
        {field: "rate_gesamt", text: "Rate"},
        {},
        {field: "rate_anzahl", text: "Rate Anzahl"},
        {field: "credits", text: "Credits"},
        {field: "check_payment_credits", text: "Bezahlung"},
        {field: "register_check", text: "Action"}
      ],
      detailColumns: [
        {field: "seminar", text: "Seminar"},
        {field: "dauer", text: "Dauer"},
        {field: "unterrichtszeiten", text: "Unterrichtszeiten"},
        {field: "ort", text: "Ort"},
        {field: "Kursdaten", text: "Kursdaten"},
        {field: "online", text: "Online"},
        {field: "gebuehr", text: "Gebühr"},
        {field: "rate_gesamt", text: "Rate"},
        {field: "rate_monatlich", text: "Rate Monatlich"},
        {field: "rate_anzahl", text: "Rate Anzahl"},
        {field: "credits", text: "Credits"},
        {field: "check_payment_credits", text: "Bezahlung"},
        {field: "register_check", text: "Action"}
      ],
      loading: false,
      online_filter: '',
      color_filter: '',
      array_filter: {green: "1", blue: "2", yellow: "3", red: "4"},
      schuelerpreis: '',
      value4: true,
    };
  },
  mounted() {
    // this.getSeminarDetailByKurs(this.$route.params.id);
    this.getOnlineGroupDetailsById(this.onlineGroupId);
    this.getSeminarOnlineGroupId(this.query);
    this.color_filter = this.$route.params.status;
    this.online_filter = this.$route.params.color;
    this.schuelerpreis = this.$store.state.user.schuelerpreis;
  },
  created() {
    if (typeof this.$store.state.user.token == 'undefined' || this.$store.state.user.token === '') {
      this.$router.push({name: "login"});
    }
  },
  computed: {
    username() {
      return this.$route.params.username;
    },
  },
  methods: {
    getSeminarDetailByKurs(kurs_id) {
      this.loading = true;
      seminareResource.getSeminarByKurs({kurs_id}).then((res) => {
        if (res.response && res.response.data) {
          let data_res = res.response.data[0];
          this.data = {
            'id': data_res.fieldData['ID_Kurs'],
            'status': data_res.fieldData['seminar_search_filter::color'] ? this.array_color[data_res.fieldData['seminar_search_filter::color']] : "",
            'seminar': data_res.fieldData['Bezeichnung'],
            'dozent': data_res.fieldData['Seminarleiter'],
            'start_datum': formatDateDMY(data_res.fieldData['Datum']),
            'end_datum': formatDateDMY(data_res.fieldData['Endedatum']),
            'ort': data_res.fieldData['Ort'],
            'seminarart': data_res.fieldData['seminarart'],
            'image': data_res.fieldData['felder_web::pfad'],
          };
          this.online_filter = data_res.fieldData['seminar_search_filter::color'];
        }
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        console.log('er', error);
        const code = _.get(error, 'response.data.messages[0].code', 0);
        if (code == 952) {
          this.$router.push({name: "login"});
        }
      });
    },
    getSeminarOnlineGroupId(query) {
      this.loading = true;
      const offset = (query.page - 1) * query.limit + 1;
      query.offset = offset;
      seminareResource.getSeminarByOnlineGroup(query).then((res) => {
        this.query = _.merge(this.query, query);
        this.query.total = _.get(res, "response.dataInfo.foundCount", 0);
        if (res.response && res.response.data) {
          res.response.data.map((value) => {
              var temp = {
                id: value.fieldData['ID_Kurs'],
                seminar: value.fieldData['Bezeichnung'],
                dauer: value.fieldData['Stundenzahl'] ? value.fieldData['Stundenzahl'] + ' Stunden' : '',
                unterrichtszeiten: value.fieldData['Zeit'] ? formatDateHI(value.fieldData['Zeit']) + ' - ' + formatDateHI(value.fieldData['Zeit_ende']) : "",
                ort: value.fieldData['Ort'],
                Kursdaten: formatDateDMY(value.fieldData['Datum']) ? formatDateDMY(value.fieldData['Datum']) + ' - ' + formatDateDMY(value.fieldData['Endedatum']) : 'jederzeit buchbar',
                online: value.fieldData['online'],
                online_grp_id: value.fieldData['online_gruppe_id'],
                online_check_value: value.fieldData['online'] == '1' ? '0' : value.fieldData['online'] == '3' ? '1' : value.fieldData['online'],
                credits: value.fieldData['kosten_credits'],
                gebuehr: this.schuelerpreis ? value.fieldData['Gebuehr_schuelerpreis'] : value.fieldData['Gebuehr_extern'],
                rate_gesamt: this.schuelerpreis ? value.fieldData['Gebuehr_schuelerpreis_rate_gesamt'] : value.fieldData['Gebuehr_extern_rate_gesamt'],
                rate_monatlich: this.schuelerpreis ? value.fieldData['Gebuehr_schuelerpreis_rate_monatlich'] : value.fieldData['Gebuehr_extern_rate_monatlich'],
                rate_anzahl: this.schuelerpreis ? value.fieldData['Gebuehr_schuelerpreis_rate_anzahl'] : value.fieldData['Gebuehr_extern_rate_anzahl'],
                check_payment_credits: value.fieldData['check_payment_credits'],
                payment_value: '0',
                rabatt: value.fieldData['online_prozent'],
                action_cart: value.fieldData['ID_Kurs'],
                teilnehmer_max: value.fieldData['TeilnehmerMax'],
                teilnehmer_number: value.fieldData['F_AnzahlTeilnehmer'],
                teilnehmer_online_max: value.fieldData['Teilnehmer_Online'],
                teilnehmer_online_number: value.fieldData['F_AnzahlTeilnehmer_Online'],
                teilnehmer_hybrid_max: value.fieldData['Teilnehmer_Hybrid'],
                teilnehmer_hybrid_number: value.fieldData['F_AnzahlTeilnehmer_Hybrid'],
                register_check: value.fieldData['register_check'],
                start_date: formatDateDMY(value.fieldData['Datum']),
                end_date: formatDateDMY(value.fieldData['Endedatum']),
                start_time: value.fieldData['Zeit'] ? formatDateHI(value.fieldData['Zeit']) : "",
                end_time: value.fieldData['Zeit_ende'] ? formatDateHI(value.fieldData['Zeit_ende']) : "",
                strasse: value.fieldData['Strasse'],
                plz: value.fieldData['plz'],
                seminarort: value.fieldData['seminarort'],
                raum: value.fieldData['Raum'],
                online_termine: value.fieldData['online_termine'],
                terminliste_online: value.fieldData['terminliste_online'],
              };
              this.data_list_seminar.push(temp);
          });
        }
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        console.log('er', error);
        const code = _.get(error, 'response.data.messages[0].code', 0);
        if (code == 952) {
          this.$router.push({name: "login"});
        }
      });
    },
    addCourseToCart(data_course) {
      this.$confirm(
          "Möchten Sie diesen Kurs in Ihren Warenkorb legen?",
          "In den Warenkorb legen",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Abbrechen",
            type: "success",
          })
          .then(() => {
            console.log(data_course);
            let current_cart = this.current_cart;
            if (data_course.id) {
              if (current_cart.length) {
                var check_result = current_cart.map(function (e) {
                  return e.id;
                }).indexOf(data_course.id);
                if (check_result != '-1') {
                  this.$message({
                    type: "warning",
                    message: "Der Kurs ist bereits in Ihrem Warenkorb",
                  });
                } else {
                  this.current_cart.push(data_course);
                  this.$store.dispatch("user/addCourseToCart", current_cart);
                  this.$message({
                    type: "success",
                    message: "Kurs erfolgreich in Ihren Warenkorb gelegt",
                  });
                }
              } else {
                this.current_cart.push(data_course);
                this.$store.dispatch("user/addCourseToCart", current_cart);
                this.$message({
                  type: "success",
                  message: "Kurs erfolgreich in Ihren Warenkorb gelegt",
                });
              }
            }
          }).catch(() => {
      });
    },
    removeCart() {
      this.$store.dispatch("user/removeCart");
    },
    returnToFurtherCourse(online_filter) {
      var color_value = online_filter ? online_filter : 'green';
      this.$router.push({name: "furtherCourses", params: {online_filter: this.array_filter[color_value]}});
    },
    mySearchTableFunction() {
      var input, filter, table, tr, td, i, txtValue;
      input = document.getElementById("inputSearchSeminar");
      filter = input.value.toUpperCase();
      table = document.getElementById("register_course");
      tr = table.getElementsByTagName("tr");
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td")[0];
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }
      }
    },
    getOnlineGroupDetailsById(onlineGroupId) {
      this.loading = true;

      seminareResource.getOnlineGroupDetails({onlineGroupId}).then((res) => {

        if (res['check'] == 1) {

          res['dozenten'].map((value) => {
            var temp_doz = {
              record_id: value['recordId'],
              v_name: value['SV_Dozenten_onlinegruppe::DVorname'],
              n_name: value['SV_Dozenten_onlinegruppe::DNachname'],
              doz_id: value['SV_Dozenten_onlinegruppe::dozent_id'],
              beschreibung: value['SV_Dozenten_onlinegruppe::portal_beschreibung'],
              profilbild: value['SV_Dozenten_onlinegruppe::portal_profilbild']
            }
            this.dozentenData.push(temp_doz);
          })

          res['daten']['color'] = res['daten']['seminar_search_filter_seminare_onlinegruppe::color'];

          this.onlineGroupData.push(res['daten']);
        } else {
          console.log('error');
          console.log(res['errormsg']);
        }
      })
    }
  },
}
;
</script>
<style scoped>
.course {
  padding: 0 2rem;
}

.course div.col-6 {
  padding: 0rem 0 0.5rem 2rem;
  margin-top: -0.3rem;
}

div.card {
  width: 15rem;
  display: flex;
  flex-direction: column;
}

.dark-label {
  background: #464b5c;
  color: #fff;
  font-size: 1.2rem;
  line-height: 1.4rem;
}

.dark-label.btn {
  font-size: 1rem;
  line-height: 1rem;
  transition: all 0.3s;
}

.dark-label.btn:hover {
  /* opacity: 0.9; */
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
}

/* .custom-switch .el-switch__core {
  width: 10rem!important;
} */
.custom-switch .el-switch__label--left {
  margin-right: -15px !important;
}

.custom-switch .el-switch__label--right {
  margin-left: -15px !important;
}

</style>