import request from '@/utils/request';
import store from '@/store';
import _ from "lodash";
import { ElMessage } from 'element-plus';

/**
 * Simple RESTful resource class
 */
class SeminareResource {

  constructor() {
    this.uri = process.env.VUE_APP_HOST_REMOTE_API;
  }
  list(offset = 0, limit = 10) {
    return request({
      url: '/seminars',
      method: 'get',
      params: {_offset: offset, _limit: limit},
    });
  }
  get(id) {
    return request({
      url: '/seminar/' + id,
      method: 'get',
    });
  }
  slug(title, id = '') {
    var url = this.uri + '/slug/' + title;
    if (id != null && id !== ''){
      url += '/' + id;
    }
    return request({
      url: url,
      method: 'get',
    });
  }
  store(resource) {
    return request({
      url: '/' + this.uri,
      method: 'post',
      data: resource,
    });
  }
  update(id, resource) {
    return request({
      url: '/' + this.uri + '/' + id,
      method: 'put',
      data: resource,
    });
  }
  partialUpdate(id, resource) {
    return request({
      url: '/' + this.uri + '/' + id,
      method: 'patch',
      data: resource,
    });
  }
  destroy(id) {
    return request({
      url: '/' + this.uri + '/' + id,
      method: 'delete',
    });
  }
  save(resource) {
    if (!resource.id || resource.id < 1){
      return request({
        url: '/' + this.uri,
        method: 'post',
        data: resource,
      });
    } else {
      return request({
        url: '/' + this.uri + '/' + resource.id,
        method: 'put',
        data: resource,
      });
    }
  }
  import(dataList) {
    return request({
      url: '/' + this.uri + '/import',
      method: 'post',
      data: dataList,
    });
  }
  exportList(query) {
    return request({
      url: '/' + this.uri + '/exportList',
      method: 'get',
      params: query,
    });
  }
  getSeminarsByUserIdSignIn(resource) {
    return request({
      url: '/get-seminars-by-user-sign-in',
      method: 'post',
      data: resource,
    });
  }
  getSeminarsByUserIdSignedOff(resource) {
    return request({
      url: '/get-seminars-by-user-signed-off',
      method: 'post',
      data: resource,
    });
  }
  getSeminarsByDozent(resource) {
    return request({
      url: '/get-seminars-by-dozent',
      method: 'post',
      data: resource,
    });
  }

  getAppointmentByUser(resource) {
    return request({
      url: '/get-appointment-by-user',
      method: 'post',
      data: resource,
    });
  }

  getAppointmentByDozent(resource) {
    return request({
      url: '/get-appointment-by-dozent',
      method: 'post',
      data: resource,
    });
  }

  getAppointmentByKurs(resource) {
    return request({
      url: '/get-appointment-by-kurs',
      method: 'post',
      data: resource,
    });
  }
  getCartData(resource) {
    return request({
      url: '/get-cart-data',
      method: 'post',
      data: resource,
    });
  }
  getFile(resource) {
    return request({
      url: '/get-file',
      method: 'post',
      data: resource,
    });
  }

  exportAppointmentIcs(id, paginate) {
    if (window.axios == null) {
      window.axios = require('axios');
    }
    const url = '';
    return window.axios({
      baseURL: process.env.VUE_APP_HOST_REMOTE_API+'/export-appointment-by-kurs/'+id,
      url: typeof url == 'undefined' || url == '' ?  '' : url.toString(),
      timeout: 20000,
      method: 'get',
      data: '',
      params: { offset: paginate.offset, limit: paginate.limit},
      headers: {
          'Authorization' : 'Bearer '+ store.state.user.token
      },
    }).then((res) => {
      return res.data;
    }).catch((error) => {
      let message = _.get(error, 'response.data.message', error.message);
      ElMessage({
        message: message,
        type: 'error',
        duration: 5 * 1000,
      });
      return Promise.reject(error);
    });
  }

  getParticipantsBySeminar(resource) {
    return request({
      url: '/get-participants-by-seminar',
      method: 'post',
      data: resource,
    });
  }

  getCreditByUser(resource) {
    return request({
      url: '/get-credit-by-user',
      method: 'post',
      data: resource,
    });
  }

  getSeminarByKurs(resource) {
    return request({
      url: '/get-seminar-by-kurs',
      method: 'post',
      data: resource,
    });
  }

  getDocumentsBySeminar(resource) {
    return request({
      url: '/get-documents-by-seminar',
      method: 'post',
      data: resource,
    });
  }

  addDocumentBySeminar(resource) {
    return request({
      url: '/add-document-by-seminar',
      method: 'post',
      data: resource,
    });
  }

  getSeminarOnlineFilter(resource) {
    return request({
      url: '/get-seminar-online-filter',
      method: 'post',
      data: resource,
    });
  }

  getSeminarArten() {
    return request({
      url: '/get-seminar-arten',
      method: 'post',
    });
  }

  getSeminarByOnlineGroup(resource) {
    return request({
      url: '/get-seminar-online-group-id',
      method: 'post',
      data: resource,
    });
  }

  getOnlineGroupDetails(ressource){
    return request({
      url: '/get-online-group-details',
      method: 'post',
      data: ressource
    })
  }

  getBookingSeminar(booking_data) {
    return request({
      url: '/get-booking-seminar',
      method: 'post',
      data: booking_data,
    });
  }

  getSeminarByDsId(ds_id) {
    return request({
      url: '/get-seminar-by-dsid',
      method: 'post',
      data: ds_id,
    });
  }

  cancelBookingSeminar(booking_data) {
    return request({
      url: '/cancel-booking-seminar',
      method: 'post',
      data: booking_data,
    });
  }

  sendWiderrufverzicht(data) {
    return request({
      url: '/set-widerrufverzicht',
      method: 'post',
      data: data,
    });
  }

}

export { SeminareResource as default };
