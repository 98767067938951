<template>
  <div class="row footer mt-auto bottom-0 p-3 px-5 justify-content-center">
    <div class="col-12 col-md-3 col-xl-auto text-start px-2 fs-6 my-3 my-md-1 mx-2">
      <b>Die Grüne Schule</b> <br/>
      Hamburger Akademie <br/>
      für Naturheilkunde <br/>
      Emilienstraße 19 <br/>
      20259 Hamburg <br/>
    </div>
    <div class="col-12 col-md-3 col-xl-auto text-start px-2 my-3 my-md-1 mx-2">
      <b>Kontakt</b> <br/>
      E-Mail:<a href="mailto:info@hafn.de">info@hafn.de</a> <br/>
      Telefon: <a href="tel:+49040493207">040 / 49 32 07</a> <br/>
      Telefonisch zu erreichen: <br/>
      Mo. – Fr. von 9:00 – 12:30 Uhr <br/>
      und 14:00 -17:30 Uhr
    </div>
    <div class="col-12 col-md-3 col-xl-auto text-start px-2 my-3 my-md-1 mx-2">
      <a href="https://hafn.de/impressum/" target="_blank" class="footer-link"><b>Impressum</b></a><br/>
      <a href="https://hafn.de/datenschutzerklaerung/" target="_blank" class="footer-link"><b>Datenschutz</b></a> <br/>
      <a href="https://hafn.de/agb/" target="_blank" class="footer-link"><b>AGB</b></a> <br/>
      <a href="https://hafn.de/widerrufsrecht/" target="_blank" class="footer-link"><b>Widerrufsrecht</b></a> <br/>
    </div>
    <div class="col-12 col-md-3 col-xl-auto text-start px-2 my-3 my-md-1 mx-2">
      <a href="https://de-de.facebook.com/pages/category/School/Die-Gr%C3%BCne-Schule-Heilpraktikerausbildung-184360164955202/"
         target="_blank"><img :src="require('/public/assets/images/facebook_dgs.png')" alt="Facebook_die_grüne_schule"></a>
      <a href="https://www.instagram.com/diegrueneschule/?utm_medium=copy_link" target="_blank">
        <img :src="require('/public/assets/images/instagramm_dgs.png')" alt="instagram_die_grüne_schule"></a>
      <a href="https://www.youtube.com/channel/UCfnTmL-ngeyBKUIjH11PoSg" target="_blank">
        <img :src="require('/public/assets/images/Youtube_dgs.png')" alt="Youtube_die_grüne_schule"></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "AGBFooter"
}
</script>

<style scoped>

.footer {
  background-color: #FFFFFF;
}

.footer-link {
  color: #464b5c;
  text-decoration: none;
}

</style>