<template>
  <div class="courses content " v-loading="loading">
    <div class="row">
      <div class="fw-bold text-start fs-4">
        {{ this.$store.state.seminar.name }}
      </div>
      <div class="col-12 text-end p-3">
        <el-button
          :loading="sending"
          type="success"
          round
          @click="exportIcs"
          class="btn-export"
        >
          Export ICS
        </el-button>
      </div>
    </div>
    <Table_noclick
        :data="data"
        :columns="columns"
        :showSwitches=false
        @onClick="onClick"
        :detailColumns="detailColumns"
        :pagination="query"
        @fetchData="fetchData"
        @changeQuery="changeQuery"
        :showPrice=false
        :showSizes=true />
  </div>
</template>
<script>
// Home.vue
import _ from "lodash";
import Table_noclick from "@/components/Table_noclick.vue";
import Resource from "@/api/seminare";
import { formatDateDMY, formatDateHI } from "@/filters/index";
const seminareResource = new Resource();

export default {
  components: {
    Table_noclick,
  },
  data() {
    return {
      data: [],
      query: { page: 1, limit: 50, total: 0, kurs_id: 0 },
      columns: [
        { field: "Color", text: "" },
        { field: "datum", text: "Datum" },
        { field: "seminar", text: "Seminar" },
        { field: "thema_lang", text: "Thema" },
        { field: "dozent", text: "Dozent" },
        { field: "uhrzeit", text: "Beginn" },
        { field: "zeit2", text: "Ende" },
        { field: "raum", text: "Raum" },
      ],
      detailColumns: [
        { field: "Color", text: "" },
        { field: "datum", text: "Datum" },
        { field: "seminar", text: "Seminar" },
        { field: "thema_lang", text: "Thema" },
        { field: "dozent", text: "Dozent" },
        { field: "uhrzeit", text: "Beginn" },
        { field: "zeit2", text: "Ende" },
        { field: "raum", text: "Raum" },
      ],
      loading: true,
    };
  },
  mounted() {
    this.query.kurs_id = this.$route.params.id;
  },
  computed: {
    username() {
      // We will see what `params` is shortly
      return this.$route.params.username;
    },
  },
  created(){
    if (typeof this.$store.state.user.token == 'undefined' || this.$store.state.user.token === '') {
      this.$router.push({name: "login"});
    }
    this.query.kurs_id = this.$route.params.id;
    this.fetchData(this.query);
  },
  methods: {
    changeQuery(limit){
      this.query.limit = limit;
    },
    fetchData(query){
      this.loading = true;
      const offset = (query.page - 1 )* query.limit + 1;
      this.query.offset = offset;
      seminareResource.getAppointmentByKurs(this.query).then((res)=>{
        this.query = _.merge(this.query, query);
        this.query.total = _.get(res, 'response.dataInfo.foundCount', 0);
        if(res.response && res.response.data){
          this.data = res.response.data.map((value) =>{
            return {
              id: value.fieldData['ds_id'],
              status_color: value.fieldData['seminar_search_filter_termine::color'],
              datum: formatDateDMY(value.fieldData.datum),
              seminar: value.fieldData.seminar,
              thema_lang: value.fieldData.thema_lang,
              dozent: value.fieldData.dozent,
              uhrzeit: formatDateHI(value.fieldData['zeit']),
              zeit2: formatDateHI(value.fieldData['zeit2']),
              raum: value.fieldData['raumname'],
              };
          });
        }
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        const code = _.get(error, 'response.data.messages[0].code', 0);
        if(code == 952){
          this.$router.push({ name: "login"});
        }
      });
    },
    onClick(row){
      console.log('data', row);
      // this.$router.push({ name: "courseDetail", params: { 'id': row.id} });
    },
    exportIcs() {
      this.loading = true;
      if(this.data.length) {
        seminareResource.exportAppointmentIcs(this.$route.params.id, this.query)
          .then((res) => {
            let blob = new Blob([res], { type: 'text/calendar' })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'Stundenplan.ics'
            link.click()
            this.loading = false;
          })
          .catch((error) => {
            console.log('er', error);
            this.loading = false;
            this.$message({
              type: "error",
              message: "Export fehlgeschlagen!",
            });
            return false;
          });
      }else{
        this.$message({
          type: "warning",
          message: "No data to export!",
        });
        this.loading = false;
      }
    }
  },
};
</script>

<style scoped>

.btn-export {
  color: #FFF;
  background-color: #464b5c!important;
  border-color: #464b5c!important;
  transition: all 0.3s;
  border-radius: 1em!important;
}

.btn-export:hover {
  opacity: 0.8;
}

</style>
