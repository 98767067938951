<template>
  <div class="content mb-3">
    <div class="course full-width custom-justify">
      <p class="mb-2">Meine Credits für Inklusivseminare</p>
    </div>
    <div class="course full-width custom-justify row" >
      <div class="col col-count-2">
        Credits sind ein alternatives Bezahlsystem der Grünen Schule. Du erhältst Credits bei der Buchung von bestimmten Ausbildungen
        (z.B. Heilpraktikerausbildung) und kannst sie zur Bezahlung bei entsprechenden Kursen einsetzen.

        In Deiner Credits-Übersicht findest Du die Angabe Deiner verbrauchten und noch freien Credits.
        Bitte achte darauf, dass Credits einer Kategorie nur zur Buchung von Seminaren aus der gleichen Kategorie
        verwandt werden können.

        Zum Filtern kannst Du Dir die angebotenen Seminare in der Suchmaske im Bereich Kursbuchung anzeigen lassen.
        Beim Bezahlvorgang im Warenkorb werden Dir Deine verfügbaren Credits zum Bezahlen zur Auswahl angeboten.
      </div>
    </div>
  </div>
  <div class="content mb-3">
    <div class="course full-width" v-loading="loading">
      <Block
        v-for="(row, index) in data" :key="'row' + index"
        :data="row.detail_data"
        :title="row.title"
        :columns="columns"
        :credit="row.credits"
        :credits_negativ ="row.credits_negativ"
        :credits_positiv ="row.credits_positiv"
        :detailColumns="detailColumns"
      />
    </div>
  </div>
</template>

<script>
// Home.vue
import _ from "lodash";
import Block from "./courses/components/Block.vue";
import Resource from "@/api/seminare";
import { formatDateDMY } from "@/filters/index";
const seminareResource = new Resource();

export default {
  components: {
    Block,
  },
  data() {
    return {
      query: { tn_id: 0 },
      data: [],
      columns: [
        { field: "datum", text: "Datum"  },
        { field: "seminar", text: "Seminar_Beschreibung" },
        { field: "betrag", text: "Betrag" },
      ],
      detailColumns: [
        { field: "datum", text: "Datum" },
        { field: "seminar", text: "Seminar_Beschreibung" },
        { field: "betrag", text: "Betrag" },
      ],
      loading: true,
    };
  },
  mounted(){
    this.query.tn_id = this.$store.state.user.id_person; //42566;
    this.fetchData(this.query);
  },
  created() {
    if (typeof this.$store.state.user.token == 'undefined' || this.$store.state.user.token === '') {
      this.$router.push({name: "login"});
    }
  },
  computed: {
    username() {
      return this.$route.params.username;
    },
  },
  methods: {
    fetchData(query) {
      this.loading = true;
      seminareResource.getCreditByUser(query).then((res)=>{
        if(res.response && res.response.data){
          this.data = res.response.data.map((value) =>{
            let temp_data_detail = [];
            if(value.portalData.seminarzaehler_buchungen) {
              temp_data_detail = value.portalData.seminarzaehler_buchungen.map((val) =>{
                return {
                  id: val['recordId'],
                  datum: formatDateDMY(val['seminarzaehler_buchungen::Erstellungszeitstempel']),
                  seminar: val['seminarzaehler_buchungen::beschreibung'],
                  betrag: val['seminarzaehler_buchungen::betrag'],
                };
              });
            }
            return {
              credits: value.fieldData['credits'] && value.fieldData['credits'] != '' ? value.fieldData['credits'] : 0,
              credits_negativ: value.fieldData['credits_negativ'] && value.fieldData['credits_negativ'] != '' ? value.fieldData['credits_negativ'] : 0,
              credits_positiv: value.fieldData['credits_positiv'] && value.fieldData['credits_positiv'] != ''  ? value.fieldData['credits_positiv'] : 0,
              title: value.fieldData['seminarart_teilnehmer::name'],
              detail_data: temp_data_detail,
              };
          });
        }
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        const code = _.get(error, 'response.data.messages[0].code', 0);
        if(code == 952){
          this.$router.push({ name: "login"});
        }
      });
    },
  },
};
</script>
<style scoped>
.course {
  padding: 0 2rem;
}

.course div.col-6 {
  padding: 0rem 0 0.5rem 2rem;
  margin-top: -0.3rem;
}

.course-image {
  margin-top: 5rem;
}

div.card {
  width: 15rem;
  display: flex;
  flex-direction: column;
}

.course__title {
  line-height: 5rem;
}

.custom-justify { 
  text-align: justify;
}
.col-count-2 {
  column-count: 2;
  column-gap: 2.5rem;
}
.spalte_datum {
   max-width: 10px;
   background-color: aqua;
}
</style>