import { createApp } from 'vue'
import App from './App.vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

// import {
//     applyPolyfills,
//     defineCustomElement
// } from "@aws-amplify/ui-components/loader";
//
// import { Amplify } from "aws-amplify";
// import awsconfig from './aws-exports';

import './index.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
import { setupRouter } from './router'
import store from './store'
import { setupI18n } from './i18n'
import en from './locales/en.json'
import de from './locales/de.json'
import '@/svg';
import SvgIcon from '@/components/SvgIcon.vue';
import 'element-plus/lib/theme-chalk/index.css';
import ElementPlus from 'element-plus';
import './element-variables.css';
import 'bootstrap/dist/js/bootstrap.js';
import * as filters from './filters'; // global filters
import deLang from 'element-plus/lib/locale/lang/de';
import VueAxios from "vue-axios";// global element ui language
import axios from "axios";

window.axios = require('axios');

// Amplify.configure(awsconfig);

// applyPolyfills().then(() => {
//     defineCustomElement(window);
// });

const i18n = setupI18n({
    globalInjection: true,
    legacy: false,
    locale: 'de',
    fallbackLocale: 'de',
    messages: {
      en, de
    }
  })
const router = setupRouter(i18n)

const Vue = createApp(App);
Vue.use(store);
Vue.use(router);
Vue.use(VueAxios, axios),
Vue.use(ElementPlus, {
  locale: deLang,
});
Vue.use(VueReCaptcha, {siteKey: '6LfdmTEfAAAAAD5EANmy9lgfc0Cq4EUoqCu9WljM'}) //Testserver
//Vue.use(VueReCaptcha, {siteKey: '6Le-nDEfAAAAAKCmilw2P1iWj4wSfNpDVpgTwJJG'})  //Live-Server
Vue.config.globalProperties.$filters = filters;

// Vue.config.isCustomElement = tag => tag.startsWith('amplify-');

Vue.component('SvgIcon', SvgIcon);
Vue.use(SvgIcon);
Vue.mount("#app");
