
<template>
  <div class="container options-page central-content">
    <div class="header">
      <div class="centered">
        <img class="logo" src="/assets/images/logo.png" />
      </div>
      <h1 class="title d-sm-none d-block font-Cavolini fw-white">
        Passwort zurücksetzen
      </h1>
    </div>
    <img
      class="d-block d-sm-none"
      src="/assets/images/login-img.png"
      alt=""
      srcset=""
    />
    <div class="login text-center">
      <div class="login__title">Passwort zurücksetzen</div>
      <el-form :model="obj" :rules="rules" ref="resetpasswordForm" label-width="0" @submit.prevent>
        <el-form-item prop="password">
          <el-input
            v-model="obj.password"
            placeholder="Passwort eingeben"
            show-password
            max-length="50"
          />
        </el-form-item>
        <el-form-item prop="password_confirm">
          <el-input
            v-model="obj.password_confirm"
            placeholder="Kennwort bestätigen"
            show-password
            max-length="50"
          />
        </el-form-item>
        <el-button
          :loading="loading"
          type="button"
          @click="submitFormResetPassword"
          class="btn btn-primary btn-lg btn-submit"
        >
          Bestätigen Sie
        </el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { resetPasswordGuest } from "@/api/auth";
export default {
  name: "ResetPasswordGuest",
  props: {
    msg: String,
  },
  data() {
    return {
      obj: {
        password: "",
        password_confirm: "",
        token: this.$route.params.token
      },
      rules: {
        password: [
          { required: true, message: "Bitte neues Passwort eingeben", trigger: "blur" },
          {
            min: 8,
            max: 50,
            message: "Länge sollte 8 bis 50 . betragen",
            trigger: "blur",
          },
        ],
        password_confirm: [
          {
            required: true,
            message: "Bitte bestätigen Sie das Passwort eingeben",
            trigger: "blur",
          },
          {
            min: 8,
            max: 50,
            message: "Länge sollte 8 bis 50 . betragen",
            trigger: "blur",
          },
        ]
      },
      loading: false,
    };
  },
  created() {
  },
  methods: {
    submitFormResetPassword() {
      this.loading = true;
      this.$refs["resetpasswordForm"].validate((valid) => {
        if (valid) {
           if(this.obj.password !== this.obj.password_confirm){
            this.$message({
                  type: "error",
                  message: "Passwörter müssen übereinstimmen",
                });
             this.loading = false;
          }else{
            resetPasswordGuest(this.obj).then((res) => {
              console.log(res);
              if(res != null){
                if(res.response){
                  this.$message({
                    type: "success",
                    message: res.response,
                  });
                  setTimeout( () => this.$router.push({ name: "login" }), 5000);
                }else{
                  this.$message({
                    type: "error",
                    message: res.error,
                  });
                }
              }
            this.loading = false;
            }).catch((error)=>{
              let message = _.get(error, 'messages[0].message', error.message);
              this.$message({
                type: "error",
                message: message,
              });
              this.loading = false;
            });
          }
        } else {
          this.$message({
            type: "error",
            message: 'Fehler abschicken!',
          });
          this.loading = false;
          return false;
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.central-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  padding: 0;
}

img {
  max-width: 100%;
}
.container > img {
  width: 100%;
}
.header {
  width: 100%;
  background-color: #c4c08e;
}
.header .title {
  padding: 1em 0;
  font-size: 2.5em;
}
.centered img {
  margin-top: 2rem;
}
form {
  margin-top: 2.5rem;
  font-size: 1rem;
}
button.btn-primary {
  margin-top: 2.2rem;
  width: 100%;
}
.login {
  margin: 2em auto;
  width: 30rem;
  background: #c4c08e;
  color: #ffffff;
  border-radius: 1.2rem;
  font-family: Arial;
  padding: 2.5rem 2.5rem 2.5rem;
  max-width: 100%;
}
.login__title {
  font: bold 1.6rem "Cavolini";
}

.btn-submit { 
  background-color: #464B5C;
  border-color: #565B5C;
  cursor: pointer;
  border-radius: 0.8rem;
  font-size: 1rem;
  padding: 0.8rem;
  line-height: 1.5;
  color:#fff;
  transition: all 0.3s;
}
.btn-submit:hover, .btn-submit:focus, .btn-submit:active {
  background-color: #565B5C;
  border-color: #464B5C;
  color:#fff;
  box-shadow: none;
}
@media (min-width: 576px) {
  .header {
    background-color: transparent;
  }
}
</style>
