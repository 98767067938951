const state = {
    id: '',
    name: '',
};

const mutations = {
    SET_ID: (state, id) => {
        state.id = id;
    },
    SET_NAME: (state, name) => {
        state.name = name;
    },
};

const actions = {
    set_name({ commit }, seminar_name) {
        commit('SET_NAME', seminar_name);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
}