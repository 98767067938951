<template>
  <el-form class="profile form" ref="formUser" :model="userData" :rules="rules">
    <div class="row" v-loading="loading">
      <div class="avatar col-sm-2">
        <img
            v-if="$store.state.user.avatar  && $store.state.user.avatar != 'https://diegrueneschule.obs.eu-de.otc.t-systems.com'"
            :src="$store.state.user.avatar" alt="" srcset="" class=""/>
        <SvgIcon
            v-else
            :name="'person'"
            class="text-center pb-3"
            @click="goHome()"
            size="5rem"
        />
        <small> <a href="javascript:void(0)" @click="$refs.uploadImage.click()">Profilfoto ändern </a></small>
        <input
            type="file"
            ref="uploadImage"
            id="uploadImage" name="uploadImage"
            @change="onUploadFiles"
            accept="image/jpeg, image/png"
            style="display:none"/>
      </div>
      <div class="col-sm-10 col-lg-8 col-xl-6 pl-2 pr-2">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="email">Email*</label>
              <input
                  type="email"
                  id="email"
                  name="email"
                  class="form-control"
                  required
                  v-model="userData.email"
                  readonly
              />
              <small><a href="javascript:void(0)" @click="resetPassword">Passwort ändern?</a></small>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="form-group mt-10 mt-xs-30">
              <label for="gender">Anrede</label>
              <el-form-item prop="gender">
                <select v-model="userData.gender" id="gender" name="gender"
                        class="form-control select-custom form-select">
                  <option value="Frau">Frau</option>
                  <option value="Herr">Herr</option>
                </select>
              </el-form-item>
            </div>
          </div>
          <div class="col-sm-6 ">
            <div class="form-group mt-10">
              <label for="title">Titel</label>
              <el-form-item prop="title">
                <select v-model="userData.title" id="title" name="title" class="form-control select-custom form-select">
                  <option v-for="(item, index) in option_title" :key="index" :value="item">{{ item }}</option>
                </select>
              </el-form-item>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="first_name">Vorname*</label>
              <el-form-item prop="first_name">
                <input
                    type="text"
                    id="first_name"
                    name="first_name"
                    class="form-control"
                    required
                    v-model="userData.first_name"
                />
              </el-form-item>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="last_name">Nachname*</label>
              <el-form-item prop="last_name">
                <input
                    type="text"
                    id="last_name"
                    name="last_name"
                    class="form-control"
                    required
                    v-model="userData.last_name"
                />
              </el-form-item>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="road">Strasse + Hausnummer*</label>
              <el-form-item prop="road">
                <input
                    type="text"
                    id="road"
                    name="road"
                    class="form-control"
                    required
                    v-model="userData.road"
                />
              </el-form-item>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="adresszusatz">Adresszusatz</label>
              <el-form-item prop="adresszusatz">
                <input
                    type="text"
                    id="adresszusatz"
                    name="adresszusatz"
                    class="form-control"
                    v-model="userData.Adresszusatz"
                />
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="row g-3">
              <div class="col">
                <label for="post_code">PLZ*</label>
                <el-form-item prop="post_code">
                  <input
                      type="text"
                      id="post_code"
                      name="post_code"
                      class="form-control"
                      required
                      v-model="userData.post_code"
                  />
                </el-form-item>
              </div>
              <div class="col">
                <label for="location">Ort*</label>
                <el-form-item prop="location">
                  <input
                      type="text"
                      id="location"
                      name="location"
                      class="form-control"
                      required
                      v-model="userData.location"
                  />
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="country">Land*</label>
              <el-form-item prop="country">
                <select v-model="userData.country" id="country" name="country"
                        class="form-control select-custom form-select" required>
                  <option v-for="(item, index) in option_land" :key="index" :value="item">{{ item }}</option>
                </select>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="row g-3">
              <div class="col">
                <label for="phone">Telefon*</label>
                <el-form-item prop="phone">
                  <input
                      type="phone"
                      id="phone"
                      name="phone"
                      class="form-control"
                      required
                      v-model="userData.phone"
                  />
                </el-form-item>
              </div>
              <div class="col">
                <label for="funk">Telefon 2</label>
                <el-form-item prop="funk">
                  <input
                      type="text"
                      id="funk"
                      name="funk"
                      class="form-control"
                      v-model="userData.funk"
                  />
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="date_of_birth">Geburtsdatum</label>
              <el-form-item prop="date_of_birth">
                <input
                    type="date"
                    id="date_of_birth"
                    name="date_of_birth"
                    class="form-control"
                    placeholder="tt.mm.jjjj"
                    required
                    v-model="userData.date_of_birth"
                />
              </el-form-item>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-xl-4">
        <!-- <div class="note">Zahlungseinstellungen</div> -->
      </div>
    </div>
    <div class="row">
      <div class="col-sm-2">
      </div>
      <div class="col-sm-10 pl-2 pr-2">
        <div class="form-group d-flex align-items-center mt-10">
          <input type="checkbox" class="form-check-input-user" id="info_forum" v-model="userData.info_forum"/>
          <label for="info_forum" class="w-100"> Ich möchte per Mail über Forumsbeiträge informiert werden.</label>
        </div>
        <div class="form-group d-flex align-items-center mt-10">
          <input type="checkbox" class="form-check-input-user" id="eintrag_tn_liste"
                 v-model="userData.eintrag_tn_liste"/>
          <label for="eintrag_tn_liste" class="w-100"> Ich bin damit einverstanden, dass ich mit Name, PLZ, Ort, Telefon
            und Emailadresse in den jeweiligen Kurslisten erscheine.</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-end pt-3">
        <el-button :loading="sending" type="success" round @click="updateDataUser" class="btn-submit">
          Speichern
        </el-button>
      </div>
    </div>
  </el-form>
</template>
<script>
// Home.vue
import _ from "lodash";
import {updateUserInfo, uploadImageS3, deleteObjectS3} from "@/api/auth";
import {formatDate, convertDate} from "@/filters/index";
import SeminareResource from "../api/seminare";

const Ressource = new SeminareResource();

export default {
  data() {
    const user = this.$store.state.user;

    return {
      userData: {
        ...user,
        date_of_birth: convertDate(user.date_of_birth)
      },
      option_land: ['Deutschland', 'Österreich', 'Schweiz'],
      option_title: ['', 'HP', 'Dr.', 'Prof.', 'Dr.-Ing.', 'Dipl.-Psych.', 'Dipl.-Oec.', 'HP Dr. rer. nat.', 'Dr. phil', 'HP-Psych. Mag.'],
      sending: false,
      loading: false,
      rules: {
        first_name: {
          required: true,
          message: "Bitte Vorname eingeben",
          trigger: "blur",
        },
        last_name: {
          required: true,
          message: "Bitte Nachname eingeben",
          trigger: "blur",
        },
        phone: {
          required: true,
          message: "Bitte Telefonnummer eingeben",
          trigger: "blur",
        },
        date_of_birth: {
          required: false,
          message: "Bitte Geburtsdatum eingeben",
          trigger: "blur",
        },
        road: {
          required: true,
          message: "Bitte Straße eingeben",
          trigger: "blur",
        },
        location: {
          required: true,
          message: "Bitte Ort eingeben",
          trigger: "blur",
        },
        country: {
          required: true,
          message: "Bitte Land eingeben",
          trigger: "blur",
        },
        title: {
          required: false,
          message: "Bitte Gegenstand eingeben",
          trigger: "blur",
        },
        post_code: {
          required: true,
          message: "Bitte Postleitzahl eingeben",
          trigger: "blur",
        },
        funk: {
          required: false,
          message: "Bitte Gegenstand eingeben",
          trigger: "blur",
        },
        adresszusatz: {
          required: false,
          message: "Bitte Gegenstand eingeben",
          trigger: "blur",
        },
      },
    };
  },
  computed: {
    username() {
      // We will see what `params` is shortly
      return this.$route.params.username;
    },
  },
  mounted() {
    this.get_cart();
  },
  created() {
    if (typeof this.$store.state.user.token == 'undefined' || this.$store.state.user.token === '') {
      this.$router.push({name: "login"});
    }
  },
  methods: {

    getCookie(cName) {
      const name = cName + "=";
      const cDecoded = decodeURIComponent(document.cookie); //to be careful
      const cArr = cDecoded.split('; ');
      let res;
      cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
      })
      return res
    },

    get_cart() {
      var hash = this.getCookie('cart_hash');

      var query = {
        hash: hash,
        user_id: this.$store.state.user.id
      }

      Ressource.getCartData(query).then((res) => {
        if (res.length > 0) {
          for (let i = 0; i < res.length; i++) {
            this.$store.state.user.cart_course.push(res[i]);
             // console.log(this.$store.state.user.cart_course);
          }
        }
       
      });
    },

    resetPassword() {
      this.$router.push({name: "resetPassword"});
    },
    onUploadFiles(event) {
      const file = event.target.files[0];
      if (!file) {
        event.preventDefault();
        this.$message({
          type: "error",
          message: 'No file chosen',
        });
        return;
      }
      if (file.size > (4 * 1024 * 1024)) {
        event.preventDefault();
        this.$message({
          type: "error",
          message: 'Datei ist größer als 4MB',
        });
        return;
      }
      // console.log(file);
      var oldImage = this.$store.state.user.pfad_s3;
      var file_ext = file.name.split('.').pop();
      var formData = new FormData();
      formData.append("image", file, 'profilepicture.' + file_ext);
      formData.append("id_person", this.$store.state.user.id_person);
      // api upload s3
      this.loading = true;

      uploadImageS3(formData).then((res) => {
        if (res != null && res != 'Error') {
          var profilepicture_url = res;
          var pfad_s3 = res.split('.com').pop();

          const arrayData = {
            pfad_s3: pfad_s3,
            profilpicture: 1
          };
          updateUserInfo(this.$store.state.user.record_id, arrayData).then((res) => {
            if (res != null) {
              let message = _.get(res, 'messages[0].message', '');
              if (message === 'OK') {
                this.$store.dispatch("user/login", {
                  ...this.$store.state.user,
                  avatar: profilepicture_url,
                  pfad_s3: arrayData.pfad_s3
                });
                this.$message({
                  type: "success",
                  message: 'Profilbild erfolgreich aktualisiert.',
                });
                if (oldImage != pfad_s3) {
                  deleteObjectS3({object_url: oldImage}).then((res) => {
                    console.log(res);
                    // if(res != null && res == 'Ok'){
                    //   this.$message({
                    //     type: "success",
                    //     message: 'altes Bild erfolgreich entfernen!',
                    //   });
                    // }
                  }).catch((error) => {
                    let message = _.get(error, 'messages[0].message', error.message);
                    this.$message({
                      type: "error",
                      message: message,
                    });
                    this.loading = false;
                  });
                }
              } else {
                console.log(message);
                this.$message({
                  type: "error",
                  message: message,
                });
              }
            }
            this.loading = false;
            this.$router.go();
          }).catch((error) => {
            let message = _.get(error, 'messages[0].message', error.message);
            this.$message({
              type: "error",
              message: message,
            });
            this.loading = false;
          });
        }
      }).catch((error) => {
        let message = _.get(error, 'messages[0].message', error.message);
        this.$message({
          type: "error",
          message: message,
        });
        this.loading = false;
      });
    },
    updateDataUser() {
      this.sending = true;
      this.$refs["formUser"].validate((valid) => {
        if (valid) {
          let arrayData = {
            FrauHerr: this.userData.gender,
            Vorname: this.userData.first_name,
            Nachname: this.userData.last_name,
            Telefon: this.userData.phone,
            GEBDATUM: formatDate(this.userData.date_of_birth),
            Strasse: this.userData.road,
            Ort: this.userData.location,
            PLZ: this.userData.post_code,
            land: this.userData.country,
            titel: this.userData.title,
            info_forum: this.userData.info_forum ? "1" : "0",
            eintrag_tn_liste: this.userData.eintrag_tn_liste ? "1" : "0",
            Funk: this.userData.funk,
            Adresszusatz: this.userData.Adresszusatz,
            profilpicture: 0
          };
          if (this.userData.gender) {
            arrayData = {
              ...arrayData,
              FrauHerr: this.userData.gender
            }
          }
          if (this.userData.title) {
            arrayData = {
              ...arrayData,
              titel: this.userData.title
            }
          }
          if (this.userData.funk) {
            arrayData = {
              ...arrayData,
              Funk: this.userData.funk
            }
          }
          if (this.userData.adresszusatz) {
            arrayData = {
              ...arrayData,
              Adresszusatz: this.userData.adresszusatz
            }
          }
          updateUserInfo(this.$store.state.user.record_id, arrayData).then((res) => {
            if (res != null) {
              let message = _.get(res, 'messages[0].message', '');
              if (message === 'OK') {
                this.$store.dispatch("user/login", {
                  ...this.$store.state.user,
                  gender: arrayData.FrauHerr,
                  first_name: arrayData.Vorname,
                  last_name: arrayData.Nachname,
                  post_code: arrayData.PLZ,
                  location: arrayData.Ort,
                  phone: arrayData.Telefon,
                  road: arrayData.Strasse,
                  title: arrayData.titel,
                  country: arrayData.land,
                  funk: arrayData.Funk,
                  date_of_birth: arrayData.GEBDATUM,
                  // newsletter: userData.newsletter,
                  // password: userData.passwort,
                  info_forum: arrayData.info_forum === '1',
                  eintrag_tn_liste: arrayData.eintrag_tn_liste === '1',
                  Adresszusatz: arrayData.Adresszusatz
                });
                this.$message({
                  type: "success",
                  message: 'Benutzerdaten wurden aktualisiert.',
                });

              } else {
                this.$message({
                  type: "error",
                  message: message,
                });
              }
            }
            this.sending = false;
          }).catch((error) => {
            let message = _.get(error, 'messages[0].message', error.message);
            this.$message({
              type: "error",
              message: message,
            });
            this.sending = false;
          });
        } else {
          this.sending = false;
          return false;
        }
      });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
::-webkit-calendar-picker-indicator {
  filter: invert(0.5);
}

.profile.form .form-group {
  text-align: left;
}

.profile div.col-6 {
  padding: 0rem 0 0.5rem 2rem;
  margin-top: -0.3rem;
}

.mt-xs-30 {
  margin-top: 3rem;
}

form.form {
  font-size: 1.5rem;
  text-align: left;
  color: #464B5C;
}

div.avatar {
  display: flex;
  flex-direction: column;
}

div.avatar img {
  width: 50%;
  border-radius: 1em;
  margin: 0.5em auto;
}

div.avatar small {
  text-align: center;
}

.todo {
  background-color: #ef14d5;
  width: 100%;
  height: 2.7rem;
  color: #ffffff;
  text-align: center;
  font-size: 1.2rem;
}

form.form .form-group {
  margin-bottom: 0.4em;
}

form.form .form-control {
  border-radius: 0.5em;
  border-color: #707070;
  height: 2em;
  font-size: 1em;
  font-weight: bold;
  color: #464B5C;
}

.form-group small a {
  padding-left: 0.5em;
}

form.form label {
  padding-left: 0.5em;
  font-size: 0.8em;
  font-weight: bold;
}

div.note {
  background-color: #e8e8e8;
  border-radius: 0.5em;
  padding-top: 1em;
  text-align: center;
  font-weight: bold;
  min-height: 30rem;
}

.form-check-input-user {
  width: 1.2em;
  height: 1.2em;
  margin-top: 0em;
  vertical-align: top;
}

@media (min-width: 576px) {
  form.form {
    font-size: 1rem;
  }

  div.avatar img {
    width: 100%;
    border-radius: 1rem;
  }

  .pr-2 {
    padding-right: 2rem;
  }

  .pl-2 {
    padding-left: 2rem;
  }

  .mt-10 {
    margin-top: 1em;
  }

  .mt-30 {
    margin-top: 3.5em;
  }

  .mt-50 {
    margin-top: 5rem;
  }

  .mt-70 {
    margin-top: 4.5em;
  }

  .mt-90 {
    margin-top: 5.5em;
  }

  .mt-15 {
    margin-top: 1.5rem;
  }
}

.select-custom {
  padding-top: 0.15rem;
}

.btn-submit {
  color: #FFF;
  background-color: #464b5c !important;
  border-color: #464b5c !important;
  transition: all 0.3s;
  border-radius: 1em !important;
}

.btn-cancel {
  color: #FFF;
  background-color: #f49b2d !important;
  border-color: #f49b2d !important;
  transition: all 0.3s;
  border-radius: 1rem !important;
}

.btn-cancel:hover, .btn-submit:hover {
  opacity: 0.8;
}
</style>
