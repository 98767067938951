import { ElMessage, ElMessageBox } from 'element-plus';
import _ from "lodash";
import store from '@/store';
import moment from "moment";

// import { isLogged, setLogged } from '@/utils/auth';
if (window.axios == null) { 
  window.axios = require('axios');
}

// Create axios instance
const service = window.axios.create({
  baseURL: process.env.VUE_APP_HOST_REMOTE_API,
  timeout: 20000, // Request timeout
});
// Request intercepter
service.interceptors.request.use(
  config => {
    let currentDate = moment().unix();
    let session_time_out = store.state.user.session_time_out;
    if (session_time_out && session_time_out < currentDate) {
      console.log('time out', currentDate , session_time_out);
      store.dispatch("user/logout");
      ElMessageBox.alert('Ihre Session ist abgelaufen. Bitte melden Sie sich neu an.', 'Benachrichtigung', {
        showClose: false,
        confirmButtonText: 'OK',
        type: 'warning',
        callback: () => {
          console.log('OK');
          window.location.href = '/login';
        },
      })
    }
    const token = store.state.user.token;
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token; // Set JWT token
    }
    return config;
  },
  error => {
    // Do something with request error
    Promise.reject(error);
  }
);

// response pre-processing
service.interceptors.response.use(
  response => {
    // if (response.headers.authorization) {
    //   setLogged(response.headers.authorization);
    //   response.data.token = response.headers.authorization;
    // }
    return response.data.data;
  },
  error => {
    let message = _.get(error, 'response.data.messages[0].message', error.message);
    if (typeof message !== 'string' && 'email' in message && message.email.length > 0 && message.email[0] === 'validation.unique'){
      message = 'Email has been used!';
    }
    ElMessage({
      message: message,
      type: 'error',
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
