<template>
  <el-form class="profile form" :rules="rules" ref="formUser" :model="resetPassword">
    <div class="row">
      <div class="avatar col-sm-2">
        <img :src="$store.state.user.avatar" alt="" srcset="" />
        <small> <a href="javascript:void(0)"> Profilfoto ändern </a></small>
      </div>
      <div class="col-sm-6 pl-2 pr-2">
        <div class="row">
          <div class="col-sm-6 offset-3">
            <div class="form-group">
              <label for="old_password">Jetziges Passwort*</label>
              <el-form-item prop="old_password">
              <input
                type="password"
                id="old_password"
                name="old_password"
                class="form-control"
                required
                v-model="resetPassword.old_password"
              />
              </el-form-item>
            </div>
            <div class="form-group mt-10">
              <label for="new_password">Neues Passwort*</label>
              <el-form-item prop="new_password">
              <input
                type="password"
                id="new_password"
                name="new_password"
                class="form-control"
                required
                ref="new_password"
                v-model="resetPassword.new_password"
              />
              </el-form-item>
            </div>

            <div class="form-group mt-10">
              <label for="new_password_confirm">Bestätige das neue Passwort*</label>
              <el-form-item prop="new_password_confirm">
              <input
                type="password"
                id="new_password_confirm"
                name="new_password_confirm"
                class="form-control"
                required
                v-model="resetPassword.new_password_confirm"
              />
              </el-form-item>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6 offset-3  text-end pt-3">
            <el-button
              :loading="sending"
              type="success"
              round
              @click="submitResetPassword"
              class="btn-submit"
            >
              Senden
            </el-button>
             <el-button :loading="loading" type="warning" class="btn-cancel" round @click="cancel">
              Abbrechen
            </el-button>
          </div>
        </div>

      </div>
      <div class="col-sm-4">
        <!-- <div class="note">Zahlungseinstellungen</div> -->
      </div>
    </div>
  </el-form>
</template>
<script>
// Home.vue
import _ from "lodash";
import { updateUserInfo } from "@/api/auth";

export default {
  data() {
    const user = this.$store.state.user;
    console.log(user);
    return { 
      resetPassword : {
        old_password: "",
        old_password_hash: user.password,
        new_password: "",
        new_password_confirm: "",
      },
      rules: {
        old_password: {
          required: true,
          message: "Bitte altes Passwort eingeben",
          trigger: "blur",
        },
        new_password: [{
          required: true,
          message: "Bitte neues Passwort eingeben",
          trigger: "blur",
          },
          {
            min: 8,
            max: 50,
            message: "Länge sollte 8 bis 50 . betragen",
            trigger: "blur",
          },
        ],
        new_password_confirm: [{
          required: true,
          message: "Bitte bestätigen Sie das Passwort eingeben",
          trigger: "blur",
          },
          {
            min: 8,
            max: 50,
            message: "Länge sollte 8 bis 50 . betragen",
            trigger: "blur",
          },
        ],
      },
      sending: false
    };
  },
  computed: {
    username() {
      // We will see what `params` is shortly
      return this.$route.params.username;
    },
  },
  created() {
    if (typeof this.$store.state.user.token == 'undefined' || this.$store.state.user.token  === '') {
      this.$router.push({ name: "login" });
    }
  },
  methods: {
    submitResetPassword() {
      this.sending = true;
      this.$refs["formUser"].validate((valid) => {
        if (valid) {
          if(this.resetPassword.new_password !== this.resetPassword.new_password_confirm){
            this.$message({
                  type: "error",
                  message: "Die Passwörter müssen übereinstimmen",
                });
          }else{
            this.digestMessage(this.resetPassword.old_password)
            .then(digestBuffer => {
              if(this.resetPassword.old_password_hash != digestBuffer){
                this.$message({
                  type: "error",
                  message: "Das alte Passwort ist falsch",
                });
                this.sending = false;
              }else{
                const arrayData = { passwort: this.resetPassword.new_password }
                updateUserInfo(this.$store.state.user.record_id , arrayData).then((res) => {
                console.log(res);
                if(res != null){
                  let message = _.get(res, 'messages[0].message', '');
                  let password_hash = _.get(res, 'password_hash', '');
                  if(message =='OK'){
                    this.$store.dispatch("user/login", {
                      ...this.$store.state.user,
                      password: password_hash,
                    });
                    this.$message({
                      type: "success",
                      message: 'Benutzer erfolgreich aktualisieren!',
                    });
                    this.$router.push({ name: "dashboard" });
                  }else{
                    console.log(message);
                    this.$message({
                      type: "error",
                      message: message,
                    });
                  }
                }
                this.sending = false;
                }).catch((error)=>{
                  let message = _.get(error, 'messages[0].message', error.message);
                  this.$message({
                    type: "error",
                    message: message,
                  });
                  this.sending = false;
                });
              }
            });
          }
        }else {
          this.sending = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
    cancel() {
      this.$router.push({ name: "dashboard" });
    },
    digestMessage : async(message) =>{
      const msgUint8 = new TextEncoder().encode(message);                           // encode as (utf-8) Uint8Array
      const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);           // hash the message
      const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
      const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
      return hashHex;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
::-webkit-calendar-picker-indicator {
    filter: invert(0.5);
}
.profile.form .form-group {
  text-align: left;
}
.profile div.col-6 {
  padding: 0rem 0 0.5rem 2rem;
  margin-top: -0.3rem;
}
.mt-xs-30{
  margin-top:3rem;
}
form.form {
  font-size: 1.5rem;
  text-align: left;
  color: #464B5C;
}
div.avatar {
  display: flex;
  flex-direction: column;
}
div.avatar img {
  width: 50%;
  border-radius: 1em;
  margin: 0.5em auto;
}
div.avatar small {
  text-align: center;
}
.todo {
  background-color: #ef14d5;
  width: 100%;
  height: 2.7rem;
  color: #ffffff;
  text-align: center;
  font-size: 1.2rem;
}
form.form .form-group {
  margin-bottom: 0.4em;
}
form.form .form-control {
  border-radius: 0.5em;
  border-color: #707070;
  height: 1.8em;
  font-size: 0.9em;
  font-weight: bold;
  color: #464B5C;
}
.form-group small a {
  padding-left: 0.5em;
}
form.form label {
  padding-left: 0.5em;
  font-size: 0.8em;
  font-weight: bold;
}

div.note {
  background-color: #e8e8e8;
  border-radius: 0.5em;
  padding-top: 1em;
  text-align: center;
  font-weight: bold;
  min-height: 30rem;
}
@media (min-width: 576px) {
  form.form {
    font-size: 1rem;
  }
  div.avatar img {
    width: 100%;
    border-radius: 1rem;
  }
  .pr-2 {
    padding-right: 2rem;
  }
  .pl-2 {
    padding-left: 2rem;
  }
  .mt-10 {
    margin-top: 1em;
  }
  .mt-30 {
    margin-top: 3.5em;
  }
  .mt-50 {
    margin-top: 5rem;
  }
  .mt-70 {
    margin-top: 4.5em;
  }
  .mt-15{
    margin-top: 1.5rem;
  }
}

.btn-submit {
  color: #FFF;
  background-color: #464b5c!important;
  border-color: #464b5c!important;
  transition: all 0.3s;
  border-radius: 1em!important;
}
.btn-cancel {
  color: #FFF;
  background-color: #f49b2d!important;
  border-color: #f49b2d!important;
  transition: all 0.3s;
  border-radius: 1em!important;
}
.btn-cancel:hover, .btn-submit:hover {
  opacity: 0.8;
}
form.form .form-control {
  border-radius: 0.5em;
  border-color: #707070;
  height: 2em;
  font-size: 1em;
  font-weight: bold;
  color: #464B5C;
}
</style>
