<template>
  <div class="content mb-3">
    <div class="course">
      <div class="row g-4 pb-4">
        <div class="col-sm-6 col-md-6 col-lg-4">
          <div class="card card-further-courses h-100"
               id="card-green"
               v-bind:class="(query.seminare_online_filter == 1 || query.seminare_online_filter == 0)? 'active card-green' : 'card-grey' "
               style="width: 100%" @click="changeTabFilter(1)">
            <div class="card-body text-center">
            <span class="fw-bold">
              Heilpraktiker / HP
            </span>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-4">
          <div class="card card-further-courses h-100"
               id="card-blue"
               v-bind:class="(query.seminare_online_filter == 2 || query.seminare_online_filter == 0)? 'active card-blue' : 'card-grey' "
               style="width: 100%" @click="changeTabFilter(2)">
            <div class="card-body text-center">
            <span class="fw-bold">
              Psychotherapie / HPP
            </span>
            </div>
          </div>
        </div>
        <!--        <div class="col-sm-6 col-md-6 col-lg-3">-->
        <!--          <div class="card card-further-courses h-100"-->
        <!--               id="card-yellow"-->
        <!--               v-bind:class="(query.seminare_online_filter == 3)? 'active card-yellow' : 'card-grey' "-->
        <!--               style="width: 100%" @click="changeTabFilter(3)">-->
        <!--            <div class="card-body text-center">-->
        <!--            <span class="fw-bold">-->
        <!--              Fernlehrgänge-->
        <!--            </span>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="col-sm-6 col-md-6 col-lg-4">
          <div class="card card-further-courses h-100"
               id="card-red"
               v-bind:class="(query.seminare_online_filter == 4 || query.seminare_online_filter == 0)? 'active card-red' : 'card-grey' "
               style="width: 100%" @click="changeTabFilter(4)">
            <div class="card-body text-center">
            <span class="fw-bold">
              Seminare und Fachfortbildungen
            </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row course mt-3">
      <div class="form-floating col-md-6">
        <input type="text" id="search" class="form-control" placeholder="Suchanfrage:" v-model="this.query.search" v-on:keyup.enter="fetchData(this.query)">
        <label for="search" class="px-4">Suchen</label>

      </div>

      <div class="form-floating col-md-6">
        <select name="seminar_teilnahme" id="seminar_teilnahme" class="form-select" v-model="this.query.teilnahme_option">
          <option value=""></option>
          <option value="1">Hybrid</option>
          <option value="2">Online</option>
          <option value="3">Vor Ort</option>
        </select>
        <label for="seminar_teilnahme" class="px-4">Teilnahme Option:</label>
      </div>
    </div>

    <!--<div class="row course mt-md-3"  v-if="query.seminare_online_filter == 4" >-->
      <div class="row course mt-md-3" >
      <div class="form-floating col-md-6 mb-4">
        <select name="seminar_art" id="seminar_art" class="form-select" v-model="this.query.sem_art">
          <option value=""></option>
          <option v-for="item in this.sem_art" :key="item.id" v-bind:value="item.name">{{ item.name }}</option>
        </select>
        <label for="seminar_art" class="px-4">Seminar Art</label>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-6 mb-2 mb-md-0">
        <input type="button" id="btn_search" value="Suchen" class="btn btn-lg btn-secondary w-50" @click="fetchData(this.query)">
      </div>
      <div class="col-md-6">
        <input type="button" id="btn_search_reset" value="Suche aufheben" class="btn btn-lg btn-secondary w-50" @click="cancelSearch">
      </div>
    </div>
  </div>

  <div class="content mb-3">
    <div class="courses content mb-4" v-loading="loading">
      <Table
          :data="data"
          :columns="columns"
          :pagination="query"
          :detailColumns="detailColumns"
          :showSwitches=false
          @fetchData="fetchData"
          @changeQuery="changeQuery"
          :showPrice=true
          :showSizes=true
          @onClick="onClick"
      />
    </div>
  </div>
</template>

<script>
// Home.vue
import _ from "lodash";
import Table from "@/components/Table.vue";
import Resource from "@/api/seminare";
import {formatDateDMY, getCurrentDate} from "@/filters/index";
// import {formatDateDMY, getCurrentDate, convertDate} from "@/filters/index";

const seminareResource = new Resource();

export default {
  components: {
    Table,
  },
  data() {
    return {
      query: {
        page: 1,
        limit: 50,
        total: 0,
        search: '',
        sem_art: '',
        teilnahme_option: '',
        seminare_online_filter: this.$route.params.online_filter ? this.$route.params.online_filter : 0
      },
      array_color: {green: "#007f49", blue: "#2a3579", yellow: "#f49a01", red: "#bf2327"},
      sem_art: [],
      data: [],
      columns: [
        {field: "Color", text: ""},
        {field: "start_datum", text: "Datum"},
        {field: "seminar", text: "Seminar"},
        {field: "dozent", text: "Dozent"},
        {field: "end_datum", text: "Ende"},
        {field: "raum", text: "Raum"},
      ],
      detailColumns: [
        {field: "Color", text: ""},
        {field: "seminar", text: "Seminar"},
        {field: "dozent", text: "Dozent"},
        {field: "start_datum", text: "Beginn"},
        {field: "end_datum", text: "Ende"},
        {field: "raum", text: "Raum"},
      ],
      loading: true,
      current_date: getCurrentDate(),
    };
  },
  mounted() {
    this.fetchData(this.query);
    this.get_sem_art();
  },
  computed: {
    username() {
      return this.$route.params.username;
    },
  },
  created() {
    if (typeof this.$store.state.user.token == 'undefined' || this.$store.state.user.token === '') {
      this.$router.push({name: "login"});
    }
  },
  methods: {
    changeQuery(limit){
      this.query.limit = limit;
    },
    fetchData(query) {
      this.loading = true;
      const offset = (query.page - 1) * query.limit + 1;
      query.offset = offset;
      seminareResource.getSeminarOnlineFilter(query).then((res) => {
        this.query.total = _.get(res, "response.dataInfo.foundCount", 0);
        if (res.response && res.response.data) {
          this.data = [];
          res.response.data.map((value) => {
            var temp = {
              'id': value.fieldData['ID_Kurs'],
              'status_color': value.fieldData['seminar_search_filter::color'] ? this.array_color[value.fieldData['seminar_search_filter::color']] : "",
              'seminar': value.fieldData['Bezeichnung'],
              'dozent': value.fieldData['Seminarleiter'],
              'start_datum': formatDateDMY(value.fieldData['Datum']),
              'end_datum': formatDateDMY(value.fieldData['Endedatum']),
              'ort': value.fieldData['Ort'],
              'raum': value.fieldData['Raum'],
              'seminarart': value.fieldData['seminarart'],
              'online_gruppe_id': value.fieldData['online_gruppe_id'],
              'online_anzeige': value.fieldData['online_anzeige'],
              'color': value.fieldData['seminar_search_filter::color']
            }
            this.data.push(temp);
          });
        } else {
          this.data = [];
        }
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        const code = _.get(error, 'response.data.messages[0].code', 0);
        if (code == 952) {
          this.$router.push({name: "login"});
        }
      });
    },
    changeTabFilter(online_filter) {
      if (!this.loading) {
        this.data = [];
        this.query = {page: 1, limit: 10, total: 0, seminare_online_filter: online_filter, search: '', sem_art: '', teilnahme_option: ''};
        this.fetchData(this.query);
      }
    },
    onClick(row) {
      if (row.online_gruppe_id) {
        this.$router.push({name: "registerCourses", params: row});
      } else {
        this.$message({
          type: "warning",
          message: "Es gibt leider noch keine Kursdaten!",
        });
      }
    },
    get_sem_art() {
      seminareResource.getSeminarArten().then((res) => {
        if (res.response && res.response.data) {
          this.sem_art = [];
          res.response.data.map((value) => {
            var temp = {
              'id': value.fieldData['id'],
              'name' : value.fieldData['name'],
            }
            this.sem_art.push(temp);
          });
        }
      });
    },
    cancelSearch() {
      if (!this.loading) {
        this.data = [];
        this.query = {page: 1, limit: 10, total: 0, seminare_online_filter: 0, search: '', sem_art: '', teilnahme_option: ''};
        this.fetchData(this.query);
      }
    }
  },
};
</script>
<style scoped>

.btn-secondary {
  font-weight: bold;
  background-color: #464b5c;
}

.form-control {
  border-radius: 0.8em;
  border-color: #707070;
  border-width: 0.15rem;
  font-size: 1em;
  font-weight: bold;
  color: #464B5C;
}

.form-select {
  border-radius: 0.8em;
  border-color: #707070;
  border-width: 0.15rem;
  font-size: 1em;
  font-weight: bold;
  color: #464B5C;
}

.course {
  padding: 0 2rem;
}

.course div.col-6 {
  padding: 0rem 0 0.5rem 2rem;
  margin-top: -0.3rem;
}

div.card {
  width: 15rem;
  display: flex;
  flex-direction: column;
}

.card-further-courses {
  width: 100%;
  color: #fff;
  font-size: 1.2em;
  padding-bottom: 10%;
  padding-top: 10%;
  cursor: pointer;
  transition: all 0.3s;
}

.card-further-courses:active {
  box-shadow: 0px 0px 0px 2px #464b5c;
}


.card-further-courses:hover {
  opacity: 0.9;
}

.card-further-courses .card-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-green {
  background: #007f49;
  border-color: #007f49;
}

.card-blue {
  background: #2a3579;
  border-color: #2a3579;
}

.card-yellow {
  background: #f49a01;
  border-color: #f49a01;
}

.card-red {
  background: #bf2327;
  border-color: #bf2327;
}

.card-grey {
  background: #464b5c;
  border-color: #464b5c;
}

@media (max-width: 425px) {
  .course {
    padding: 0 4rem;
  }
}

@media (max-width: 767px) {
  .form-floating {
    margin-bottom: 0.8rem;
  }
}

@media (min-width: 1024px) {
  .card-further-courses {
    width: 100%;
    color: #fff;
    font-size: 1.2em;
    padding-bottom: 8%;
    padding-top: 8%;
    cursor: pointer;
    transition: all 0.3s;
  }
}

@media (min-width: 1440px) {
  .card-further-courses {
    width: 100%;
    color: #fff;
    font-size: 1.2em;
    padding-bottom: 10%;
    padding-top: 10%;
    cursor: pointer;
    transition: all 0.3s;
  }
}
</style>