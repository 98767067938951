// set function parseTime,formatTime to filter
// export { parseTime, formatTime } from '@/utils';

export function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }
  return time + label + 's';
}

export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time);
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute');
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour');
  } else {
    return pluralize(~~(between / 86400), ' day');
  }
}

/* Number formating*/
export function numberFormatter(num, digits) {
  const si = [
    { value: 1E18, symbol: 'E' },
    { value: 1E15, symbol: 'P' },
    { value: 1E12, symbol: 'T' },
    { value: 1E9, symbol: 'G' },
    { value: 1E6, symbol: 'M' },
    { value: 1E3, symbol: 'k' },
  ];
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value + 0.1).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol;
    }
  }
  return num.toString();
}

export function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','));
}

export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatCamelFromEnum(string) {
  if (typeof string === 'undefined' || string === ''){
    return '';
  }
  // eslint-disable-next-line no-useless-escape
  string = string.replace(/\_/g, ' ');
  return string.charAt(0).toUpperCase() + string.slice(1);
}
//yyyy-mm-dd to mm/dd/yyyy
export function formatDate(inputDate) {
  var date = new Date(inputDate);
  if (!isNaN(date.getTime())) {
      var day = date.getDate().toString();
      var month = (date.getMonth() + 1).toString();
      // Months use 0 index.
      return (month[1] ? month : '0' + month[0]) + '/' +
           (day[1] ? day : '0' + day[0]) + '/' + 
           date.getFullYear();
  }
}
// mm/dd/yyyy to yyyy-mm-dd
export function convertDate(usDate) {
  if(usDate != undefined){
    var dateParts = usDate.split(/(\d{1,2})\/(\d{1,2})\/(\d{4})/);
    return dateParts[3] + "-" + dateParts[1] + "-" + dateParts[2];
  } else {
    return 'tt.mm.jjjj';
  }
}
// dd.mm.yyyy
export function formatDateDMY(inputDate) {
  var date = new Date(inputDate);
  if (!isNaN(date.getTime())) {
      var day = date.getDate().toString();
      var month = (date.getMonth() + 1).toString();
      return (day[1] ? day : '0' + day[0]) + '.' + 
           (month[1] ? month : '0' + month[0]) + '.' +
           date.getFullYear();
  }
}

// hh:ii:ss to hh:ii
export function formatDateHI(inputDate) {
  if(inputDate) {
    var data = inputDate.split(':');
    data.pop();
    return data.join(':');
  }
  return inputDate;
}

// dd.mm.yyyy to hh:ii:ss
export function formatDateDMYHIS(inputDate) {
  var date = new Date(inputDate);
  if (!isNaN(date.getTime())) {
      var day = date.getDate().toString();
      var month = (date.getMonth() + 1).toString();
      var minute = date.getMinutes();
      var hour = date.getHours();
      var second = date.getSeconds();
      return (day[1] ? day : '0' + day[0]) + '.' + 
           (month[1] ? month : '0' + month[0]) + '.' +
           date.getFullYear()+ ' ' +
           hour + ':' + minute + ':' + second;
  }
}

// yyyy-mm-dd
export function getCurrentDate() {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;
  return today;
}

export function byteCalc (byte) {
  if (byte < 1000) {
    return byte + ' B'
  } else if (byte > 1000 && byte < 1000000) {
    return Math.round(byte/1000) + ' KB'
  } else if (byte > 1000000 && byte < 1000000000) {
    return Math.round(byte/1000000) + ' MB'
  } else {
    return Math.round(byte/1000000000) + ' GB'
  }

}