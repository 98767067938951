<template>
  <div class="courses content mb-4" v-loading="loading">
    <div class="row">
      <div class="fw-bold text-start fs-4">
        {{ this.$store.state.seminar.name }}<br/><br/>
      </div>
    <!--  <div class="col-12 text-end p-3">
        <el-button
            :loading="loading"
            type="success"
            round
            @click="exportPdf"
            class="btn-export"
        >
          Export PDF
        </el-button>
      </div> -->
    </div>
    <Table :data="data"
           :columns="columns"
           :showSwitches=false
           @onClick="onClick"
           :detailColumns="detailColumns"
           :pagination="null"
           @fetchData="fetchData"
           :showPrice=false
           :showSizes=true
    />
<div class="fw-bold text-start fs-4" v-if="check_permission">

       <br/>Alle Teilnehmer
      <div class="col-12 text-end p-3">
        <el-button
            :loading="loading"
            type="success"
            round
            @click="exportPdfDozent"
            class="btn-export"
        >
          Export PDF
        </el-button>
      </div>
    <Table :data="dataDozent"
           :columns="columnsDozent"
           :showSwitches=false
           @onClick="onClick"
           :detailColumns="detailColumnsDozent"
           :pagination="null"
           @fetchData="fetchDataDozent"
           :showPrice=false
           :showSizes=true
    />

  </div>

  </div>
</template>
<script>
// Home.vue
import _ from "lodash";
import Table from "@/components/Table.vue";
import Resource from "@/api/seminare";

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const seminareResource = new Resource();
const doc = new jsPDF();
var totalPagesExp = '{total_pages_count_string}';

export default {
  components: {
    Table,
  },
  data() {
    return {
      dataDozent: [],
      data: [],
      query: {seminar_id: 0,tn: 1},
      queryDozent: {seminar_id: 0},
      columns: [
        // { field: "status", text: "" },
        {field: "name", text: "Name"},
        {field: "plz", text: "Plz"},
        {field: "ort", text: "Ort"},
        {field: "phone", text: "Telefonnumer"},
        {field: "mail", text: "Mailadresse"},
      ],
      columnsDozent: [
        // { field: "status", text: "" },
        {field: "name", text: "Name"},
      ],
      detailColumns: [
        {field: "status", text: ""},
        {field: "name", text: "Name"},
        {field: "plz", text: "Plz"},
        {field: "ort", text: "Ort"},
        {field: "phone", text: "Telefonnumer"},
        {field: "mail", text: "Mailadresse"},
      ],
      loading: true,
      loadingDozent: true,
    };
  },
  mounted() {
    this.query.seminar_id = this.$route.params.id;
    this.query.dozenten_id = this.$store.state.user.dozent_id;
  },
  computed: {
    username() {
      return this.$route.params.username;
    },
  },
  created() {
    if (typeof this.$store.state.user.token == 'undefined' || this.$store.state.user.token === '') {
      this.$router.push({name: "login"});
    }
    this.query.seminar_id = this.$route.params.id;
    this.queryDozent.seminar_id = this.$route.params.id;
    this.check_permission = this.$store.state.user.dozent_id != '';
    this.fetchData(this.query);
    this.fetchDataDozent(this.queryDozent);
  },
  methods: {
    fetchData(query) {
      this.loading = true;
      seminareResource.getParticipantsBySeminar(query).then((res) => {
        if (res.response && res.response.data) {
          this.data = res.response.data.map((value) => {
            return {
              id: value.fieldData['ds_id'],
              name: value.fieldData['f_p_Name'],
              plz: value.fieldData['f_p_plz'],
              ort: value.fieldData['f_p_ort'],
              phone: value.fieldData['telefon'],
              mail: value.fieldData['mail'],
              seminarname: value.fieldData['seminarname'],
              tn_liste: value.fieldData['eintrag_tn_liste'],
            };
          });
        }
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        console.log('er', error);
        const code = _.get(error, 'response.data.messages[0].code', 0);
        if (code == 952) {
          this.$router.push({name: "login"});
        }
      });
    },
    fetchDataDozent(query) {
      this.loadingDozent = true;
      seminareResource.getParticipantsBySeminar(query).then((res) => {
        if (res.response && res.response.data) {
          this.dataDozent = res.response.data.map((value) => {
            return {
              id: value.fieldData['ds_id'],
              name: value.fieldData['f_p_Name'],
              plz: value.fieldData['f_p_plz'],
              ort: value.fieldData['f_p_ort'],
              phone: value.fieldData['telefon'],
              mail: value.fieldData['mail'],
              seminarname: value.fieldData['seminarname'],
              tn_liste: value.fieldData['eintrag_tn_liste'],
            };
          });
        }
        this.loadingDozent = false;
      }).catch((error) => {
        this.loadingDozent = false;
        console.log('er', error);
        const code = _.get(error, 'response.data.messages[0].code', 0);
        if (code == 952) {
          this.$router.push({name: "login"});
        }
      });
    },
    onClick(row) {
      console.log('data', row);
      // this.$router.push({ name: "courseDetail", params: { 'id': row.id} });
    },
    exportPdf() {
      this.loading = true;
      if (this.data.length) {
        var today = new Date().toISOString().replace(/[^0-9]/g, "").substring(0, 8);

        this.loading = false;
        var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        doc.text(this.data[0].seminarname, pageWidth / 2, 20, 'center');

        // const logo = '//diegrueneschule.obs.otc.t-systems.com/user/42838/profilepicture.png';
        // var imgLogo = new Image();
        // imgLogo.onload = function() {
        //     doc.addImage(this, 'PNG', 14, 20, 20, 20);
        // };
        // imgLogo.src = logo;

        autoTable(doc, {
          head: [['Name', 'Plz', 'Ort', 'Telefonnumer', 'Mailadresse']],
          columns: [
            {dataKey: 'name', header: 'Name'},
            {dataKey: 'plz', header: 'Plz'},
            {dataKey: 'ort', header: 'Ort'},
            {dataKey: 'phone', header: 'Telefonnumer'},
            {dataKey: 'mail', header: 'Mailadresse'},
          ],
          body: this.data,
          didDrawPage: function (data) {
            // Footer
            var str = 'Page ' + doc.internal.getNumberOfPages()
            if (typeof doc.putTotalPages === 'function') {
              str = str + ' of ' + totalPagesExp
            }
            doc.setFontSize(10)

            var pageSize = doc.internal.pageSize
            var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
            doc.text(str, data.settings.margin.left, pageHeight - 10)
          },
          showHead: 'everyPage',
          margin: {top: 30},
        });
        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPagesExp)
        }
        doc.save('Participants_' + today + '.pdf')
      } else {
        this.$message({
          type: "warning",
          message: "No data to export!",
        });
        this.loading = false;
      }
    },
    exportPdfDozent() {
      this.loadingDozent = true;
      if (this.dataDozent.length) {
        var today = new Date().toISOString().replace(/[^0-9]/g, "");

        this.loadingDozent = false;
        var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        doc.text(this.data[0].seminarname, pageWidth / 2, 20, 'center');

        // const logo = '//diegrueneschule.obs.otc.t-systems.com/user/42838/profilepicture.png';
        // var imgLogo = new Image();
        // imgLogo.onload = function() {
        //     doc.addImage(this, 'PNG', 14, 20, 20, 20);
        // };
        // imgLogo.src = logo;

        autoTable(doc, {
          head: [['Name']],
          columns: [
            {dataKey: 'name', header: 'Name'},
           
          ],
          body: this.dataDozent,
          didDrawPage: function (data) {
            // Footer
            var str = 'Page ' + doc.internal.getNumberOfPages()
            if (typeof doc.putTotalPages === 'function') {
              str = str + ' of ' + totalPagesExp
            }
            doc.setFontSize(10)

            var pageSize = doc.internal.pageSize
            var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
            doc.text(str, data.settings.margin.left, pageHeight - 10)
          },
          showHead: 'everyPage',
          margin: {top: 30},
        });
        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPagesExp)
        }
        doc.save('Teilnehmer_' + today + '.pdf')
      } else {
        this.$message({
          type: "warning",
          message: "No data to export!",
        });
        this.loading = false;
      }
    }
  },
};
</script>

<style scoped>

.btn-export {
  color: #FFF;
  background-color: #464b5c !important;
  border-color: #464b5c !important;
  transition: all 0.3s;
  border-radius: 1em !important;
}

.btn-export:hover {
  opacity: 0.8;
}

</style>
