<template>
    <div :class="classList" @click="changeStyle">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>
    </div>
</template>
<script>
export default {
  name: "Hamburger",
  data(){
      return {
          classList: 'hamburger',
      }
  },
  methods: {
      changeStyle(){
          if(this.classList == 'hamburger'){
              this.classList = 'hamburger change';
          }
          else{
              this.classList = 'hamburger';
          }
          this.$emit('onClick');
      }
  }
};
</script>


<style scoped>

.hamburger {
    flex-direction: column;
    cursor: pointer;
    height: 2rem;
}

.bar1, .bar2, .bar3 {
  width: 2.8rem;
  height: .4rem;
  background-color: #333;
  margin: .4rem 1rem;
  transition: 0.3s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-.6rem, .5rem);
  transform: rotate(-45deg) translate(-.8rem, .5rem);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-.3rem, -.3rem);
  transform: rotate(45deg) translate(-.5rem, -.3rem);
}

@media (min-width: 576px) {
    .hamburger {
        display: none!important;
    }
}
</style>