<template>
  <div class="container options-page central-content">
    <div class="header">
      <div class="centered">
        <img class="logo" src="/assets/images/logo.png"/>
      </div>
    </div>
    <img
        class="d-block d-sm-none"
        src="/assets/images/login-img.png"
        alt=""
        srcset=""
    />
    <div class="login text-center">
      <div class="login__title">Persönlicher Login-Bereich</div>
      <el-form :model="obj" :rules="rules" ref="loginForm" label-width="0">
        <el-form-item prop="username">
          <el-input
              v-model="obj.username"
              placeholder="E-Mail Adresse"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
              v-model="obj.password"
              placeholder="Passwort"
              show-password
              max-length="100"
          />
        </el-form-item>
        <div class="form-check text-start">
          <input type="checkbox" class="form-check-input" id="exampleCheck1"/>
          <label for="exampleCheck1"> Benutzerdaten merken</label>
        </div>
        <div class="flex-nowrap">
        <el-button
            :loading="loading"
            type="button"
            @click="submitForm"
            class="btn btn-primary btn-lg btn-submit"
        >Anmelden</el-button>
        <el-button
            :loading="loading"
            type="button"
            @click="showinfo" 
            variant="info"
            class="btn btn-info "
        >
          Info
        </el-button></div>
        <div class="form-check text-start" v-show="value" id="info">
<br/>Wir freuen uns über Deine Anmeldung.<br/><br/> Du wirst nun auf unserer Buchungsportal weitergeleitet, wo Du die Buchung abschließen kannst. Dazu ist ein Konto erforderlich. <br/>Falls Du noch kein Konto hast, wähle bitte Registrieren aus, ansonsten melde Dich mit Deinen persönlichen Anmeldedaten einfach an.
<br/><br/>Falls Du bereits ein Seminar bei uns gebucht hast und noch nicht im neuen Buchungsportal aktiv warst, nutze bitte die Funktion „Passwort vergessen“ mit Deiner bekannten E-Mail Adresse. <br/>Du erhältst dann einen Link per Mail, wo Du Dein Passwort vergeben kannst.
        </div>
        <div class="form-group pt-3">
          <div class="w-50 float-start text-start">
            <label class="btn-link-custom " @click="forgotPassword()"> Passwort vergessen</label>
          </div>
          <div class="w-50 float-end text-end">
            <label class="btn-link-custom " @click="register()"> Registrieren</label>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {login} from "@/api/auth";
import moment from "moment";

export default {
  name: "Login",
  props: {
    msg: String,
  },
  data() {
    return {
      value: false,
      obj: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          {required: true, message: "Bitte gib Deine E-Mailadresse an", trigger: "blur"},
        ],
        password: [
          {required: true, message: "Bitte gib dein Passwort an", trigger: "blur"},
          {
            min: 8,
            max: 50,
            message: "Length should be 8 to 50",
            trigger: "blur",
          },
        ],
      },
      loading: false,
    };
  },
  created() {
    if (this.$store.state.user.token) {
      this.$router.push({name: "dashboard"});
    }
  },
  methods: {
    submitForm() {
      this.loading = true;
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          login(this.obj).then((res) => {
            if (res != null) {
              if (res.status === 'success') {
                const userData = _.get(res, 'response.data[0].fieldData', '');
                const recordId = _.get(res, 'response.data[0].recordId', '');
                const token = _.get(res, 'token', '');
                this.$store.dispatch("user/login", {
                  token,
                  username: this.obj.username,
                  avatar: userData.profilepicture_url.indexOf('.com') !== -1 ? userData.profilepicture_url : 'https://diegrueneschule.obs.eu-de.otc.t-systems.com' + userData.profilepicture_url, //"https://i.pravatar.cc",
                  pfad_s3: userData.pfad_s3,
                  gender: userData.FrauHerr,
                  first_name: userData.Vorname,
                  last_name: userData.Nachname,
                  post_code: userData.PLZ,
                  location: userData.Ort,
                  phone: userData.Telefon,
                  road: userData.Strasse,
                  email: userData.Email,
                  title: userData.titel,
                  country: userData.land,
                  funk: userData.Funk,
                  date_of_birth: userData.GEBDATUM,
                  newsletter: userData.newsletter,
                  id_person: userData.ID_Person,
                  password: userData.passwort,
                  record_id: recordId,
                  dozent_id: userData.verknuepfung_dozent_id,
                  info_forum: userData.info_forum == 1 ,
                  eintrag_tn_liste: userData.eintrag_tn_liste == 1,
                  Adresszusatz: userData.Adresszusatz,
                  schuelerpreis: userData.schuelerpreis,
                  session_time_out: moment().add(process.env.VUE_APP_SESSION_LOGIN_TIME ? process.env.VUE_APP_SESSION_LOGIN_TIME : 2, 'hours').unix()
                });
                this.$router.push({name: "myCourse"});
              } else {
                console.log(res);
                let message = res.error_msg;
                this.$message({
                  type: "error",
                  message: message,
                });
              }
            }
            this.loading = false;
          }).catch((error) => {
            let message = _.get(error, 'messages[0].message', error.message);
            if (typeof message !== 'string' && 'email' in message && message.email.length > 0 && message.email[0] === 'validation.unique') {
              message = 'Email has been used!';
            }
            this.$message({
              type: "error",
              message: message,
            });
            this.loading = false;
          });
        } else {
          this.$message({
            type: "error",
            message: 'error submit!!',
          });
          this.loading = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
     showinfo() {
       if (this.value === true) { this.value = false } else { this.value = true }
      
      },
    forgotPassword() {
      this.$router.push({name: "forgotPassword"});
    },
    register() {
      this.$router.push({name: "register"});
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.central-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  padding: 0;
}

img {
  max-width: 100%;
}

.container > img {
  width: 100%;
}

.header {
  width: 100%;
  background-color: #c4c08e;
}

.header .title {
  padding: 1em 0;
  font-size: 2.5em;
}

.centered img {
  margin-top: 2rem;
}

.text-justify {
  display: flex;
  align-content: space-between;
  justify-content: center;
  padding: 1.1rem 1.3rem;
}

form {
  margin-top: 2.5rem;
  font-size: 1rem;
}

button.btn-primary {
  margin-top: 2.2rem;
  width: 80%;
 
}

button.btn-info {
  margin-top: 2.2rem;
  
 
}

.login {
  margin: 2em auto;
  width: 30rem;
  background: #c4c08e;
  color: #ffffff;
  border-radius: 1.2rem;
  font-family: Arial;
  padding: 2.5rem 2.5rem 2.5rem;
  max-width: 100%;
}

.login__title {
  font: bold 1.6rem "Cavolini";
}

.form-control {
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.8rem;
  margin: 1.2rem 0;
}

.form-check {
  padding-left: 2rem;
}

.form-check .form-check-input {
  float: none;
  margin-right: 0.5em;
  font-size: 1.2rem;
}

label {
  margin-top: 0.1rem;
}

.btn-submit {
  background-color: #464B5C;
  border-color: #565B5C;
  cursor: pointer;
  border-radius: 0.8rem;
  font-size: 1rem;
  padding: 0.8rem;
  line-height: 1.5;
  color: #fff;
  transition: all 0.3s;
}

.btn-info {
   background-color: #464B5C;
  border-color: #565B5C;
  cursor: pointer;
  border-radius: 0.8rem;
  font-size: 1rem;
  padding: 0.8rem;
  line-height: 1.5;
  color: #fff;
  transition: all 0.3s;
  
}

.btn-submit:hover, .btn-submit:focus, .btn-submit:active, .btn-info:hover, .btn-info:active, .btn-info:focus {
  background-color: #565B5C;
  border-color: #464B5C;
  color: #fff;
  box-shadow: none;
}

.btn-link-custom {
  transition: all 0.3s;
  border-bottom: 1px solid transparent;
}

.btn-link-custom:hover {
  border-bottom: 1px solid #fff;
  cursor: pointer;
}

@media (min-width: 576px) {
  .header {
    background-color: transparent;
  }
}
</style>
