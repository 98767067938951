<template>
  <div class="courses content mb-4" v-loading="loading">
    <div class=" fz-25 fw-bold text-start course__title">
      <p>Buchung stornieren von {{ this.$store.state.seminar.name }}</p>
    </div>
    <div class="course full-width text-start">
      <p class="form-label" v-html="cancel_booking_desc"></p>
    </div>
    <div class="text-end p-3">
      <button type="button" class="btn btn-success" :disabled="!cancel_booking_allow" @click="confirmCancelBooking">
        {{ cancel_booking_btn_label }}
      </button>
    </div>
  </div>
</template>
<script>
// Home.vue
import _ from "lodash";
import Resource from "@/api/seminare";
import {sendEmailCancelBooking} from "@/api/auth";
import {formatDateDMY} from "@/filters/index";

const seminareResource = new Resource();
import {getCurrentDate, convertDate} from "@/filters/index";

export default {
  components: {},
  data() {
    return {
      loading: false,
      cancel_booking_allow: false,
      cancel_booking_desc: "",
      cancel_booking_btn_label: "Buchung jetzt widerrufen",
      cancel_booking_status: "",
      current_date: getCurrentDate(),
      email: this.$store.state.user.email,
      first_name: this.$store.state.user.first_name,
      last_name: this.$store.state.user.last_name,
      seminar: "",
      start_date: "",
      end_date: '',
      zahlungsart: ""
    };
  },
  mounted() {
  },
  computed: {},
  created() {
    if (typeof this.$store.state.user.token == 'undefined' || this.$store.state.user.token === '') {
      this.$router.push({name: "login"});
    }
    this.cancelBooking();
  },
  methods: {
    cancelBooking() {
      this.loading = true;
      var course_ds_id = this.$store.state.user.course_ds_id;
      var course_angemeldet = this.$store.state.user.course_angemeldet;
      seminareResource.getSeminarByDsId({ds_id: course_ds_id}).then((res) => {
        if (res.response && res.response.data) {
          var data_res = res.response.data[0];
          var erstellt = data_res.fieldData['erstellt'];
          var datum = data_res.fieldData['SV_Seminare::Datum'];
          this.seminar = data_res.fieldData['SV_Seminare::Bezeichnung'];
          this.start_date = datum;
          this.end_date = data_res.fieldData['SV_Seminare::Endedatum'];
          this.zahlungsart = data_res.fieldData['Zahlungsart'];
          var widerrufverzicht = data_res.fieldData['widerruf_verwurf'];
          var kuendigungsprozess = data_res.fieldData['kuendigungsprozess'];
          var end_datum = this.$store.state.user.course_end_date;
          var date_current = new Date(this.current_date);
          var date_register = new Date(convertDate(erstellt));
          var date_start = new Date(convertDate(datum));
          var date_end = new Date(convertDate(end_datum));
          const diffTime = (date_current - date_register);
          const diffTimeStart = (date_start - date_current);
          const diffTimeCourse = (date_end - date_start);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          const diffDaysStart = Math.ceil(diffTimeStart / (1000 * 60 * 60 * 24));
          const diffDaysDuration = Math.ceil(diffTimeCourse / (1000 * 60 * 60 * 24));
          if (course_angemeldet == 'Warteliste') {
            this.cancel_booking_desc = "Du möchtest Dich von der Warteliste abmelden? Kein Problem - klick einfach auf den Jetzt Abmelden Button. Du kommst danach von uns eine Bestätigungs-E-Mail. Und wir freuen uns, wenn Du Dich zu einem späteren Zeitpunkt erneut für eine Fortbildung bei uns anmeldest.";
            this.cancel_booking_btn_label = "Jetzt Abmelden";
            this.cancel_booking_status = "5";
            this.cancel_booking_allow = true;
          } else {
            if ((0 <= diffDays && diffDays <= 14) && widerrufverzicht == 0) {
              this.cancel_booking_desc = "Du hast das Recht binnen vierzehn Tagen ohne Angabe von Gründen diese Kursbuchung zu widerrufen. Die Widerrufsfrist beginnt ab dem Zeitpunkt Deiner Buchung.<br>Die Widerrufsbelehrung findest Du mit unseren AGB in Deiner Buchungsbestätigung oder hier.<br>Du kannst Deine Buchung auch über nachfolgenden Widerrufen:";
              this.cancel_booking_btn_label = "Buchung jetzt widerrufen";
              this.cancel_booking_status = "1";
              this.cancel_booking_allow = true;
            } else if ((diffDaysStart > 14) && widerrufverzicht == 0) {
              this.cancel_booking_desc = "Bis 14 Tage vor Seminarbeginn kannst Du gegen eine Gebühr von 20,-- Euro von Deiner Seminarbuchung zurücktreten. Über die Stonierungsgebühr von 20,-- Euro erhältst Du eine gesonderte Rechnung.<br>Deinen Rücktritt kannst Du hier erklären:<br>Jetzt meinen Rücktritt mit Stornogebühr von EUR 20 ,-- erklären";
              this.cancel_booking_btn_label = "Buchung jetzt widerrufen";
              this.cancel_booking_status = "2";
              this.cancel_booking_allow = true;
            } else if (diffDaysStart >= 0 && diffDaysStart <= 14 || widerrufverzicht == 1) {
              this.cancel_booking_desc = "Innerhalb von 14 Tagen VOR Seminarbeginn ist ein Rücktritt vom Seminar nur noch mit kompletter Berechnung möglich. Wenn Du nicht am Seminar teilnehmen kannst, hast Du jedoch die Möglichkeit einen Ersatzteilnehmer zu benennen. Solltest Du einen Ersatz stellen können, ist es wichtig, dass Du bitte unbedingt VOR deinem Rücktritt mit dem Büro Kontakt aufnimmst.<br>Geht der Kurs länger als 6 Monate hast Du die Möglichkeit der Kündigung. Eine Kündigung ist immer zum Ende eines Halbjahres (6 Monate, 12 Monate, 18 Monate usw.) möglich und zwar mit einer Frist von sechs Wochen zu diesem Termin. Die Kündigung hat in Textform (z.B. E-Mail) zu erfolgen.<br>Deinen Rücktritt kannst Du hier erklären:";
              this.cancel_booking_btn_label = "Jetzt meinen Rücktritt mit kompletter Berechnung erklären";
              this.cancel_booking_status = "3";
              this.cancel_booking_allow = true;
            } else if (diffDaysStart < 0 && diffDaysDuration >= 180) {
              if(kuendigungsprozess==1){
              this.cancel_booking_desc = "Du hast bereits gekündigt. Wir melden uns zeitnah bei dir.";
              this.cancel_booking_btn_label = "Jetzt kündigen";
              this.cancel_booking_allow = false;
              }else{
                this.cancel_booking_desc = "Verträge, die länger als 6 Monate laufen kannst Du vorzeitig kündigen. Die Kündigung ist immer zum Ende eines Halbjahres (6 Monate, 12 Monate, 18 Monate usw.) möglich und zwar mit einer Frist von sechs Wochen zu diesem Termin. Der 1. Halbjahreszeitraum (6 Monate) läuft ab Kursbeginn: „Zeitpunkt des Kursbeginns“";
              this.cancel_booking_btn_label = "Jetzt kündigen";
              this.cancel_booking_status = "4";
              this.cancel_booking_allow = true;
              }
            } else {
              this.cancel_booking_desc = "Der Unterricht hat bereits begonnen. Für diesen Kurs gibt es keine Abmelde- oder Umbuchungsmöglichkeiten mehr.<br> Wir wünschen Dir viel Freude und Erfolg bei der Teilnahme.";
              this.cancel_booking_allow = false;
            }
          }
        } else {
          this.cancel_booking_allow = false
        }
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        console.log('er', error);
        const code = _.get(error, 'response.data.messages[0].code', 0);
        if (code == 952) {
          this.$router.push({name: "login"});
        }
      });
    },
    confirmCancelBooking() {
      this.loading = true;
      var course_ds_id = this.$store.state.user.course_ds_id;
      var cancel_booking_status = this.cancel_booking_status;

      if (course_ds_id && cancel_booking_status && this.cancel_booking_allow) {
        // console.log(course_ds_id, cancel_booking_status);
        let query_cancel_booking = {
          ds_id: course_ds_id,
          status_cancel: cancel_booking_status,
        }
        let query_send_mail = {
          status: cancel_booking_status,
          email: this.email,
          first_name: this.first_name,
          last_name: this.last_name,
          seminar: this.seminar,
          start_date: formatDateDMY(this.start_date),
          end_date: formatDateDMY(this.end_date),
          today: formatDateDMY(this.current_date),
          zahlungsart: this.zahlungsart,
        }
        seminareResource.cancelBookingSeminar(query_cancel_booking).then((res) => {
          console.log(res);
          sendEmailCancelBooking(query_send_mail).then((res) => {
            console.log(res);
          }).catch((error) => {
            let message = _.get(error, 'messages[0].message', error.message);
            this.$message({
              type: "error",
              message: message,
            });
            this.loading = false;
          });
          this.$message({
            type: "success",
            message: "Deine Buchung wurde erfolgreich storniert.",
          });
          this.$router.push({name: "myCourse"});
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
          console.log('er', error);
          const code = _.get(error, 'response.data.messages[0].code', 0);
          if (code == 952) {
            this.$router.push({name: "login"});
          }
        });
      }
    }
  },
};
</script>

<style scoped>

.btn-export {
  color: #FFF;
  background-color: #464b5c !important;
  border-color: #464b5c !important;
  transition: all 0.3s;
  border-radius: 1em !important;
}

.btn-export:hover {
  opacity: 0.8;
}

</style>
