<template>
  <div class="font-Cavolini mb-3">
    <h2>Widerrufsbelehrung</h2>
  </div>
  <div class="text-start mx-auto">
    <b>Widerrufsrecht für Verbraucher</b> <br/>
    (Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer
    gewerblichen noch ihrer selbstständigen beruflichen Tätigkeit zugerechnet werden können.) <br/> <br/>

    <b>Widerrufsrecht</b> <br/>
    Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.
    Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses. <br/> <br/>

    Um Ihr Widerrufsrecht auszuüben, müssen Sie uns <b>(Florian Wittpahl, Emilienstr. 19, 20529 Hamburg,
    Telefonnummer: 040 493207, E-Mail-Adresse: info@hafn.de)</b> mittels einer eindeutigen Erklärung (z.B. ein mit
    der Post versandter Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie
    können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist. <br/> <br/>

    Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor
    Ablauf der Widerrufsfrist absenden. <br/> <br/>

    <b>Folgen des Widerrufs</b> <br/> <br/>

    Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben,
    einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine
    andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich
    und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf
    dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie
    bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes
    vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet. <br/> <br/>

    Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen soll, so haben Sie uns einen
    angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des
    Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum
    Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht. <br/> <br/>

    <b>Ausschluss- bzw. Erlöschensgründe</b> <br/> <br/>

    Das Widerrufsrecht besteht nicht bei Verträgen zur Erbringung von Dienstleistungen im Zusammenhang mit
    Freizeitbetätigungen, wenn der Vertrag für die Erbringung einen spezifischen Termin oder Zeitraum vorsieht. <br /> <br />

    Das Widerrufsrecht erlischt bei einem Vertrag zur Erbringung von Dienstleistungen, wenn der Unternehmer die
    Dienstleistung vollständig erbracht hat und mit der Ausführung der Dienstleistung erst begonnen hat, nachdem der
    Verbraucher dazu seine ausdrückliche Zustimmung gegeben hat und gleichzeitig seine Kenntnis davon bestätigt
    hat, dass er sein Widerrufsrecht bei vollständiger Vertragserfüllung durch den Unternehmer verliert. <br /> <br />

  </div>
  <div class="mt-4">
    <input class="btn btn-success btn-lg w-25 fw-bold py-1" type="button" @click="goBack()" value="ZURÜCK">
  </div>
</template>

<script>
export default {
  name: "Widerrufsbelehrung",
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>

</style>