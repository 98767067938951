import _ from "lodash";
import store from '@/store';
import { ElMessage } from 'element-plus';
/**
 * Simple RESTful resource class
 */
class MessageResource {

  constructor(seminar_id) {
    this.seminar_id = seminar_id;
  }

  request({method, data, url, params}) {
    if (window.axios == null) {
      window.axios = require('axios');
    }
    return window.axios({
      baseURL: process.env.VUE_APP_HOST_REMOTE_API+'/seminare/'+this.seminar_id+'/messages',
      url: typeof url == 'undefined' || url == '' ?  '' : url.toString(),
      method,
      data,
      params,
      headers: {
          'Authorization' : 'Bearer '+ store.state.user.token
      },
    }).then((res) => {
      return res.data;
    }).catch((error) => {
      let message = _.get(error, 'response.data.message', error.message);
      ElMessage({
        message: message,
        type: 'error',
        duration: 5 * 1000,
      });
      return Promise.reject(error);
    });
  }
  list(query) {
    return this.request({
      method: 'get',
      params: query,
    });
  }
  login(query) {
    if (window.axios == null) {
      window.axios = require('axios');
    }
    return window.axios({
      baseURL:process.env.VUE_APP_HOST_REMOTE_API+'/seminare/'+this.seminar_id,
      url: '',
      method : 'put',
      data: query,
      headers: {
          'Authorization' : 'Bearer '+ store.state.user.token 
      },
    }).then((res) => {
      return res.data;
    }).catch((error) => {
      let message = _.get(error, 'response.data.message', error.message);
      ElMessage({
        message: message,
        type: 'error',
        duration: 5 * 1000,
      });
      return Promise.reject(error);
    });
  }
  get(id) {
    return this.request({
      url: id,
      method: 'get',
    });
  }
  store(resource) {
    return this.request({
      method: 'post',
      data: resource,
    });
  }
  update(id, resource) {
    return this.request({
      url:  id,
      method: 'put',
      data: resource,
    });
  }
  partialUpdate(id, resource) {
    return this.request({
      url:  id,
      method: 'patch',
      data: resource,
    });
  }
  destroy(id) {
    return this.request({
      url: id,
      method: 'delete',
    });
  }
  upvote(id) {
    return this.request({
      url: id +'/vote',
      method: 'post',
      data: {
        username: store.state.user.username
      }
    });
  }
  save(resource) {
    if (!resource.id || resource.id < 1) {
      return this.request({
        method: 'post',
        data: { ... resource, parent: null},
      });
    } else {
      return this.request({
        url: resource.id,
        method: 'put',
        data: { ... resource, parent: null},
      });
    }
  }
  getReply(id, query) {
    return this.request({
      url: id +'/reply',
      method: 'get',
      params: query,
    });
  }
}

export { MessageResource as default };
