<template>
  <div class="font-Cavolini mb-3">
    <h2>Allgemeine Geschäftsbedingungen und Kundeninformationen</h2>
  </div>
  <div class="text-start mx-auto">
    <b>I. Allgemeine Geschäftsbedingungen</b> <br/> <br/>

    <b>§ 1 Grundlegende Bestimmungen</b> <br/> <br/>

    <b>(1)</b> Die nachstehenden Geschäftsbedingungen gelten für Verträge, die Sie mit uns als Anbieter (Florian
    Wittpahl) über
    die Internetseite www.hafn.de oder über sonstige Fernkommunikationsmittel schließen. Soweit nicht anders vereinbart,
    wird der Einbeziehung gegebenenfalls von Ihnen verwendeter eigener Bedingungen widersprochen. <br/> <br/>

    <b>(2)</b> Verbraucher im Sinne der nachstehenden Regelungen ist jede natürliche Person, die ein Rechtsgeschäft zu
    Zwecken
    abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet
    werden kann. Unternehmer ist jede natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft,
    die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer selbständigen beruflichen oder gewerblichen Tätigkeit
    handelt. <br/> <br/>

    <b>§ 2 Gegenstand des Vertrages</b> <br/> <br/>

    <b>(1)</b> Gegenstand des Vertrages ist die Durchführung von Kursen, Seminaren, Ausbildungs- und
    Fortbildungsveranstaltungen im Bereich Naturheilkunde und Psychotherapie, nachfolgend insgesamt „Kurse“ genannt.
    <br/> <br/>

    <b>(2)</b> Wir bieten sowohl Vor-Ort-Kurse als auch Online-Kurse an. Die näheren Einzelheiten zum jeweiligen
    Kursangebot
    ergeben sich aus der Kursbeschreibung auf unserer Internetseite und im jeweiligen Angebot. <br/> <br/>

    <b>§ 3 Zustandekommen des Vertrages</b> <br/> <br/>

    <b>(1)</b> Unsere Angebote im Internet sind unverbindlich und kein verbindliches Angebot zum Abschluss eines
    Vertrages. <br/> <br/>

    <b>(2)</b> Sie können ein verbindliches Vertragsangebot (Buchung) über das Online-Buchungssystem abgeben.
    Dabei werden die zur Buchung beabsichtigten Kurse im „Seminarkorb“ abgelegt. Über die Schaltfläche „zum Seminarkorb“
    können Sie den „Seminarkorb“ aufrufen und dort jederzeit Änderungen vornehmen.
    Nach Anklicken der Schaltfläche „Jetzt zur Adresseingabe“ erfolgt die Eingabe Ihrer persönlichen Daten. Abschließend
    werden Ihnen hier die Buchungsdaten als Buchungsübersicht angezeigt.
    Vor Absenden der Buchung haben Sie die Möglichkeit, die Angaben in der Buchungsübersicht nochmals zu überprüfen, zu
    ändern (auch über die Funktion „zurück“ des Internetbrowsers) bzw. die Buchung abzubrechen.
    Mit dem Absenden der Buchung über die Schaltfläche „Den Seminarkorb kostenpflichtig buchen“ geben Sie ein
    verbindliches Angebot bei uns ab. <br/> <br/>

    Sie erhalten zunächst eine automatische E-Mail über den Eingang Ihrer Buchung, die noch nicht zum Vertragsschluss
    führt.<br/> <br/>

    <b>(3)</b> Sie können ferner ein verbindliches Vertragsangebot (Anmeldung) unter Verwendung des auf unserer
    Internetseite
    integrierten PDF-Anmeldeformulars (zum Ausdrucken) per Fax oder per Post bei uns abgeben. <br/> <br/>

    <b>(4)</b> Unter Bezugnahme auf Angebote auf unserer Internetseite www.hafn.de können Sie darüber hinaus ein
    verbindliches
    Vertragsangebot (Buchung bzw. Anmeldung) über sonstige Fernkommunikationsmittel (z.B. per Telefon oder E-Mail) bei
    uns abgeben. <br/> <br/>

    <b>(5)</b> Die Annahme des Angebots (und damit der Vertragsabschluss) erfolgt in jedem Fall durch Buchungs- bzw.
    Anmeldebestätigung in Textform (z.B. E-Mail).
    Sollten Sie binnen 5 Tagen keine Buchungs- bzw. Anmeldebestätigung erhalten haben, sind Sie nicht mehr an Ihre
    Buchung bzw. Anmeldung gebunden. Gegebenenfalls bereits erbrachte Leistungen werden in diesem Fall unverzüglich
    zurückerstattet. <br/> <br/>

    <b>(6)</b> Ihre Anfragen zur Buchung unserer Kurse oder zur Erstellung eines Angebotes sind für Sie unverbindlich.
    Wir
    unterbreiten Ihnen hierzu ein verbindliches Angebot in Textform (z.B. per E-Mail), welches Sie innerhalb von 5 Tagen
    (soweit im jeweiligen Angebot keine andere Frist ausgewiesen ist) annehmen können. <br/> <br/>

    <b>(7)</b> Die Abwicklung der Buchung bzw. Anmeldung und Übermittlung aller im Zusammenhang mit dem Vertragsschluss
    erforderlichen Informationen erfolgt per E-Mail zum Teil automatisiert. Sie haben deshalb sicherzustellen, dass die
    von Ihnen bei uns hinterlegte E-Mail-Adresse zutreffend ist, der Empfang der E-Mails technisch sichergestellt und
    insbesondere nicht durch SPAM-Filter verhindert wird. <br/> <br/>

    <b>§ 4 Durchführung der Kurse, Teilnahmebedingungen</b> <br/> <br/>

    <b>(1)</b> Die Durchführung der Kurse erfolgt in der in den jeweiligen Angeboten beschriebenen Form zu den
    vereinbarten
    Terminen. <br/> <br/>

    <b>(2)</b> Soweit die Durchführung der Kurse von der Teilnehmerzahl abhängig ist, ergibt sich die
    Mindest-Teilnehmerzahl
    aus dem jeweiligen Angebot. <br/>
    Wird die Mindest-Teilnehmerzahl nicht erreicht, informieren wir Sie spätestens 5 Tage vor Kursbeginn in Textform
    (z.B. E-Mail) über das Nichtstattfinden des gebuchten Kurses. Gegebenenfalls bereits erbrachte Leistungen werden in
    diesem Fall unverzüglich zurückerstattet. <br/> <br/>

    <b>(3)</b> Bei Absage einer Einzel-Veranstaltung aufgrund kurzfristigen Ausfalls des Dozenten wegen Krankheit oder
    aus
    sonstigem wichtigen Grund werden die bereits erbrachten Leistungen unverzüglich zurückerstattet. <br/>
    Bei Kursen, die aus mehreren Veranstaltungsterminen bestehen, erfolgt bei Absage eines Termins aufgrund
    kurzfristigen Ausfalls des Dozenten wegen Krankheit oder aus sonstigem wichtigen Grund die Nachholung des abgesagten
    Termins an einem Ersatztermin. Können Sie den Ersatztermin Ihrerseits wegen Krankheit oder aus sonstigen wichtigen
    Gründen nicht wahrnehmen, werden wir Ihnen einen alternativen Ersatztermin anbieten. Auf unser Verlangen müssen Sie
    uns jedoch einen Nachweis des Krankheitsfalles oder des sonstigen wichtigen Grundes vorlegen. <br/> <br/>

    <b>(4)</b> Sie haben den Anweisungen des Dozenten strikt Folge zu leisten, insbesondere wenn in den Kursen
    Anwendungen am
    Menschen geübt oder erprobt werden. <br/> <br/>

    <b>(5)</b> Soweit besondere Teilnahmebedingungen bestehen, werden diese im jeweiligen Angebot, im
    Online-Buchungsvorgang
    oder in dem auf unserer Internetseite integrierten PDF-Anmeldeformular ausgewiesen. <br/>
    Die besonderen Teilnahmebedingungen gelten ergänzend zu den vorliegenden AGB / Kundeninformationen.
    Sind in den besonderen Teilnahmebedingungen Bestimmungen enthalten, die von den vorliegenden AGB /
    Kundeninformationen inhaltlich abweichen, finden insoweit ausschließlich die Bestimmungen der besonderen
    Teilnahmebedingungen Anwendung. <br/> <br/>

    <b>(6)</b> Die Teilnahme an den Kursen erfolgt auf eigene Gefahr. Der Abschluss einer entsprechenden Unfall-
    und/oder
    Haftpflichtversicherung wird empfohlen. <br/> <br/>

    <b>(7)</b> Nach erfolgreicher Teilnahme an dem gebuchten Kurs erhalten Sie ein Teilnahme-Zertifikat, soweit in der
    jeweiligen Kursbeschreibung nichts anderes geregelt ist. <br/>
    Die erfolgreiche Teilnahme bzw. das Teilnahme-Zertifikat stellen keine Erlaubnis zur Ausübung der Naturheilkunde
    oder Psychotherapie dar. <br/> <br/>

    <b>(8)</b> Das Speichern von Unterricht oder Unterrichtsteilen mit Hilfe von Sprachaufzeichnungsgeräten oder Geräten
    mit
    Kamerafunktion ist nicht gestattet. <br/> <br/>

    <b>§ 5 Besondere Bestimmungen für Online-Kurse</b> <br/> <br/>

    <b>(1)</b> Die Durchführung der Online-Kurse erfolgt unter Nutzung eines Videokonferenz-Tools eines Drittanbieters,
    wie zum
    Beispiel „Zoom“ der Zoom Video Communications, Inc. (55 Almaden Boulevard, San Jose, CA 95113, USA). Nähere
    Einzelheiten zur Nutzung von Zoom finden Sie in nachfolgenden AGB-Regelungen, in unserer Datenschutzerklärung sowie
    in den Nutzungsbedingungen und der Datenschutzerklärung von Zoom, welche Sie im Internet über folgende Links
    aufrufen und einsehen können: <br/>
    <a href="https://zoom.us/de-de/terms.html" target="_blank">https://zoom.us/de-de/terms.html </a> <br/>
    <a href="https://zoom.us/de-de/privacy.html" target="_blank">https://zoom.us/de-de/privacy.html</a> <br/> <br/>


    <b>(2)</b> Detaillierte Informationen zur Durchführung des jeweiligen Online-Kurses unter Nutzung eines
    Videokonferenz-Tools, der hierfür erforderlichen technischen und sonstigen Voraussetzungen, einschließlich der
    Ihrerseits notwendigen Mitwirkungshandlungen erhalten Sie ferner in der Kursbeschreibung im jeweiligen Angebot, im
    Buchungs- bzw. Anmeldeprozess sowie in den nach der Buchung bzw. Anmeldung von uns oder ggf. vom Anbieter des
    Videokonferenz-Tools versandten E-Mail-Benachrichtigungen.<br/>
    In jedem Fall benötigen Sie einen Internetzugang, einen gängigen Browser sowie Computer, Laptop, Tablet oder
    Mobiltelefon, welche über Kamera und Mikrofon verfügen.<br/> <br/>

    <b>(3)</b> Soweit Zoom für den jeweiligen Online-Kurs von uns genutzt wird, und nichts Abweichendes vereinbart ist,
    gelten
    folgende Bestimmungen: <br/> <br/>

    <b>a)</b> Für die Teilnahme an dem Online-Kurs ist Ihre Anmeldung als Teilnehmer bei Zoom erforderlich. Wir
    hinterlegen
    hierzu vorab Ihre E-Mail-Adresse und Ihren Namen bei Zoom (zur Legitimation) und übersenden Ihnen den Link für Ihre
    Zoom-Teilnahme per E-Mail. Die einzelnen technischen Schritte der Anmeldung ergeben sich sodann aus der Menüführung
    von Zoom nach Aufrufen des übersandten Links. Sie finden auch Hinweise zur Vorgehensweise in unserer E-Mail. <br/>
    <br/>

    <b>b)</b> Im Rahmen der Anmeldung bei Zoom und des Anlegens eines Profils, werden Sie aufgefordert, einen
    Benutzernamen
    einzugeben. Bitte beachten Sie, dass Ihr Benutzername für andere Teilnehmer des Online-Kurses sichtbar ist. <br/>
    <br/>

    <b>c)</b> Sie sind verpflichtet, sich rechtzeitig vor Kursbeginn bei Zoom als Teilnehmer anzumelden sowie sich
    rechtzeitig
    zu den einzelnen Kursterminen über den Teilnahmelink einzuloggen. Der Zugang zum jeweiligen Kurstermin wird Ihnen
    hierfür bereits 15 Minuten vor Beginn von uns ermöglicht. <br/> <br/>

    <b>d)</b> Sie können Ihre Kamera und/oder Ihr Mikrofon unmittelbar vor der Teilnahme an dem jeweiligen Kurstermin
    sowie
    jederzeit während des Kurses ausschalten, wenn Sie von anderen Teilnehmern nicht gesehen und/oder nicht gehört
    werden wollen. <br/>
    Ferner kann auch der Dozent die Mikrofone der Teilnehmer zu Unterrichtszwecken zeitweise stumm schalten. Als
    Teilnehmer haben Sie dann die Möglichkeit, durch das virtuelle Heben einer Hand einen Wortbeitrag anzumelden. <br/>
    <br/>

    <b>e)</b> Der Unterricht in einigen Kursen wird mit Bild und Ton aufgezeichnet. Hierüber werden Sie bereits im
    Buchungs-
    bzw. Anmeldeprozess für den Online-Kurs informiert und um Einwilligung gebeten. Eine Kurs-Teilnahme ohne
    Einwilligung zur Aufzeichnung dieses Kurses ist nicht möglich. <br/>
    Die Aufzeichnung beginnt stets mit einem Warnhinweis. <br/>
    Sie haben die Möglichkeit, Ihre Kamera und/oder Ihr Mikrofon auszuschalten, soweit Sie eine Aufzeichnung mit Ihrem
    Bild und/oder Ton nicht wünschen. <br/>
    Bei Ausschaltung von Kamera und Mikrofon erscheint in der Aufzeichnung nur der Vorname und der Anfangsbuchstabe des
    Nachnamens. Dies lässt sich technisch nicht unterbinden. <br/> <br/>

    <b>f)</b> Wir stellen Ihnen sowie den anderen Teilnehmern unserer Online-Kurse die Kurs-Aufzeichnungen nur zu
    Unterrichtszwecken und nur in der Form des Streamings zur Verfügung. Streaming bedeutet dabei die digitale
    Übertragung der Inhalte über das Internet auf ein nutzerbetriebenes internetfähiges Endgerät in einer Weise, bei der
    die Daten für eine Echtzeitansicht bestimmt sind, nicht aber für einen (permanenten oder vorübergehenden) Download,
    ein Kopieren, ein Speichern oder einen Weitervertrieb durch den Nutzer. <br/>
    Das Streamen der Kurs-Aufzeichnung ist bis zu 14 Tage nach dem jeweiligen Kurstermin möglich. Innerhalb dieses
    Zeitraums haben Sie Zugriff auf eine unbegrenzte Anzahl an Wiederholungen. <br/> <br/>

    <b>g)</b> Es ist Ihnen untersagt, eigene Aufzeichnungen von Kursen oder Kursteilen anzufertigen. Etwaige Verstöße
    werden
    rechtlich verfolgt. <br/> <br/>

    <b>(4)</b> Soweit nicht Zoom, sondern ein anderes Videokonferenz-Tool für den jeweiligen Online-Kurs von uns genutzt
    wird,
    ergeben sich die näheren Einzelheiten zu diesem Tool und dessen Anbieter aus der Kursbeschreibung im jeweiligen
    Angebot, dem Buchungs- bzw. Anmeldeprozess und/oder den nach der Buchung bzw. Anmeldung von uns oder ggf. vom
    Anbieter des Videokonferenz-Tools versandten E-Mail-Benachrichtigungen. <br/>
    Ist nichts Abweichendes vereinbart, gelten in diesem Fall die Bestimmungen gemäß vorstehendem Abs. 3 entsprechend.
    <br/> <br/>

    <b>§ 6 Urheberrecht, Nutzungslizenz</b> <br/> <br/>

    <b>(1)</b> Die im Rahmen der Kurse verwendeten Unterlagen, Dokumente, Dateien oder sonstigen Werke sind
    urheberrechtlich
    geschützt. Sie erhalten eine Nutzungslizenz ausschließlich für die vertraglich vorgesehenen Zwecke Ihrer Teilnahme
    an dem jeweiligen Kurs. Ohne unsere ausdrückliche Zustimmung oder die Zustimmung des jeweiligen Urhebers ist eine
    darüberhinausgehende Nutzung nicht erlaubt. <br/> <br/>

    <b>(2)</b> Diese Unterlagen, Dokumente, Dateien oder sonstigen Werke – oder Teile davon – dürfen von Ihnen nicht
    ohne
    unsere ausdrückliche Zustimmung oder die Zustimmung des jeweiligen Urhebers in irgendeiner Form – privat wie
    beruflich – reproduziert, insbesondere unter Verwendung elektronischer Systeme verarbeitet, vervielfältigt,
    verbreitet oder zu öffentlichen Wiedergaben benutzt werden. <br/> <br/>

    <b>§ 7 Vertragslaufzeit, Kündigung bei Verträgen mit Laufzeitvereinbarung</b> <br/> <br/>

    <b>(1)</b> Soweit im jeweiligen Angebot eine bestimmte Laufzeit ausgewiesen ist, hat der zwischen Ihnen und uns
    geschlossene Vertrag die angegebene Laufzeit (Laufzeitvereinbarung). Mit Ablauf der Laufzeit endet der Vertrag
    automatisch; einer Kündigung bedarf es hierfür nicht. <br/> <br/>

    <b>(2)</b> Veranstaltungen, die länger als 6 Monate dauern, können vom Teilnehmer gekündigt werden. Die Kündigung
    ist immer
    zum Ende eines Kurshalbjahres (6 Monate, 12 Monate, 18 Monate usw.) möglich und zwar mit einer Frist von sechs
    Wochen zu diesem Termin. Der 1. Kurshalbjahreszeitraum (6 Monate) läuft ab Kursbeginn (Bei HP und HPP Kursen der 1.
    Tag des Monats, in dem der Kurs beginnt). Die Kündigung hat in Textform (z.B. E-Mail) zu erfolgen. <br/> <br/>

    <b>(3)</b> Das Recht zur fristlosen Kündigung aus wichtigem Grund bleibt hiervon unberührt. <br/> <br/>

    <b>(4)</b> Ihr gesetzliches Widerrufsrecht für Verbraucher wird hierdurch nicht berührt, es besteht unabhängig von
    dem
    Bestehen oder Nichtbestehen des Kündigungsrechts. <br/> <br/>

    <b>§ 8 Rücktrittsrecht bei Verträgen ohne Laufzeitvereinbarung</b> <br/> <br/>

    <b>(1)</b> Bei Verträgen ohne eine vereinbarte Laufzeit können Sie bis zu 2 Wochen vor Kursbeginn vom Vertrag
    zurücktreten.
    Die Rücktrittserklärung ist in Textform (z.B. E-Mail) an uns zu übermitteln; maßgeblich ist der Zeitpunkt des
    Eingangs der Rücktrittserklärung bei uns. <br/>
    Bei Rücktritt wird eine Bearbeitungsgebühr von Euro 20,00 € fällig. Ihnen bleibt der Nachweis vorbehalten, dass uns
    tatsächlich kein oder ein wesentlich geringerer Aufwand entstanden ist. <br/> <br/>

    <b>(2)</b> Weniger als 2 Wochen vor Kursbeginn ist der Rücktritt nicht mehr möglich, es sei denn, im jeweiligen
    Angebot ist
    ein Rücktrittsrecht ausdrücklich ausgewiesen oder wird mit Ihnen individuell vereinbart. <br/> <br/>

    <b>(3)</b> Ihr gesetzliches Widerrufsrecht für Verbraucher wird hierdurch nicht berührt, es besteht unabhängig von
    dem
    Bestehen oder Nichtbestehen dieses zusätzlichen Rücktrittsrechts. <br/> <br/>

    <b>§ 9 Zurückbehaltungsrecht</b> <br/> <br/>

    Ein Zurückbehaltungsrecht können Sie nur ausüben, soweit es sich um Forderungen aus demselben Vertragsverhältnis
    handelt. <br/> <br/>

    <b>§ 10 Rechtswahl</b> <br/> <br/>

    <b>(1)</b> Es gilt deutsches Recht. Bei Verbrauchern gilt diese Rechtswahl nur, soweit hierdurch der durch zwingende
    Bestimmungen des Rechts des Staates des gewöhnlichen Aufenthaltes des Verbrauchers gewährte Schutz nicht entzogen
    wird (Günstigkeitsprinzip). <br/> <br/>

    <b>(2)</b> Die Bestimmungen des UN-Kaufrechts finden ausdrücklich keine Anwendung. <br/> <br/>

    ______________________________________________________________________________________ <br/> <br/>

    <b> II. Kundeninformationen</b> <br/> <br/>

    <b>1. Identität des Anbieters</b> <br/> <br/>

    Florian Wittpahl <br/>
    Die Grüne Schule <br/>
    Emilienstr. 19 <br/>
    20529 Hamburg <br/>
    Deutschland <br/>
    Telefon: 040 493207 <br/>
    E-Mail: info@hafn.de <br/> <br/>

    <b>Alternative Streitbeilegung:</b> <br/>
    Die Europäische Kommission stellt eine Plattform für die außergerichtliche Online-Streitbeilegung (OS-Plattform)
    bereit, aufrufbar unter
    <a href="https://ec.europa.eu/odr" target="_blank">https://ec.europa.eu/odr</a>. <br/> <br/>

    Wir sind nicht bereit, an Streitbeilegungsverfahren vor Verbraucherschlichtungsstellen teilzunehmen. <br/> <br/>

    <b>2. Informationen zum Zustandekommen des Vertrages</b> <br/> <br/>

    Die technischen Schritte zum Vertragsschluss, der Vertragsschluss selbst und die Korrekturmöglichkeiten erfolgen
    nach Maßgabe des § 3 unserer Allgemeinen Geschäftsbedingungen (Teil I). <br/> <br/>

    <b>3. Vertragssprache, Vertragstextspeicherung</b> <br/> <br/>

    3.1. Vertragssprache ist deutsch. <br/> <br/>

    3.2. Der vollständige Vertragstext wird von uns nicht gespeichert. Vor Absenden der Buchung über das
    Online-Buchungssystem oder der Anmeldung unter Verwendung des auf unserer Internetseite integrierten
    PDF-Anmeldeformulars können die Vertragsdaten über die Druckfunktion des Browsers ausgedruckt oder elektronisch
    gesichert werden. Nach Zugang der Buchung bzw. Anmeldung bei uns werden die Buchungs- bzw. Anmeldedaten, die
    gesetzlich vorgeschriebenen Informationen bei Fernabsatzverträgen und die Allgemeinen Geschäftsbedingungen nochmals
    per E-Mail an Sie übersandt. <br/> <br/>

    3.3. Bei Anfragen erhalten Sie alle Vertragsdaten im Rahmen eines verbindlichen Angebotes in Textform übersandt,
    z.B. per E-Mail, welche Sie ausdrucken oder elektronisch sichern können. <br/> <br/>

    <b>4. Wesentliche Merkmale der Kurse</b> <br/> <br/>

    Die wesentlichen Merkmale der Kurse finden sich im jeweiligen Angebot. <br/> <br/>

    <b>5. Preise und Zahlungsmodalitäten</b> <br/> <br/>

    5.1. Die in den jeweiligen Angeboten angeführten Preise stellen Gesamtpreise dar; sie beinhalten alle
    Preisbestandteile mit Ausnahme der Mehrwertsteuer. <br/> <br/>

    5.2. Unsere Leistungen sind gemäß § 4 Ziffer 21 a) bb) Umsatzsteuergesetz steuerbefreit. Wir erheben daher keine
    Mehrwertsteuer und weisen diese auf der Rechnung auch nicht aus. <br/> <br/>

    5.3. Sie haben folgende Zahlungsmöglichkeiten, soweit nichts anderes vereinbart ist: <br/>
    <ul>
      <li>– Vorkasse per Überweisung</li>
      <li> – Ratenzahlung (soweit im jeweiligen Angebot ausgewiesen)</li>
    </ul>

    5.4. Kommen Sie bei Nutzung von Ratenzahlung mit der Zahlung einer Rate ganz oder teilweise länger als 5 Tage in
    Verzug, wird der gesamte noch offene Restbetrag in einer Summe sofort zur Zahlung fällig. <br/> <br/>

    5.5. Soweit im jeweiligen Angebot oder in der Rechnung nicht anders angegeben, sind die Zahlungsansprüche aus dem
    geschlossenen Vertrag (bei Nutzung von Ratenzahlung die erste Rate) sofort zur Zahlung fällig. <br/> <br/>

    5.6. Soweit nichts anderes vereinbart ist, hat die Zahlung spätestens 1 Tag vor Beginn des gebuchten Kurses zu
    erfolgen. Ansonsten behalten wir uns vor, Sie von der Teilnahme auszuschließen. <br/> <br/>

    <b>6. Gesetzliches Mängelhaftungsrecht</b> <br/> <br/>

    Es bestehen die gesetzlichen Mängelhaftungsrechte. <br/> <br/>

    <b>7. Vertragslaufzeit / Kündigung</b> <br/> <br/>

    Informationen zur Laufzeit des Vertrages sowie den Kündigungsbedingungen finden Sie in § 7 unserer Allgemeinen
    Geschäftsbedingungen (Teil I), sowie im jeweiligen Angebot. <br/> <br/>

    Diese AGB und Kundeninformationen wurden von den auf IT-Recht spezialisierten Juristen des Händlerbundes erstellt
    und werden permanent auf Rechtskonformität geprüft. Die Händlerbund Management AG garantiert für die
    Rechtssicherheit der Texte und haftet im Falle von Abmahnungen. Nähere Informationen dazu finden Sie unter:
    <a href="https://www.haendlerbund.de/agb-service" target="_blank">https://www.haendlerbund.de/agb-service</a>
    . <br/> <br/>

    <b><i>letzte Aktualisierung: 21.01.2022</i></b> <br/> <br/>
  </div>
  <div class="mt-4">
    <input class="btn btn-success btn-lg w-25 fw-bold py-1" type="button" @click="goBack()" value="ZURÜCK">
  </div>
</template>

<script>
export default {
  name: "Agb",
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>

</style>