// import { login, logout, getInfo } from '@/api/auth';
// import { isLogged, setLogged, removeToken } from '@/utils/auth';

const state = {
  id: null,
  user: null,
  // token: isLogged(),
  name: '',
  username: '',
  avatar: '',
  introduction: '',
  roles: [],
  permissions: [],
  forum_token: '',
  gender : "",
  first_name : "", 
  last_name : "",  
  post_code : "",  
  location : "", 
  phone : "",
  road : "",  
  email : "",
  title : "",
  country : "", 
  funk : "",  
  date_of_birth : "",
  newsletter : "",
  id_person : "",
  password: "",
  record_id: "",
  dozent_id: "",
  pfad_s3: "",
  info_forum: "",
  eintrag_tn_liste: "",
  cart_course: [],
  course_dozent_select: "",
  Adresszusatz: "",
  schuelerpreis: "",
  course_ds_id: "",
  course_end_date: "",
  course_angemeldet: "",
  session_time_out: ""
};

const mutations = {
  SET_ID: (state, id) => {
    state.id = id;
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions;
  },
  SET_FORUM_TOKEN: (state, token) => {
    state.forum_token = token;
  },
  SET_USER: (state, user) => {
    state.username = user.email;
    state.id = user.id_person;
    state.token = user.token;
    // state.name = user.name;
    state.avatar = user.avatar;
    state.gender = user.gender;
    state.first_name = user.first_name;
    state.last_name = user.last_name;
    state.post_code = user.post_code;
    state.location = user.location;
    state.phone = user.phone;
    state.road = user.road;
    state.email = user.email;
    state.title = user.title;
    state.country = user.country;
    state.funk = user.funk;
    state.date_of_birth = user.date_of_birth;
    state.newsletter = user.newsletter;
    state.id_person = user.id_person;
    state.password = user.password;
    state.record_id = user.record_id;
    state.dozent_id = user.dozent_id;
    state.pfad_s3 = user.pfad_s3;
    state.info_forum = user.info_forum;
    state.eintrag_tn_liste = user.eintrag_tn_liste;
    state.Adresszusatz = user.Adresszusatz;
    state.schuelerpreis = user.schuelerpreis;
    state.session_time_out = user.session_time_out;
  },
  REMOVE:  (state) => {
    state.id = null;
    state.token = '';
    state.name = '';
    state.avatar = '';
    state.session_time_out = '';
  },
  ADD_COURSE_TO_CART: (state, courses) => {
    state.cart_course = courses;
  },
  REMOVE_CART: (state) => {
    state.cart_course = [];
  },
  CHECK_COURSE_DOZENT: (state, dozentId) => {
    state.course_dozent_select = dozentId;
  },
  CHECK_COURSE_DS_ID: (state, dsId) => {
    state.course_ds_id = dsId;
  },
  CHECK_COURSE_ANGEMELDET: (state, angemeldet) => {
    state.course_angemeldet = angemeldet;
  },
  SET_END_DATE_COURSE: (state, endDate) => {
    state.course_end_date = endDate;
  },
};

const actions = {
  // user login
  login({ commit }, userInfo) {
    commit('SET_USER', userInfo);
  },
  setForumToken({ commit }, token) {
    commit('SET_FORUM_TOKEN', token);
  },

  // user login
  logout({ commit }) {
    commit('REMOVE');
  },

  // Dynamically modify permissions
  changeRoles({ commit, dispatch }, role) {
    return new Promise(resolve => {
      // const token = role + '-token';

      // commit('SET_TOKEN', token);
      // setLogged(token);

      // const { roles } = await dispatch('getInfo');

      const roles = [role.name];
      const permissions = role.permissions.map(permission => permission.name);
      commit('SET_ROLES', roles);
      commit('SET_PERMISSIONS', permissions);

      // generate accessible routes map based on roles
      dispatch('permission/generateRoutes', { roles, permissions });

      resolve();
    });
  },

  addCourseToCart({ commit }, courseInfo) {
    commit('ADD_COURSE_TO_CART', courseInfo);
  },
  removeCart({ commit }) {
    commit('REMOVE_CART');
  },
  checkCourseDozent({ commit}, dozentId) {
    commit('CHECK_COURSE_DOZENT', dozentId);
  },
  checkCourseDsId({ commit}, dsId) {
    commit('CHECK_COURSE_DS_ID', dsId);
  },
  checkCourseAngemeldet({ commit}, angemeldet) {
    commit('CHECK_COURSE_ANGEMELDET', angemeldet);
  },
  setEndDateCourse({ commit}, endDate) {
    commit('SET_END_DATE_COURSE', endDate);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
