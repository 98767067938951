<template>
    <div :class="classList" @click="changeStyle">
        <div class="bar1"></div>
        <div class="bar2"></div>
    </div>
</template>
<script>
export default {
  name: "CloseIcon",
  data(){
      return {
          classList: 'closer',
      }
  },
  methods: {
      changeStyle(){
          if(this.classList == 'closer'){
              this.classList = 'closer change';
          }
          else{
              this.classList = 'closer';
          }
          this.$emit('onClick');
      }
  }
};
</script>


<style scoped>

.closer {
    flex-direction: column;
    cursor: pointer;
    height: 2rem;
}

.bar1, .bar2 {
  width: 2rem;
  height: .3rem;
  background-color: red;
  margin: .15rem 0;
  transition: 0.4s;
}
.bar1{
  -webkit-transform: rotate(-45deg) translate(-.1rem, .2rem);
  transform: rotate(-45deg) translate(-.1rem, .2rem);
}
.bar2{
  -webkit-transform: rotate(45deg) translate(-.1rem, -.2rem);
  transform: rotate(45deg) translate(-.1rem, -.2rem);
}

</style>