<template>
  <div class="flex-row content mb-4">
    <div class="course full-width">
      <div class=" fz-25 fw-bold text-start course__title">
        Forum ({{ title }})
      </div>
      <div v-loading="loading">
        <el-row class="space-between mb-3 fw-bold">
          <el-col :span="12" class="d-flex align-items-center">
            <a @click="showForm = false">
              Alle Fragen in diesem Kurs ({{ query.total }})
            </a></el-col
          >
          <el-col :span="12" class="d-flex align-items-center justify-content-end">
            <el-select v-model="query.sort" value-key="value" @change="select">
              <el-option
                v-for="(item, index) in sorts"
                :value="item.value"
                :label="item.label"
                :key="'select-' + index"
              />
            </el-select>
            <el-button
              style="margin-left: 1rem"
              type="info"
              @click="onClick('messages_count', -1)"
              round
              size="mini"
            >
              <i class="bi bi-plus-lg"></i>
            </el-button>
          </el-col>
        </el-row>
        <div v-if="showForm">
          <ListItem
            v-if="obj.parent != null"
            :item="obj.parent"
            @action="onClick"
            :rightHeaders="false"
            :hasSubcomment="true"
            :index="-1"
            classCustom="bggrey"
          />
          <div class="sublist ml5rem">
            <list
              v-if="obj.parent != null && obj.parent.messages.length"
              :data="obj.parent.messages"
              @action="onClick"
              :rightHeaders="true"
              :hasSubcomment="false"
              :pagination="paginationReply"
              @fetchData="reply"
            />
          </div>
            <el-form :model="obj" :rules="rules" ref="form" class="ml5rem pt-3 border-top">
            <el-form-item prop="subject">
              <el-input
                v-if="!obj.parent"
                type="text"
                v-model="obj.subject"
                placeholder="Gegenstand"
                aria-label="Gegenstand"
                @keypress.enter.prevent="sendCommand"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="message">
              <el-input
                type="textarea"
                rows="4"
                v-model="obj.message"
                placeholder="Botschaft"
                aria-label="Botschaft"
              >
              </el-input>
            </el-form-item>
            <el-button
              :loading="sending"
              type="success"
              round
              @click="sendCommand"
              class="btn-submit"
            >
              Senden
            </el-button>
            <el-button :loading="loading" type="warning" class="btn-cancel" round @click="cancel">
              Cancel
            </el-button>
          </el-form>
        </div>
        <list
          v-else
          :data="messages"
          :pagination="query"
          @fetchData="fetchData"
          @action="onClick"
          :rightHeaders="true"
          :hasSubcomment="true"
        />
      </div>
    </div>
  </div>
</template>
<script>
// Home.vue
import _ from "lodash";
import MessageResource from "@/api/message";
import List from "@/components/List.vue";
import ListItem from "@/components/ListItem.vue";
import { readable } from "@/utils/datetime.js";
import { sendEmailNewPost } from "@/api/auth";
const defObj = {
  id: 0,
  seminar_id: 0,
  parent_id: 0,
  message: "",
  subject: "",
  user: {},
  parent: null,
  parent_idx: -1,
};
export default {
  components: { List, ListItem },
  props: {
     title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedIndex: -1,
      query: {
        page: 1,
        limit: 10,
        total: 0,
        sort: "created_at",
      },
      obj: { ...defObj },
      selectedMessage: null,
      loading: true,
      sending: false,
      messageResource: null,
      rules: {
        subject: {
          required: true,
          message: "Bitte Thema eingeben",
          trigger: "blur",
        },
        message: {
          required: true,
          message: "Bitte Nachricht eingeben",
          trigger: "blur",
        },
      },
      messages: [],
      showForm: false,
      types: ["all", "following", "your_question"],
      sorts: [
        {
          value: "upvote",
          label: "Most Up Vote",
        },
        {
          value: "created_at",
          label: "Most Recent",
        },
        {
          value: "subject",
          label: "Recommended",
        },
      ],
      paginationReply: { page: 1, limit: 10, total: 0, sort: "created_at" },
      email : this.$store.state.user.email,
      first_name : this.$store.state.user.first_name,
      last_name : this.$store.state.user.last_name,
      info_forum : this.$store.state.user.info_forum,
    };
  },
  mounted() {
    this.obj.user = this.$store.state.user;
    this.obj.username = this.$store.state.user.username;
    this.obj.seminar_id = this.$route.params.id;
    this.fetchData(this.query, true);
  },
  computed: {
    username() {
      return this.$route.params.username;
    },
  },
  methods: {
    cancel() {
      this.showForm = false;
      this.obj = { ...defObj };
    },
    select() {
      this.fetchData(this.query);
    },
    fetchData(query, createToken = false) {
      this.loading = true;
      this.query = _.merge(this.query, {
        limit: query.limit,
        page: query.page,
        username: this.$store.state.user.username,
      });
      if (this.messageResource == null) {
        this.messageResource = new MessageResource(this.obj.seminar_id);
      }
      if (createToken) {
        this.messageResource
          .list(this.query, createToken)
          .then((res) => {
            this.query.total = res.data.total;
            this.messages = res.data.data.map((value) => {
              return {
                ...value,
                created_at: readable(value.created_at),
              };
            });
            this.loading = false;
          })
          .catch((error) => {
            console.log("error submit!!", error);
            this.loading = false;
            return false;
          });
      } else {
        this.messageResource
          .list(this.query, createToken)
          .then((res) => {
            this.query.total = res.data.total;
            this.messages = res.data.data.map((value) => {
              return {
                ...value,
                created_at: readable(value.created_at),
              };
            });
            this.loading = false;
          })
          .catch((error) => {
            console.log("error submit!!", error);
            this.loading = false;
            return false;
          });
      }
    },
    sendCommand() {
      this.sending = true;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          console.log("saving", this.obj);
          if (this.messageResource == null) {
            this.messageResource = new MessageResource(this.obj.seminar_id);
          }
          if(this.obj.subject ==""){
            this.obj.subject = this.obj.parent.subject;
          }
          this.messageResource
            .save(this.obj)
            .then((response) => {
              if (this.obj.id > 0) {
                this.$message({
                  type: "success",
                  message: "Update completed",
                });
                if (this.obj.parent_idx > -1) {
                  this.obj.id = 0;
                  this.obj.parent = this.messages[this.obj.parent_idx];
                  this.obj.parent_id = this.obj.parent.id;
                  this.messages[this.obj.parent_idx].messages[
                    this.selectedIndex
                  ].message = response.data.message;
                  this.messages[this.obj.parent_idx].messages[
                    this.selectedIndex
                  ].subject = response.data.subject;
                  this.obj.parent.messages[this.selectedIndex].subject =
                    response.data.subject;
                  this.obj.parent.messages[this.selectedIndex].message =
                    response.data.message;
                } else {
                  this.messages[this.selectedIndex].subject =
                    response.data.subject;
                  this.messages[this.selectedIndex].message =
                    response.data.message;
                    this.obj = {...defObj};
                    this.showForm = false;
                    this.sending = false;
                    return;
                }
                this.obj.id = 0;
                this.obj.subject = "";
                this.obj.message = "";
                console.log("updated", this.obj);
                this.sending = false;
                return;
              }
              const res = response.data;
              console.log("response", res);
              res.created_at = readable(res.created_at);
              res.messages = [];
              res.type = "comment";
              if (res.parent_id == 0) {
                console.log("before list", this.messages);
                this.messages.unshift(res);
                this.showForm = false;
                console.log("after list", this.messages);
                // send mail create new post
                if(this.info_forum == true){
                  let query_send_mail = { 
                    email: this.email,
                    first_name: this.first_name, 
                    last_name: this.last_name, 
                    seminar: this.title,
                  }
                  console.log(query_send_mail);
                  sendEmailNewPost(query_send_mail).then((res) => {
                    console.log(res);
                  }).catch((error)=>{
                    let message = _.get(error, 'messages[0].message', error.message);
                    this.$message({
                      type: "error",
                      message: message,
                    });
                    this.loading = false;
                  });
                }
              } else {
                let len = this.messages.length;
                for (let i = 0; i < len; i++) {
                  let message = this.messages[i];
                  if (res.parent_id == message.id) {
                    this.messages[i].messages.unshift(res);
                    break;
                  }
                }
              }
              console.log("list", this.messages);
              this.obj.subject = "";
              this.obj.message = "";
              this.sending = false;
            })
            .catch((error) => {
              console.log("error", error);
              this.$router.push({
                name: "courseDetail",
                params: { id: this.obj.seminar_id },
              });
              this.sending = false;
            });
        } else {
          this.sending = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
    delete(id) {
      this.$confirm(
        "Diese Nachricht wird gelöscht. Fortfahren?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Abbrechen",
          type: "warning",
        }
      )
        .then(() => {
          this.sending = true;
          this.messageResource
            .destroy(id)
            .then(() => {
              if (this.obj.parent_idx > -1) {
                this.messages[this.obj.parent_idx].messages.splice(
                  this.selectedIndex,
                  1
                );
              } else {
                this.messages.splice(this.selectedIndex, 1);
              }
              this.obj = { ...defObj };
              this.$message({
                type: "success",
                message: "Löschen erfolgreich",
              });
              this.sending = false;
            })
            .catch((error) => {
              console.log("error", error);
              this.$router.push({
                name: "courseDetail",
                params: { id: this.obj.seminar_id },
              });
              this.sending = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Löschen abgebrochen.",
          });
        });
    },
    onClick(action, idx, id) {
      if (this.sending == true) {
        return false;
      }
      switch (action) {
        case "messages_count":
          if (idx > -1) {
            this.obj.parent = this.messages[idx];
            this.obj.parent_id = this.obj.parent.id;
            this.obj.parent_idx = idx;
            this.rules= {
              message: {
                required: true,
                message: "Bitte Nachricht eingeben",
                trigger: "blur",
              },
            }
          } else {
            if (this.obj.parent_idx > -1) {
              this.obj.parent = null;
              this.obj.parent_id = 0;
              this.obj.parent_idx = -1;
            }
            this.rules= {
              subject: {
                required: true,
                message: "Bitte Thema eingeben",
                trigger: "blur",
              },
              message: {
                required: true,
                message: "Bitte Nachricht eingeben",
                trigger: "blur",
              },
            }
          }

          this.reply(this.paginationReply, id);
          this.showForm = true;
          break;
        case "upvote":
          this.upvote(idx, id);
          break;
        case "edit":
          this.selectedIndex = idx;
          if (this.obj.parent_idx == -1) {
            this.obj.id = this.messages[idx].id;
            this.obj.subject = this.messages[idx].subject;
            this.obj.message = this.messages[idx].message;
          } else {
            this.obj.id = this.messages[this.obj.parent_idx].messages[idx].id;
            this.obj.subject =
              this.messages[this.obj.parent_idx].messages[idx].subject;
            this.obj.message =
              this.messages[this.obj.parent_idx].messages[idx].message;
          }
          console.log("editing", this.obj);
          this.showForm = true;
          break;
        case "delete":
          this.selectedIndex = idx;
          if (
            this.obj.parent_idx == -1 &&
            this.messages[idx].messages.length > 0
          ) {
            this.deleteMultiple(id);
            return;
          }else {
            this.delete(id);
          }
          break;
      }
    },
    upvote(idx, id) {
      let parent_idx = this.obj.parent_idx;
      if (idx == -1) {
        idx = parent_idx;
        parent_idx = -1;
      }
      this.sending = true;
      this.messageResource
        .upvote(id)
        .then((response) => {
          const res = response.data;
          if (parent_idx > -1 && idx > -1) {
            this.messages[parent_idx].messages[idx].upvote = res.upvote;
            this.messages[parent_idx].messages[idx].has_voted = res.has_voted;
          } else {
            this.messages[idx].upvote = res.upvote;
            this.messages[idx].has_voted = res.has_voted;
          }
          this.sending = false;
        })
        .catch((error) => {
          console.log(error);
          this.$router.push({
            name: "courseDetail",
            params: { id: this.obj.seminar_id },
          });
          this.sending = false;
        });
    },
    reply(paginationReply, id) {

      this.paginationReply = _.merge(this.paginationReply, {
        limit: paginationReply.limit,
        page: paginationReply.page,
        username: this.$store.state.user.username,
      });

     console.log(this.paginationReply);
      this.sending = true;
      this.messageResource
        .getReply(id ? id : this.obj.parent_idx, this.paginationReply)
        .then((response) => {
          console.log(response);
          this.paginationReply.total = response.data.total;
          this.sending = false;
          this.obj.parent.messages = response.data.data.map((value) => {
            return {
              ...value,
              created_at: readable(value.created_at),
            };
          });
        })
        .catch((error) => {
          console.log(error);
          this.sending = false;
        });
    },
    deleteMultiple(id) {
    this.$confirm(
        "Diese Nachricht wird gelöscht. Fortfahren?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.sending = true;
          this.messageResource
            .destroy(id)
            .then(() => {
              this.messages.splice(this.selectedIndex, 1);
              this.obj = { ...defObj };
              this.$message({
                type: "success",
                message: "Löschen erfolgreich",
              });
              this.sending = false;
            })
            .catch((error) => {
              console.log("error", error);
              this.$router.push({
                name: "courseDetail",
                params: { id: this.obj.seminar_id },
              });
              this.sending = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Löschen abgebrochen",
          });
        });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.course {
  padding: 0 2rem;
}

.course div.col-6 {
  padding: 0rem 0 0.5rem 2rem;
  margin-top: -0.3rem;
}

.course-image {
  margin-top: 5rem;
}
form {
  margin-bottom: 1rem;
}
div.card {
  width: 15rem;
  display: flex;
  flex-direction: column;
}

.course__title {
  line-height: 5rem;
}
.course__title small {
  font-size: 0.6em;
  font-style: italic;
}
.space-between {
  justify-content: space-between;
}
a {
  cursor: pointer;
}

.btn-submit {
  color: #FFF;
  background-color: #36793d!important;
  border-color: #36793d!important;
  transition: all 0.3s;
  border-radius: 1em!important;
}
.btn-cancel {
  color: #FFF;
  background-color: #f49b2d!important;
  border-color: #f49b2d!important;
  transition: all 0.3s;
  border-radius: 1em!important;
}
.btn-cancel:hover, .btn-submit:hover {
  opacity: 0.8;
}
.ml5rem {
  margin-left: 5rem;
}
</style>