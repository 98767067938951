<template>
  <div class="content mb-3">
    <div class="course full-width custom-justify">
      <p class="mb-2">Hier ist Deine Seminarauswahl. Nur noch ein paar Schritte und Deine Anmeldung ist komplett. Prüfe
        noch einmal, ob alles richtig ist und dann kann es schon bald los gehen.</p>
      <p class="mb-2">Wir freuen uns auf Dich. Dein Grüne Schule Team.</p>
    </div>
  </div>
  <div class="courses content mb-4" v-loading="loading">
    <div class="fz-25 fw-bold text-start course__title">
      Warenkorb
    </div>
    <CartListView
        v-if="data.length"
        :data="data"
        @onRemove="onRemove"
    />
    <div v-if="data.length" v-loading="this.loading">

      <div v-show="checkbox_besondere_bedingung_show" class="px-5 mt-5 row">
        <div class="col-1 text-start">
          <input class="mt-1" type="checkbox" id="checkbox_besondere_bedingung" v-model="checkbox_besondere_bedingung">
        </div>
        <div class="col-11">
          <div class="fw-bold text-start">
            <label for="checkbox_besondere_bedingung"> Besondere Bedingungen zur Anmeldung zur Heilpraktiker Ausbildung
              <span class="red">*</span></label>
          </div>
          <div class="text-start mt-2">
            <b>§ 1 Information</b> <br/> <br/>

            Der Teilnehmer versichert, dass er sich vor Anmeldung ausreichend über die Ausbildung und deren Inhalte
            informiert hat z.B. durch den Besuch einer Informationsveranstaltung oder ein persönliches
            Beratungsgespräch.
            <br/> <br/>
            <b>§ 2 Zweck des Vertrages</b> <br/> <br/>

            Die Schule vermittelt dem Teilnehmer Inhalte für die Ausübung der Heilkunde ohne Approbation und bereitet im
            Rahmen des Studiums auf die entsprechende Amtsärztliche Überprüfung vor. Das Prüfungsverfahren selbst ist
            nicht Gegenstand dieses Vertrages und muss vom Studierenden beim zuständigen Gesundheitsamt beantragt
            werden.
            Entfällt die Überprüfung auf Grund von behördlicher Vorschriften, steht den Parteien jeweils ein
            außerordentliches Kündigungsrecht zu. Wechselseitige Schadenersatzansprüche sind ausgeschlossen.<br/> <br/>
          </div>
        </div>
      </div>

      <div v-show="checkbox_kompakt_show" class="px-5 mt-4 row">
        <div class="col-1 text-start">
          <input type="checkbox" id="checkbox_kompakt" v-model="checkbox_kompakt">
        </div>
        <div class="col-11 text-start">
          <div class="text-start fw-bold">
            <label for="checkbox_kompakt"> Besondere Bedingungen zur Anmeldung zur Heilpraktiker Ausbildung
              <span class="red">*</span></label>
          </div>
          <div class="text-start mt-2">
            <b>Information (Kompakt)</b> <br/>

            Der Teilnehmer versichert, dass er die Voraussetzungen zum Besuch des Kurses erfüllt und Physio- oder
            Ergotherapeuth, Osteopath oder Notfallsanitäter ist. <br/> <br/>
          </div>
        </div>
      </div>

      <div v-show="checkbox_online_hybrid_show" class="px-5 mt-4 row">
        <div class="col-1 text-start">
          <input type="checkbox" id="checkbox_online_hybrid" v-model="checkbox_online_hybrid">
        </div>
        <div class="col-11 text-start">
          <div class="text-start fw-bold">
            <label for="checkbox_online_hybrid">Zusatzbestimmungen Online-/Hybridunterricht <span
                class="red">*</span></label>
          </div>
          <div class="text-start mt-2">
            <b>§ 3 Onlineunterricht</b> <br/> <br/>

            Dem Teilnehmer ist bekannt, dass der Unterricht online statt findet und dass die Unterrichtstermine
            aufgezeichnet und gespeichert werden. Sowohl der Teilnehmer als auch weitere Schüler können sich diese
            Aufzeichnung eine gewisse Zeit ansehen. Der Teilnehmer erklärt sich mit dieser Praxis insbesondere im Bezug
            auf seine Persönlichkeitsrechte und den Datenschutz einverstanden. Die Grüne Schule versichert, dass sie die
            Aufnahmen nicht für weitere Zwecke verwendet und auch nicht öffentlich zeigt. <br/> <br/>
          </div>
        </div>
      </div>

      <div class="px-5 mt-4 row">
        <div class="col-1 text-start">
          <input type="checkbox" id="checkbox_agb" v-model="checkbox_agb">
        </div>
        <div class="col-11 text-start fw-bold">
          <label for="checkbox_agb">Es gelten unsere 
            <a href="https://hafn.de/agb/" target="_blank">Allgemeinen Geschäftsbedingungen</a>,
            <a href="https://hafn.de/widerrufsrecht/" target="_blank">Widerrufsbestimmungen</a> und
            <a href="https://hafn.de/datenschutzerklaerung/" target="_blank">Datenschutzbestimmungen</a><span class="red">*</span></label>
        </div>
      </div>

      <div class="row px-5 mt-4">
        <div class="col-1 text-start">
          <input type="checkbox" id="checkbox_mitteilung" v-model="checkbox_mitteilung"/>
        </div>
        <div class="col-11 text-start">
          <div class="fw-bold">
            <label for="checkbox_mitteilung">Hast du eine abweichende Rechnungsadresse oder möchtest du uns eine Nachricht zukommen lassen ? (optional)</label>
          </div>
          <div>
            <textarea v-show="checkbox_mitteilung" v-model="optional_msg" rows="5" class="w-100 mt-2" placeholder="Bitte gib hier deine Nachricht und / oder deine abweichende Rechnungsadresse an."></textarea>
          </div>
        </div>
      </div>
      <div>
        <button type="button" class="btn dark-label btn-secondary mt-4" :disabled="check_btn()"
                style="border-radius: 12px;transition: all 0.3s;" @click="bookingSeminar()">
          Jetzt Zahlungspflichtig bestellen
        </button>
      </div>
      <div class="mt-3 text-end">
        Mit <span class="red">*</span> gekennzeichnete Felder sind Pflichfelder
      </div>
    </div>

    <div v-else>
      <span class="">leerer Warenkorb</span>
    </div>
  </div>
</template>

<script>
// Cart.vue
// import Table from "@/components/Table.vue";
import CartListView from "./components/CartListView";
import _ from "lodash";
import Resource from "@/api/seminare";
// import { formatDateDMY, formatDateHI } from "@/filters/index";
import {sendEmailConfirmBooking} from "@/api/auth";
import {formatDateDMY, formatDateHI} from "../../filters";

const seminareResource = new Resource();

export default {
  components: {
    // Table,
    CartListView
  },
  data() {
    return {
      array_color: {yellow: "#f49a01", green: "#007f49", total: "#2a3579", red: "#bf2327"},
      data: [],
      optional_msg: '',
      checkbox_mitteilung: '',
      checkbox_besondere_bedingung: '',
      checkbox_besondere_bedingung_show: '',
      checkbox_kompakt: '',
      checkbox_kompakt_show: '',
      checkbox_online_hybrid: '',
      checkbox_online_hybrid_show: '',
      checkbox_agb: '',
      current_cart: this.$store.state.user.cart_course,
      teilnehmer_id: this.$store.state.user.id_person,
      email: this.$store.state.user.email,
      first_name: this.$store.state.user.first_name,
      last_name: this.$store.state.user.last_name,
      columns: [
        // { field: "id", text: "Id" },
        {field: "seminar", text: "Seminar"},
        {field: "unterrichtszeiten", text: "Unterrichtszeiten"},
        {field: "kursdaten", text: "Kursdaten"},
        {field: "ort", text: "Ort"},
        {field: "gebuehr", text: "Gebühr"},
        {field: "rate", text: "rate"},
        {field: "rate_anzahl", text: "rate"},
        {field: "credits", text: "credits"},
        {field: "payment_api_value", text: "Bezahlung"},
        {field: "online_table_value", text: "Online"},
        {field: "removeCart", text: "Action"},
      ],
      detailColumns: [
        {field: "seminar", text: "Seminar"},
        {field: "unterrichtszeiten", text: "Unterrichtszeiten"},
        {field: "kursdaten", text: "Kursdaten"},
        {field: "ort", text: "Ort"},
        {field: "gebuehr", text: "Gebühr"},
        {field: "rate", text: "rate"},
        {field: "rate_anzahl", text: "rate"},
        {field: "credits", text: "credits"},
        {field: "payment_api_value", text: "Bezahlung"},
        {field: "online_table_value", text: "Online"},
        {field: "removeCart", text: "Action"},
      ],
      loading: true,
    };
  },
  created() {
  },
  mounted() {
  //   this.get_cart();
    this.showShopingCart();
    this.updateTeilnehmerNumber();
  },
  computed: {},
  methods: {
    
    check_btn() {
      if (this.checkbox_besondere_bedingung_show == true) {
        if (this.checkbox_kompakt_show == true) {
          if (this.checkbox_online_hybrid_show == true) {
            return !(this.checkbox_besondere_bedingung && this.checkbox_kompakt && this.checkbox_online_hybrid && this.checkbox_agb);
          } else {
            return !(this.checkbox_besondere_bedingung && this.checkbox_kompakt && this.checkbox_agb);
          }
        } else {
          if (this.checkbox_online_hybrid_show == true) {
            return !(this.checkbox_besondere_bedingung && this.checkbox_online_hybrid && this.checkbox_agb);
          } else {
            return !(this.checkbox_besondere_bedingung && this.checkbox_agb);
          }
        }
      } else {
        if (this.checkbox_kompakt_show == true) {
          if (this.checkbox_online_hybrid_show == true) {
            return !(this.checkbox_kompakt && this.checkbox_online_hybrid && this.checkbox_agb);
          } else {
            return !(this.checkbox_kompakt && this.checkbox_agb);
          }
        } else {
          if (this.checkbox_online_hybrid_show == true) {
            return !(this.checkbox_online_hybrid && this.checkbox_agb);
          } else {
            return !(this.checkbox_agb);
          }
        }
      }

    },
    show_boxes() {
      for (var h = 0; h < this.current_cart.length; h++) {
        const besondere_bedingung_online_grp_id = [13, 16, 22];
        if (besondere_bedingung_online_grp_id.includes(this.current_cart[h].online_grp_id)) {
          this.checkbox_besondere_bedingung_show = true;
          break;
        } else {
          this.checkbox_besondere_bedingung_show = false;
        }
      }
      for (var i = 0; i < this.current_cart.length; i++) {
        const kompakt_online_grp_id = [22];
        if (kompakt_online_grp_id.includes(this.current_cart[i].online_grp_id)) {
          this.checkbox_kompakt_show = true;
          break;
        } else {
          this.checkbox_kompakt_show = false;
        }
      }
      for (var j = 0; j < this.current_cart.length; j++) {
        if (this.current_cart[j].online<3 ) {
          this.checkbox_online_hybrid_show = true;
          break;
        } else {
          this.checkbox_online_hybrid_show = false;
        }
        //console.log(this.checkbox_online_hybrid_show);
      }
      this.loading = false;
    },
    showShopingCart() {
      this.loading = true;
      let current_cart = this.current_cart;
      if (current_cart.length) {
        current_cart.map((value) => {
          if (value.id) {
            //console.log(value);
            let temp = {
              'id': value.id,
              'kursdaten': value.Kursdaten,
              'seminar': value.seminar,
              'ort': value.ort,
              'removeCart': value.id,
              'online': value.online,
              'online_check_value': value.online_check_value,
              'online_api_value': value.online_check_value,
              'online_table_value': value.online_check_value,
              'online_grp_id': value.online_grp_id,
              'payment_value': value.payment_value,
              'payment_api_value': value.payment_value == 'credits' ? 'Credits' : value.payment_value == 'ratenzahlung' ? 'Ratenzahlung' : 'Rechnung',
              'gebuehr': value.gebuehr,
              'rabatt': value.rabatt,
              'rate_gesamt': value.rate_gesamt,
              'rate_anzahl': value.rate_anzahl,
              'rate_monatlich': value.rate_monatlich,
              'teilnehmer_max': value.teilnehmer_max,
              'teilnehmer_number': value.teilnehmer_number,
              'unterrichtszeiten': value.unterrichtszeiten,
              'credits': value.credits,
              'end_date': value.end_date,
              'end_time': value.end_time,
              'plz': value.plz,
              'raum': value.raum,
              'seminarort': value.seminarort,
              'start_date': value.start_date,
              'start_time': value.start_time,
              'strasse': value.strasse,
            };
            this.data.push(temp);
             console.log(temp);
          }
        });
        this.show_boxes();
      } else {
        this.loading = false;
      }
    },
    updateTeilnehmerNumber() {
      let current_cart = this.current_cart;
      if (current_cart.length) {
        current_cart.map((value) => {
          let online=value.online_check_value;
          seminareResource.getSeminarByKurs({kurs_id: value.id}).then((res) => {
            if (res.response && res.response.data) {
              let data_res = res.response.data[0]['fieldData'];
              var dataIndex = this.data.findIndex(x => x.id === value.id);
              if (dataIndex >= 0) {

                



                if(online==2){
                  this.data[dataIndex]['teilnehmer_max'] = data_res['Teilnehmer_Online'];
                  this.data[dataIndex]['teilnehmer_number'] = data_res['F_AnzahlTeilnehmer_Online'];
                }else if(online==1 || online==3){
                  this.data[dataIndex]['teilnehmer_max'] = data_res['Teilnehmer_Hybrid'];
                  this.data[dataIndex]['teilnehmer_number'] = data_res['F_AnzahlTeilnehmer_Hybrid'];
                }else{
                  this.data[dataIndex]['teilnehmer_max'] = data_res['TeilnehmerMax'];
                  this.data[dataIndex]['teilnehmer_number'] = data_res['F_AnzahlTeilnehmer'];
                }
                

                this.data[dataIndex]['start_date'] = formatDateDMY(data_res['Datum']);
                this.data[dataIndex]['end_date'] = formatDateDMY(data_res['Endedatum']);
                this.data[dataIndex]['start_time'] = formatDateHI(data_res['Zeit']);
                this.data[dataIndex]['end_time'] = formatDateHI(data_res['Zeit_ende']);

                this.data[dataIndex]['strasse'] = data_res['Strasse'];
                this.data[dataIndex]['plz'] = data_res['plz'];
                this.data[dataIndex]['seminarort'] = data_res['seminarort'];
                this.data[dataIndex]['raum'] = data_res['Raum'];
              }
            }
          }).catch((error) => {
            console.log('er', error);
            const code = _.get(error, 'response.data.messages[0].code', 0);
            if (code == 952) {
              this.$router.push({name: "login"});
            }
          });
        });
      }
    },
    onRemove(id) {
      this.$confirm(
          "Möchtest Du diesen Kurs aus deiner Bestellung entfernen?",
          "aus dem Warenkorb entfernen",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Abbrechen",
            type: "warning",
          })
          .then(() => {
            let current_cart = this.current_cart;
            let check_result = current_cart.map(function (e) {
              return e.id;
            }).indexOf(id);
            if (check_result != '-1') {
              current_cart.splice(check_result, 1);
              this.$store.dispatch("user/addCourseToCart", current_cart);
            }
            this.data = [];
            this.showShopingCart();
          }).catch(() => {
        console.log('cancel');
      });
    },
    bookingSeminar() {
      this.$confirm(
          "Möchten Sie sich für diese " + this.data.length + " Kurse anmelden?",
          "Kurszahlung",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Abbrechen",
            type: "warning",
          })
          .then(() => {
            this.data.forEach((item, index) => {
              this.loading = true;
              setTimeout(() => {
                let query_booking = {
                  type: item.teilnehmer_number >= item.teilnehmer_max ? 'seminar_einbuchen_warteliste' : 'seminar_einbuchen',
                  teilnehmer_id: this.teilnehmer_id,
                  seminar_id: item.id,
                  online: item.online_api_value,
                  bezahlung: item.payment_api_value
                }
                seminareResource.getBookingSeminar(query_booking).then(() => {
                  let query_send_mail = {
                    type: item.teilnehmer_number >= item.teilnehmer_max ? 'seminar_einbuchen_warteliste' : 'seminar_einbuchen',
                    email: this.email,
                    first_name: this.first_name,
                    last_name: this.last_name,
                    seminar: item.seminar,
                    start_date: item.start_date,
                    end_date: item.end_date,
                    start_time: item.start_time,
                    end_time: item.end_time,
                    ort: item.ort,
                    strasse: item.strasse,
                    plz: item.plz,
                    seminarort: item.seminarort,
                    raum: item.raum,
                    optional_msg: this.optional_msg,
                    bezahlung: item.payment_value,
                    online: item.online_check_value,
                  }
                  sendEmailConfirmBooking(query_send_mail).then((res) => {
                    console.log(res);
                    if (res != null) {
                      if (res.response) {
                        this.$message({
                          type: "success",
                          message: res.response,
                        });
                      } else {
                        this.$message({
                          type: "error",
                          message: res.error,
                        });
                      }
                    }
                  }).catch((error) => {
                    let message = _.get(error, 'messages[0].message', error.message);
                    this.$message({
                      type: "error",
                      message: message,
                    });
                    this.loading = false;
                  });

                  if (index == this.data.length - 1) {
                    this.$message({
                      type: "success",
                      message: "Deine Buchung wurde erfolgreich durchgeführt.",
                    });
                    //remove cart
                    this.$store.dispatch("user/removeCart");
                    setTimeout(() => {
                      this.data = [];
                      this.loading = false;
                    }, 1000 * (index + 1))
                  }
                }).catch((error) => {
                  this.loading = false;
                  console.log('er', error);
                  const code = _.get(error, 'response.data.messages[0].code', 0);
                  if (code == 952) {
                    this.$router.push({name: "login"});
                  }
                });
              }, 2000 * (index + 1))
            });
          }).catch(() => {
        console.log('cancel');
      });
    }
  },
};
</script>
<style scoped>
.course {
  padding: 0 2rem;
}

.course div.col-6 {
  padding: 0rem 0 0.5rem 2rem;
  margin-top: -0.3rem;
}

.course__title {
  line-height: 5rem;
}

.custom-justify {
  text-align: justify;
}

.red {
  color: #bf2327;
}
</style>