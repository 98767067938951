<template>
  <div class="navbar">
    <div class="container container-custom mb-sm-3">
      <ul class="flex-row row">
        <li :class="index == selectedIndex ? 'active nav-col' :  'nav-col'" v-for="(item, index) in tabs"
            :key="index">
          <div v-if="item.text =='Abmeldung'" @click="changeIndex(item, index, disabled)"
               :class="index == selectedIndex ? 'selected tab pointer ' + disabled : 'tab pointer ' + disabled">
            {{ item.text }}
          </div>
          <div v-else @click="changeIndex(item, index)"
               :class="index == selectedIndex ? 'selected tab pointer' : 'tab pointer'">
            {{ item.text }}
          </div>
        </li>
        <li v-if="showHomeButton === true" class="home-btn nav-col">
          <div class="tab pointer" @click="backToMyCourse">
            Home <span class="home-arw"> &gt; </span>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <nav class="navbar navbar-light bg-light pt-2 navbar-mobile">
    <div class="container-fluid">
      <a class="navbar-brand" href="#"></a>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav">
          <a v-for="(item, index) in tabs" :key="index"
             :class="index == selectedIndex ? 'nav-link active' :  'nav-link'" aria-current="page" href="#"
             @click="changeIndex(item, index)">{{ item.text }}</a>
          <a v-if="showHomeButton === true" @click="backToMyCourse"
             :class="selectedIndex ? 'nav-link active' :  'nav-link'" aria-current="page"
             href="#"> Home ></a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
// import Hamburger from "./Hamburger.vue";

export default {
  components: {
    // Hamburger,
  },
  props: {
    tabs: {
      type: Array,
    },
    selectedIndex: {
      type: Number,
      require: true,
    },
    showHomeButton: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      secIndex: 2,
    };
  },
  methods: {
    backToMyCourse() {
      this.$router.push({name: 'myCourse'});
    },
    changeIndex(item, index, disabled = '') {
      if (index !== this.selectedIndex && disabled !== 'disabled') {
        this.$router.push(item.route);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar {
  padding-bottom: 0;
  margin-top: 2rem;
}

.navbar ul {
  padding: 0;
  margin: 0;
  width: 100%;
}

.navbar ul li {
  padding-left: 0;
  padding-right: 0;
}

.navbar ul li:last-child {
  margin-right: -0.5rem;
  flex-grow: 1;
}

.navbar ul li div.tab {
  background: #FFFFFF;
  margin: 0;
  font-family: "Cavolini";
  font-weight: bold;
  font-size: 1.4rem;
  text-shadow: 0.05rem 0 currentColor;
  height: 100%;
  text-align: left;
  padding: 1rem 1rem 0.5rem 1rem;
  transition: all 0.3s;
}

.navbar ul li div.selected {
  background-color: #464b5c;
  color: #FFFFFF;
}

.navbar ul li.home-btn div.tab {
  display: grid;
  background-color: #C4C08E;
  text-align: center;
  line-height: 1.5rem;
  padding-top: 0.5rem;
  color: #FFFFFF;
  padding-bottom: 0.4rem;
}

.home-arw {
  display: none;
}

.navbar-mobile .nav-link {
  text-align: left;
  font-weight: bold;
  font-size: 1.5em;
  padding-left: 1em;
  color: rgba(0, 0, 0, 0.70);
  padding: 1.5rem 1rem;
}

.navbar-mobile .nav-link.active {
  color: #C4C08E;
}

.navbar-mobile .navbar-toggler {
  color: transparent;
  border-color: transparent;
}

.navbar-mobile .navbar-toggler .hamburger {
  display: flex;
}

.nav-col .disabled {
  background: darkgray !important;
  cursor: not-allowed;
}

@media (max-width: 575px) {
  .navbar {
    padding: 0;
    margin: 0;
  }

  .navbar .container {
    padding: 0;
  }

  .navbar ul li {
    display: none;
    background-color: #464B5C;
    color: #ffffff;
    padding: 1rem;
  }

  .navbar ul li.active {
    display: block;
  }

  .navbar ul li.active div.selected {
    background-color: #464B5C;
    color: #ffffff;
    text-align: center;
  }

  .navbar ul li div.tab {
    font-size: 2rem;
  }

  .navbar-mobile {
    display: block;
    font-family: "Cavolini";
  }
}

@media (min-width: 576px) {
  .nav-col {
    flex: 1 0 0%;
  }

  .home-btn {
    margin-top: 1rem;
    max-width: 7.5rem;
  }

  .navbar ul {
    margin: 0 -0.5rem;
  }

  .navbar ul li div.tab {
    margin: 0 0 0.5rem 1rem;
    border-radius: 1rem 1rem 1rem 1rem;
  }

  .home-arw {
    display: block;
    font-size: 2rem;
    font-weight: 500;
  }

  .navbar-mobile {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar ul li.home-btn div.tab {
    padding-top: 0.5rem;
  }
}

@media (min-width: 1400px) {
  .navbar ul li.home-btn div.tab {
    padding-top: 0.4rem;
  }
}

@media (min-width: 1500px) {
  .container-custom {
    max-width: 90vw;
  }

  .container-custom ul li:first-child div.tab {
    margin-left: 0.5rem;
  }

  .navbar ul li.home-btn div.tab {
    padding-top: 0.5rem;
    padding-left: 1rem;
  }
}
</style>