<template>
  <div class="courses content mb-4 " v-loading="loading">
    <div class="my-4 px-5"><b> Hinweis:</b> Möchtest Du dich erneut für einen Kurs anmelden, der den Status „abgemeldet“
      hat, melde Dich gerne
      per Telefon oder Email bei uns im Büro. Eine erneute Anmeldung über das System ist leider nicht möglich.
    </div>
    <h2 class="fw-bold d-sm-block d-none font-Cavolini text-decoration-underline">Meine Kurse</h2>
    <h3 class="fw-bold d-sm-none d-block font-Cavolini text-decoration-underline">Meine Kurse</h3>
    <Table :data="data_angemeldet"
           :columns="columns_a"
           :showSwitches=true
           @onClick="onClick"
           :detailColumns="detailColumns"
           :pagination="query"
           @fetchData="fetchData_angemeldet"
           @changeQuery="changeQuery"
           :showPrice=false
           :showSizes=true />
  </div>
  <div class="courses content mb-4 " v-loading="loading">
    <h2 class="fw-bold d-sm-block d-none font-Cavolini text-decoration-underline">Abgemeldete Kurse/Warteliste</h2>
    <h3 class="fw-bold d-sm-none d-block font-Cavolini text-decoration-underline">Abgemeldete Kurse/Warteliste</h3>
    <Table_noclick :data="data_abgemeldet"
                   :columns="columns"
                   :showSwitches=true
                   @onClick="onClick"
                   :detailColumns="detailColumns"
                   :pagination="query"
                   @fetchData="fetchData_abgemeldet"
                   @changeQuery="changeQuery"
                   :showPrice=false
                   :showSizes=true />
  </div>
  <div class="dozent content" v-loading="loadingDozent" v-if="this.$store.state.user.dozent_id">
    <h2 class="fw-bold d-sm-block d-none font-Cavolini">Kurse in denen ich Dozent bin</h2>
    <h3 class="fw-bold d-sm-none d-block font-Cavolini">Kurse in denen ich Dozent bin</h3>
    <Table :data="dataDozent"
           :columns="columnsDozent"
           :showSwitches=true
           @onClick="onClick"
           :detailColumns="detailColumns"
           :pagination="queryDozent"
           @fetchData="fetchDataDozent"
           @changeQuery="changeQuery"
           :showPrice=false
           :showSizes=true />
  </div>
</template>
<script>
// Home.vue
import _ from "lodash";
import Table from "@/components/Table.vue";
import Table_noclick from "@/components/Table_noclick.vue";
import Resource from "@/api/seminare";
import {formatDateDMY, formatDateHI} from "@/filters/index";

const seminareResource = new Resource();


export default {
  components: {
    Table,
    Table_noclick,
  },
  data() {
    return {
      data_angemeldet: [],
      data_abgemeldet: [],
      dataDozent: [],
      query:
          {
            page: 1,
            limit: 50,
            total: 0,
            teilnehmer_id: this.$store.state.user.id_person,
            anmeldestatus: 6,
          },
      queryDozent:
          {
            page: 1,
            limit: 50,
            total: 0,
            dozent_id: this.$store.state.user.dozent_id
          },
      columns: [{field: "Color", text: ''},
        {field: "datum", text: 'Datum'},
        {field: "seminar", text: 'Seminar'},
        {field: "dozent", text: 'Dozent'},
        {field: "uhrzeit", text: 'Beginn'},
        {field: "ort", text: 'Ort'},
        {field: "angemeldet", text: "Anmeldestatus"}],
        columns_a: [{field: "Color", text: ''},
        {field: "datum", text: 'Datum'},
        {field: "seminar", text: 'Seminar'},
        {field: "dozent", text: 'Dozent'},
        {field: "uhrzeit", text: 'Beginn'},
        {field: "ort", text: 'Ort'},
        {field: "angemeldet2", text: "Anmeldestatus"}],
      columnsDozent: [{field: "Color", text: ''},
        {field: "datum", text: 'Datum'},
        {field: "seminar", text: 'Seminar'},
        {field: "dozent", text: 'Dozent'},
        {field: "uhrzeit", text: 'Beginn'},
        {field: "ort", text: 'Ort'}],
      detailColumns: [{field: "Color", text: ''},
        {field: "datum", text: 'Datum'},
        {field: "seminar", text: 'Seminar'},
        {field: "dozent", text: 'Dozent'},
        {field: "uhrzeit", text: 'Beginn'},
        {field: "ort", text: 'Ort'},
        {field: "angemeldet", text: "Anmeldestatus"}],
      loading: true,
      loadingDozent: true,
      array_color: {yellow: "#f49a01", green: "#007f49", blue: "#2a3579", red: "#bf2327"},
    };
  },
  computed: {
    username() {
      return this.$route.params.username;
    },
  },
  mounted() {
    this.get_cart();
  },
  created() {
    if (typeof this.$store.state.user.token == 'undefined' || this.$store.state.user.token === '') {
      this.$router.push({name: "login"});
    }
    this.fetchData_angemeldet(this.query);
    this.fetchData_abgemeldet(this.query);
    this.fetchDataDozent(this.queryDozent);
  },
  methods: {

    getCookie(cName) {
      const name = cName + "=";
      const cDecoded = decodeURIComponent(document.cookie); //to be careful
      const cArr = cDecoded.split('; ');
      let res;
      cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
      })
      return res
    },

    get_cart() {
      var hash = this.getCookie('cart_hash');

      var query = {
        hash: hash,
        user_id: this.$store.state.user.id
      }

      seminareResource.getCartData(query).then((res) => {
        if (res.length > 0) {
          for (let i = 0; i < res.length; i++) {
            this.$store.state.user.cart_course.push(res[i]);
             // console.log(this.$store.state.user.cart_course);
          }
        }
       
      });
    },

    changeQuery(limit) {
      this.query.limit = limit;
      this.queryDozent.limit = limit;
    },
    fetchData_angemeldet(query) {
      this.loading = true;
      let offset = (query.page - 1) * query.limit + 1;
      query.offset = offset;
      seminareResource.getSeminarsByUserIdSignIn(query).then((res) => {
        this.query = _.merge(this.query, query);
        this.query.total = _.get(res, 'response.dataInfo.foundCount', 0);
        if (res.response && res.response.data) {
          this.data_angemeldet = res.response.data.map((value) => {
            return {
              'id': value.fieldData.seminar_id,
              'status_color': value.fieldData['seminar_search_filter::color'] ? this.array_color[value.fieldData['seminar_search_filter::color']] : "",
              'datum': formatDateDMY(value.fieldData['SV_Seminare::Datum']),
              'endedatum': formatDateDMY(value.fieldData['SV_Seminare::Endedatum']),
              'seminar': value.fieldData.seminarname,
              'dozent': value.fieldData['SV_Seminare::Seminarleiter'],
              'uhrzeit': formatDateHI(value.fieldData['SV_Seminare::Zeit']),
              'ort': value.fieldData['SV_Seminare::Ort'],
              'dozent_id': "",
              'angemeldet': value.fieldData['F_fest_angemeldet'] === 'n',              
              'angemeldet2': value.fieldData['kuendigungsprozess'] == 1 ? 'Kündigung' : 'angemeldet',
              'teilnehmer_status': value.fieldData['status_teilnehmer'],
              'ds_id': value.fieldData['ds_id'],
              'pic': value.fieldData['felder_web_teilnahme::pfad'],
              'veranstaltungsart': value.fieldData.hybrid_online
            };
          });
        }
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        console.log('er', error);
        const code = _.get(error, 'response.data.messages[0].code', 0);
        if (code == 952) {
          this.$router.push({name: "login"});
        }
      });
    },
    fetchData_abgemeldet(query) {
      this.loading = true;
      let offset = (query.page - 1) * query.limit + 1;
      query.offset = offset;
      seminareResource.getSeminarsByUserIdSignedOff(query).then((res) => {
        this.query = _.merge(this.query, query);
        this.query.total = _.get(res, 'response.dataInfo.foundCount', 0);
        if (res.response && res.response.data) {
          this.data_abgemeldet = res.response.data.map((value) => {
            return {
              'id': value.fieldData.seminar_id,
              'status_color': value.fieldData['seminar_search_filter::color'] ? this.array_color[value.fieldData['seminar_search_filter::color']] : "",
              'datum': formatDateDMY(value.fieldData['SV_Seminare::Datum']),
              'endedatum': formatDateDMY(value.fieldData['SV_Seminare::Endedatum']),
              'seminar': value.fieldData.seminarname,
              'dozent': value.fieldData['SV_Seminare::Seminarleiter'],
              'uhrzeit': formatDateHI(value.fieldData['SV_Seminare::Zeit']),
              'ort': value.fieldData['SV_Seminare::Ort'],
              'dozent_id': "",
              'angemeldet': value.fieldData['F_fest_angemeldet'] === 'n',
              'angemeldet2': value.fieldData['kuendigungsprozess'] == 1 ? 'Kündigung' : 'angemeldet',
              'teilnehmer_status': value.fieldData['status_teilnehmer'],
              'ds_id': value.fieldData['ds_id'],
              'pic': value.fieldData['felder_web_teilnahme::pfad'],
              'veranstaltungsart': value.fieldData.hybrid_online
            };
          });
        }
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        console.log('er', error);
        const code = _.get(error, 'response.data.messages[0].code', 0);
        if (code == 952) {
          this.$router.push({name: "login"});
        }
      });
    },
    fetchDataDozent(query) {
      this.loadingDozent = true;
      let offset = (query.page - 1) * query.limit + 1;
      query.offset = offset;
      if (this.queryDozent.dozent_id) {
        seminareResource.getSeminarsByDozent(query).then((res) => {
          //console.log(res);
          this.queryDozent = _.merge(this.queryDozent, query);
          this.queryDozent.total = _.get(res, 'response.dataInfo.foundCount', 0);
          if (res.response && res.response.data) {
            this.dataDozent = res.response.data.map((value) => {
              return {
                'id': value.fieldData.semi_id,
                'status': value.fieldData['seminar_search_filter::color'] ? this.array_color[value.fieldData['seminar_search_filter::color']] : "",
                'datum': formatDateDMY(value.fieldData['SV_Seminare::Datum']),
                'seminar': value.fieldData.semi_name,
                'dozent': value.fieldData['doz_nachname'] + ' ' + value.fieldData['doz_vorname'],
                'uhrzeit': formatDateHI(value.fieldData['SV_Seminare::Zeit']),
                'ort': value.fieldData['doz_ort'],
                'dozent_id': value.fieldData['doz_id'],
                // 'angemeldet': value.fieldData['F_fest_angemeldet'] == 'n' ? 'Warteliste' : 'angemeldet',
                'ds_id': value.fieldData['ds_id']
              };
            });
          }
          this.loadingDozent = false;
        }).catch((error) => {
          this.loadingDozent = false;
          console.log('er', error);
          const code = _.get(error, 'response.data.messages[0].code', 0);
          if (code == 952) {
            this.$router.push({name: "login"});
          }
        });
      }
    },
    onClick(row) {
      this.$store.dispatch("user/checkCourseDozent", row.dozent_id);
      this.$store.dispatch('seminar/set_name', row.seminar);
      this.$router.push({
        name: "courseDetail",
        params: {
          'id': row.id,
          'dozentId': row.dozent_id,
          'warteliste': row.angemeldet,
          'angemeldet': row.teilnehmer_status,
          'ds_id': row.ds_id
        }
      });
    },
  },
};
</script>