<template>
  <div class="container options-page central-content">
    <div class="header">
      <div class="centered">
        <img class="logo" src="/assets/images/logo.png"/>
      </div>
     
    </div>
    <img
        class="d-block d-sm-none"
        src="/assets/images/login-img.png"
        alt=""
        srcset=""
    />
    <div v-if="!showForm" class="register-form ">
      <div class="register-title pb-3">Registrieren</div>
      <p>{{ message_response }}</p>
      <p style="font-size: 0.9em;">Diese Seite wird nach 5 Sekunden automatisch zur Anmeldeseite weitergeleitet</p>
      <el-button
          @click="returnLogin"
          type="info"
          style="border-radius: 0.8rem;"
          plain
          class="btn  w-50"
          title="Login-Seite zurückgeben"
      >
        Abbrechen
      </el-button>
    </div>
    <div v-else class="register-form" v-loading="loading">
      <div class="register-title">Registrieren</div>
      <el-form class="text-start" :model="userData" :rules="rules" ref="registerForm" label-width="0" @submit.prevent
               @submit="submitFormRegister">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="email">Email*</label>
              <el-form-item prop="email">
                <el-input
                    type="email"
                    id="email"
                    name="email"
                    v-model="userData.email"
                />
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 pb-3">
            <div class="form-group mt-3">
              <label for="gender">Passwort eingeben*</label>
              <el-form-item prop="password">
                <el-input
                    v-model="userData.password"
                    id="password"
                    name="password"
                    show-password
                    max-length="50"
                    autocomplete="new-password"
                />
              </el-form-item>
            </div>
          </div>
          <div class="col-sm-6 pb-3">
            <div class="form-group mt-3">
              <label for="gender">Kennwort bestätigen*</label>
              <el-form-item prop="password_confirm">
                <el-input
                    v-model="userData.password_confirm"
                    show-password
                    max-length="50"
                    id="password_confirm"
                    name="password_confirm"
                />
              </el-form-item>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6 pb-3">
            <div class="form-group">
              <label for="gender">Anrede</label>
              <el-form-item prop="gender">
                <el-select v-model="userData.gender" id="gender" name="gender" class="w-100" placeholder="Bitte auswählen">
                  <el-option value="Frau">Frau</el-option>
                  <el-option value="Herr">Herr</el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="col-sm-6 pb-3">
            <div class="form-group ">
              <label for="title">Titel</label>
              <el-form-item prop="title">
                <el-select v-model="userData.title" id="title" name="title" class="w-100" placeholder="Bitte auswählen">
                  <el-option v-for="(item, index) in option_title" :key="index" :value="item">{{ item }}</el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6 pb-3">
            <div class="form-group">
              <label for="first_name">Vorname*</label>
              <el-form-item prop="first_name">
                <el-input
                    type="text"
                    id="first_name"
                    name="first_name"
                    v-model="userData.first_name"
                />
              </el-form-item>
            </div>
          </div>
          <div class="col-sm-6 pb-3">
            <div class="form-group">
              <label for="last_name">Nachname*</label>
              <el-form-item prop="last_name">
                <el-input
                    type="text"
                    id="last_name"
                    name="last_name"
                    v-model="userData.last_name"
                />
              </el-form-item>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6 pb-3">
            <div class="form-group">
              <label for="road">Strasse + Hausnummer*</label>
              <el-form-item prop="road">
                <el-input
                    type="text"
                    id="road"
                    name="road"
                    v-model="userData.road"
                />
              </el-form-item>
            </div>
          </div>
          <div class="col-sm-6 pb-3">
            <div class="form-group">
              <label for="adresszusatz">Adresszusatz</label>
              <el-form-item prop="adresszusatz">
                <el-input
                    type="text"
                    id="adresszusatz"
                    name="adresszusatz"
                    v-model="userData.adresszusatz"
                />
              </el-form-item>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6 pb-3">
            <div class="row g-3">
              <div class="col form-group">
                <label for="post_code">PLZ*</label>
                <el-form-item prop="post_code">
                  <el-input
                      type="text"
                      id="post_code"
                      name="post_code"
                      v-model="userData.post_code"
                  />
                </el-form-item>
              </div>
              <div class="col form-group">
                <label for="location">Ort*</label>
                <el-form-item prop="location">
                  <el-input
                      type="text"
                      id="location"
                      name="location"
                      v-model="userData.location"
                  />
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="col-sm-6 pb-3">
            <div class="form-group">
              <label for="country">Land*</label>
              <el-form-item prop="country">
                <el-select v-model="userData.country" id="country" name="country" class=" w-100" placeholder="Bitte auswählen">
                  <el-option v-for="(item, index) in option_land" :key="index" :value="item">{{ item }}</el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6 pb-3">
            <div class="row g-3">
              <div class="col form-group">
                <label for="phone">Telefon*</label>
                <el-form-item prop="phone">
                  <el-input
                      type="phone"
                      id="phone"
                      name="phone"
                      v-model="userData.phone"
                  />
                </el-form-item>
              </div>
              <div class="col form-group">
                <label for="funk">Telefon 2</label>
                <el-form-item prop="funk">
                  <el-input
                      type="text"
                      id="funk"
                      name="funk"
                      v-model="userData.funk"
                  />
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="col-sm-6 pb-3">
            <div class="form-group">
              <label for="date_of_birth">Geburtsdatum</label>
              <el-form-item prop="date_of_birth">
                <el-input
                    type="date"
                    id="date_of_birth"
                    name="date_of_birth"
                    placeholder="tt.mm.jjjj"
                    v-model="userData.date_of_birth"
                />
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div>
            <div class="form-check form-group align-items-center mt-3">
              <input type="checkbox" class="form-check-input" id="info_forum" v-model="userData.info_forum"/>
              <label for="info_forum" class="w-100"> Ich möchte per Mail über Forumsbeiträge informiert werden.</label>
            </div>
            <div class="form-check form-group d-flex align-items-center mt-3">
              <input type="checkbox" class="form-check-input" id="eintrag_tn_liste"
                     v-model="userData.eintrag_tn_liste"/>
              <label for="eintrag_tn_liste" class="w-100"> Ich bin damit einverstanden, dass ich mit Name, PLZ, Ort,
                Telefon und Emailadresse in den jeweiligen Kurslisten erscheine.</label>
            </div>
            <div class="form-check form-group d-flex align-items-center mt-3">
              <input type="checkbox" class="form-check-input" id="eintrag_agb"
                     v-model="userData.eintrag_agb"/>
              <label for="eintrag_tn_liste" class="w-100"> Mit der Registrierung erklärst du dich mit den
                <a href="https://hafn.de/agb/" target="_blank">Allgemeinen Geschäftsbedingungen</a> und
                <a href="https://hafn.de/datenschutzerklaerung/" target="_blank">Datenschutzbestimmungen</a>
                einverstanden.</label>
            </div>
          </div>
        </div>

        <div class="d-flex">
          <el-button
              @click="returnLogin"
              type="info"
              style="border-radius: 0.8rem;"
              plain
              class="btn  w-50"
              title="Login-Seite zurückgeben"
          >
            Abbrechen
          </el-button>
          <el-button
              :disabled="!userData.eintrag_agb"
              :loading="loading"
              type="primary"
              @click="submitFormRegister"
              class="btn  btn-lg btn-submit w-50"
          >
            Registrieren
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {sendReCaptcha} from "@/api/auth";
import {registerUser} from "@/api/auth";
import {formatDate} from "@/filters/index";

export default {
  name: "Register",
  props: {
    msg: String,
  },

  data() {
    return {
      userData: {
        email: "",
        gender: "",
        first_name: "",
        last_name: "",
        phone: "",
        funk: "",
        date_of_birth: "",
        title: "",
        info_forum: false,
        eintrag_tn_liste: false,
        eintrag_agb: false,
        road: "",
        adresszusatz: "",
        post_code: "",
        location: "",
        country: "",
        password: "",
        password_confirm: ""
      },
      option_land: ['Deutschland', 'Österreich', 'Schweiz'],
      option_title: [ '','HP', 'Dr.', 'Prof.', 'Dr.-Ing.', 'Dipl.-Psych.', 'Dipl.-Oec.', 'HP Dr. rer. nat.', 'Dr. phil', 'HP-Psych. Mag.'],
      rules: {
        email: [
          {required: true, message: "Bitte E-Mail angeben", trigger: "blur"},
          {
            type: 'email',
            message: 'Deine E-Mail Adresse ist nicht korrekt.',
            trigger: ['blur', 'change']
          }
        ],
        first_name: {
          required: true,
          message: "Bitte Vornamen angeben",
          trigger: "blur",
        },
        last_name: {
          required: true,
          message: "Bitte Nachnamen angeben",
          trigger: "blur",
        },
        phone: {
          required: true,
          message: "Bitte Telefonnummer angeben",
          trigger: "blur",
        },
        date_of_birth: {
          required: false,
          message: "Bitte Geburtsdatum angeben",
          trigger: "blur",
        },
        road: {
          required: true,
          message: "Bitte Straße angeben",
          trigger: "blur",
        },
        location: {
          required: true,
          message: "Bitte Ort angeben",
          trigger: "blur",
        },
        country: {
          required: true,
          message: "Bitte Land angeben",
          trigger: "blur",
        },
        title: {
          required: false,
          message: "Bitte Gegenstand eingeben",
          trigger: "blur",
        },
        post_code: {
          required: true,
          message: "Bitte Postleitzahl angeben",
          trigger: "blur",
        },
        funk: {
          required: false,
          message: "Bitte Gegenstand eingeben",
          trigger: "blur",
        },
        adresszusatz: {
          required: false,
          message: "Bitte Gegenstand eingeben",
          trigger: "blur",
        },
        password: [
          {required: true, message: "Bitte gib Dein Passwort ein.", trigger: "blur"},
          {
            min: 8,
            max: 50,
            message: "Dein Passwwort muss zwischen 8 und 50 Zeichen lang sein.",
            trigger: "blur",
          },
        ],
        password_confirm: [
          {
            required: true,
            message: "Bitte wiederhole Dein Passwort",
            trigger: "blur",
          },
          {
            min: 8,
            max: 50,
            message: "Dein Passwwort muss zwischen 8 und 50 Zeichen lang sein.",
            trigger: "blur",
          },
        ]
      },
      loading: false,
      message_response: "",
      showForm: true
    };
  },
  created() {
    if (this.$store.state.user.token) {
      this.$router.push({name: "dashboard"});
    }
  },
  methods: {
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login')

      // Do stuff with the received token.
      return token;

    },
    submitFormRegister() {
      if (this.loading === false) {
        this.loading = true;
        this.$refs["registerForm"].validate((valid) => {
          if (valid) {
            if (this.userData.password !== this.userData.password_confirm) {
              this.$message({
                type: "error",
                message: "Deine Passwörter müssen übereinstimmen.",
              });
              this.loading = false;
            } else {

              this.recaptcha().then((res) => {
                const data = {
                  token: res
                }

                sendReCaptcha(data).then((res) => {

                  if (res.data.success == true && res.data.score >= 0.5) {

                    let arrayData = {
                      FrauHerr: this.userData.gender,
                      Vorname: this.userData.first_name,
                      Nachname: this.userData.last_name,
                      Telefon: this.userData.phone,
                      GEBDATUM: formatDate(this.userData.date_of_birth),
                      Strasse: this.userData.road,
                      Ort: this.userData.location,
                      PLZ: this.userData.post_code,
                      land: this.userData.country,
                      titel: this.userData.title,
                      info_forum: this.userData.info_forum ? "1" : "0",
                      eintrag_tn_liste: this.userData.eintrag_tn_liste ? "1" : "0",
                      Funk: this.userData.funk,
                      Adresszusatz: this.userData.adresszusatz,
                      passwort: this.userData.password,
                      Email: this.userData.email
                    };
                    if (this.userData.gender) {
                      arrayData = {
                        ...arrayData,
                        FrauHerr: this.userData.gender
                      }
                    }
                    if (this.userData.title) {
                      arrayData = {
                        ...arrayData,
                        titel: this.userData.title
                      }
                    }
                    if (this.userData.funk) {
                      arrayData = {
                        ...arrayData,
                        Funk: this.userData.funk
                      }
                    }
                    if (this.userData.adresszusatz) {
                      arrayData = {
                        ...arrayData,
                        Adresszusatz: this.userData.adresszusatz
                      }
                    }
                    registerUser(arrayData).then((res) => {
                      console.log(res);
                      if (res != null) {
                        if (res.response) {
                          this.message_response = res.response;
                          this.showForm = false;
                          //console.log(res);
                          this.$message({
                            type: "success",
                            message: res.response,
                          });
                          setTimeout(() => this.$router.push({name: "login"}), 5000);

                          this.loading = false;
                        } else {
                          this.$message({
                            type: "error",
                            message: res.error,
                          });
                        }
                      }
                      this.loading = false;

                    }).catch((error) => {
                      let message = _.get(error, 'messages[0].message', error.message);
                      this.$message({
                        type: "error",
                        message: message,
                      });
                      this.loading = false;
                    });
                  }
                })
              })
              this.loading = false;
            }
          } else {
            this.$message({
              type: "error",
              message: 'Angaben unvollständig. Bitte vervollständige Deine Angaben.',
            });
            this.loading = false;
            return false;
          }
        });
      }
    },

    returnLogin() {
      this.$router.push({name: 'login'});
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-group label.label-checkbox {
  padding-left: 0.5em;
  font-size: .9em;
  font-weight: 700;
}

.form-group label {
  font-weight: 700;
}

.central-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  padding: 0;
}

img {
  max-width: 100%;
}

.container > img {
  width: 100%;
}

.header {
  width: 100%;
  background-color: #c4c08e;
}

.header .title {
  padding: 1em 0;
  font-size: 2.5em;
}

.centered img {
  margin-top: 2rem;
}

form {
  margin-top: 2.5rem;
  font-size: 1rem;
}

button.btn-primary {
  margin-top: 2.2rem;
  width: 100%;
}

.register-form {
  margin: 2em auto;
  width: 50rem;
  background: #c4c08e;
  color: #ffffff;
  border-radius: 1.2rem;
  font-family: Arial;
  padding: 2.5rem 2.5rem 2.5rem;
  max-width: 100%;
}

.register-title {
  font: bold 1.6rem "Cavolini";
}

.form-control {
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  color: #464b5c;
  padding: 0.375rem 0.75rem;
}

.form-check {
  padding-left: 2rem;
}

.form-check .form-check-input {
  float: none;
  margin-right: 0.5em;
  font-size: 1.2rem;
}

label {
  margin-top: 0.1rem;
}

.btn-submit {
  background-color: #464B5C;
  border-color: #565B5C;
  cursor: pointer;
  border-radius: 0.8rem;
  font-size: 1rem;
  padding: 0.8rem;
  line-height: 1.5;
  color: #fff;
  transition: all 0.3s;
}

.btn-submit:hover, .btn-submit:focus, .btn-submit:active {
  background-color: #565B5C;
  border-color: #464B5C;
  color: #fff;
  box-shadow: none;
}

@media (min-width: 576px) {
  .header {
    background-color: transparent;
  }
}
</style>

