import Courses from "@/views/courses/Courses.vue";
import CourseDetail from "@/views/courses/CourseDetail.vue";
import CourseQuestion from "@/views/courses/CourseQuestion.vue";
import CourseAppointment from "@/views/courses/CourseAppointment.vue";
import CourseParticipants from "@/views/courses/CourseParticipants.vue";
import CourseMaterial from "@/views/courses/CourseMaterial.vue";
import RegisterCourses from "@/views/courses/RegisterCourses.vue";
import CancelCourse from "@/views/courses/CancelCourse.vue";

import Layout from "@/views/Layout.vue";
// setup routes
export default [
    {
      path: '/:locale/my-courses',
      component: Layout,
      children: [
        {
          path: '',
          name: 'myCourse',
          component: Courses,
        },
      ]
    },
    {
        path: '/:locale/my-courses/:id',
        component: Layout,
        children: [
            {
                path: '',
                name: 'courseDetail',
                component: CourseDetail,
            },
        ]
    },
    {
        path: '/:locale/my-courses/:id/Q-A',
        component: Layout,
        children: [
            {
                path: '',
                name: 'courseQuestion',
                component: CourseQuestion,
            },
        ]
    },
    {
        path: '/:locale/my-courses/:id/appointment',
        component: Layout,
        children: [
            {
                path: '',
                name: 'myCourseAppointment',
                component: CourseAppointment,
            },
        ]
    },
    {
        path: '/:locale/my-courses/:id/participants',
        component: Layout,
        children: [
            {
                path: '',
                name: 'myCourseParticipants',
                component: CourseParticipants,
            },
        ]
    },
    {
        path: '/:locale/my-courses/:id/material',
        component: Layout,
        children: [
            {
                path: '',
                name: 'myCourseMaterial',
                component: CourseMaterial,
            },
        ]
    },
    {
        path: '/:locale/register-course/:online_gruppe_id',
        component: Layout,
        children: [
            {
                path: '',
                name: 'registerCourses',
                component: RegisterCourses,
            },
        ]
    },
    {
        path: '/:locale/cancel-course/:id',
        component: Layout,
        children: [
            {
                path: '',
                name: 'cancelBooking',
                component: CancelCourse,
            },
        ]
    },
];