<template>
  <div v-if="frist_14_tage == true && !check_permission" class="courses content" v-loading="loading">
    <div class="text-center fs-4 px-3">
      Kurs-Material kann erst 14 Tage vor Beginn der Veranstaltung abgerufen werden.
    </div>
  </div>
  <div v-else>
    <div v-if="widerrufverzicht_aktiv == true && !check_permission" class="courses content" v-loading="loading">
      <div class="text-start fs-4 px-3">
        Du hast innerhalb von 14 Tagen vor Kursstart dieses Seminare gebucht. Dir steht ein 14 -tägiges Widerrufsrecht
        zu.
        <br/>
        Innerhalb dieser Zeit kannst Du Deine Buchung ohne Angabe von Gründen jederzeit widerrufen.
        <br/>
        Damit wir Dir Zugang zu den Unterlagen geben können, musst Du vorher den Verzicht auf Dein Widerrufsrecht
        erklären.
      </div>
      <div class="row mt-3 px-3">
        <div class="col-1 text-center py-2">
          <input class="bigger align-middle" type="checkbox" v-model="widerruf_verwurf" id="wideruf_verwurf_checkbox">
        </div>
        <div class="col-11">
          <label class="text-start px-4 px-md-2 fs-5" for="wideruf_verwurf_checkbox">Ich verlange ausdrücklich und
            stimme gleichzeitig zu, dass Sie mit der in
            Auftrag gegebenen Dienstleistung vor Ablauf der Widerrufsfrist beginnen. Ich weiß, dass mein
            <a href="https://hafn.de/widerrufsrecht/" target="_blank">Widerrufsrecht</a>
            damit erlischt.</label>
        </div>
      </div>
      <div class="mt-4">
        <input class="btn btn-secondary btn-lg fs-5" type="button" @click="send_widerruf_verwurf()"
               value="Abfrage abschicken" :disabled="!widerruf_verwurf">
      </div>
    </div>
    <div v-else>
      <div class="courses content" v-loading="loading">
        <div class="fw-bold text-start fs-4">
          {{ this.$store.state.seminar.name }}
        </div>
        <div class="fz-25 fw-bold text-start course__title">
          Download 
        </div>
        <div class="row justify-content-start">
          <div v-if="keine_daten == true">
            Keine Materialien vorhanden.
          </div>
          <div v-else class="col-sm-4 col-md-4 col-lg-3 col-xl-3 col-xxl-2" v-for="(row, index) in data" :key="'row' + index">
            
            <div class="card mx-2">
              <input type="button" v-if="check_permission" class="btn-remove-doc" @click="removeDocuments3(row)"
                     value="x">
              <div class="card-body position-relative">
                <div class="row g-3">
                  <div class="col-4">
                    <div class="file-type">{{ row.file_type }}</div>
                  </div>
                  <div class="col-8 px-0">
                    <div class="file-info text-end">
                      <p class="info-size fw-bold mb-3"> {{ row.dokument_gr }} </p>
                      <span class="info-date"> {{ row.dokument_datum }}</span>
                    </div>
                  </div>
                </div>
                <h1 class="card-title fw-bold text-start mt-4 mb-3 d-sm-none d-block">{{ row.file_name }}</h1>
                <h6 class="card-title fw-bold text-start mt-3 mb-3 d-sm-block d-none">{{ row.file_name }}</h6>
                <div class="position-absolute bottom-0 mx-2 mb-4">
                  <!--                  <a href="http://127.0.0.1:8000/storage/tmp/Dozententeam.png" target="download" class="btn btn-outline-secondary btn-download">DOWNLOAD</a>-->
                  <input type="button" @click="open_file(row)" value="DOWNLOAD"
                         class="btn btn-outline-secondary btn-download">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="courses content mt-3 row" v-if="check_permission">
    <div class="col-md-4 col-lg-3 col-xl-2">
      <div class="card card-upload mx-2" data-bs-toggle="modal" data-bs-target="#uploadDocModal">
        <div class="card-body">
          <div class="icon-add">
            <i class="bi bi-plus-lg"></i>
          </div>
          <a href="#" class="btn btn-outline-secondary btn-download">UPLOAD</a>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="uploadDocModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
       v-loading="loading">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Document hochladen</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-start">
          <form>
            <div class="mb-3">
              <label for="recipientName" class="col-form-label">Name:</label>
              <input type="text" class="form-control" id="recipientName" ref="recipientName">
            </div>
            <div class="mb-3 ">
              <p for="formFile" class="form-label">Datei auswählen:</p>
              <div class="input-group">
                <label class="input-group-text btn btn-custom-upload" for="formFile">Datei auswählen</label>
                <input class="form-control custom-file-input" type="file" id="formFile" ref="formFile" name="formFile"
                       accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf,
                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                .docx, image/jpeg, image/png"
                       style="display:none"
                       @change="onFileChange"
                >
                <p class="mb-0 ps-2 d-flex align-items-center label-custom-upload">
                  {{ files[0] ? files[0].name : 'Keine Datein ausgewählt' }}</p>
              </div>

            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" ref="Close">Abrechen</button>
          <button type="button" class="btn btn-success" :disabled="!check_permission" @click="uploadDocument">
            Upload
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// Home.vue
import _ from "lodash";
import Resource from "@/api/seminare";
import {uploadDocumentS3} from "@/api/auth";
import {deleteObjectS3} from "@/api/auth";
import {formatDateDMY, byteCalc} from "@/filters/index";
// import axios from "axios";

const seminareResource = new Resource();

export default {
  components: {},
  data() {
    return {
      data: [],
      query: {
        seminar_id: 0,
        user_id: 0,
        dozenten_id: 0,
      },
      widerruf_verwurf: false,
      widerrufverzicht_aktiv: true,
      frist_14_tage: true,
      keine_daten: false,
      doz_id: '',
      loading: true,
      files: [],
      check_permission: false
    };
  },
  mounted() {
    this.query.seminar_id = this.$route.params.id;
    this.query.user_id = this.$store.state.user.id_person;
    this.query.dozenten_id = this.$store.state.user.dozent_id;
  },
  created() {
    if (typeof this.$store.state.user.token == 'undefined' || this.$store.state.user.token === '') {
      this.$router.push({name: "login"});
    }
    this.check_permission = this.$store.state.user.dozent_id != '';
    this.query.seminar_id = this.$route.params.id;
    this.query.user_id = this.$store.state.user.id_person;
    this.query.dozenten_id = this.$store.state.user.dozent_id;
    this.fetchData(this.query);
  },
  methods: {

    open_file(input) {
      this.loading = true;
      seminareResource.getFile(input).then((res) => {
        // console.log(res.export_url);
        window.open(res.export_url, '_target');

        // axios({
        //   url: res.export_url,
        //   method: 'GET',
        //   responseType: 'blob',
        // }).then((response) => {
        //
        //   var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        //   var fileLink = document.createElement('a');
        //
        //   fileLink.href = fileURL;
        //   fileLink.setAttribute('download', 'file.pdf');
        //   document.body.appendChild(fileLink);
        //
        //   fileLink.click();
        // });

        this.loading = false;
      });
    },
    send_widerruf_verwurf() {
      this.loading = true;
      seminareResource.sendWiderrufverzicht(this.query).then((res) => {
        if (res.success) {
          this.widerrufverzicht_aktiv = false;
          this.fetchData(this.query);
        }
      })
    },
    fetchData(query) {
      this.loading = true;
      seminareResource.getDocumentsBySeminar(query).then((res) => {
        //console.log(res);
        if (res.status === 'keine_daten') {
          this.keine_daten = true;
        } else if (res.status === 'seminarbeginn_frist') {
          this.frist_14_tage = true;
        } else if (res.status === 'widerruf_aktiv') {
          this.frist_14_tage = false;
          this.widerrufverzicht_aktiv = true;
        } else if (res.status === 'widerruf_inaktiv') {
          this.frist_14_tage = false;
          this.widerrufverzicht_aktiv = false;
          this.data = res.data.response.data.map((value) => {
            return {
              id: value.fieldData['ID_Dokumente'],
              file_name: value.fieldData['file_name'],
              seminar_id: value.fieldData['seminar_id'],
              download: value.fieldData['pfad_download'],
              upload: value.fieldData['SV_Global_dokumente::domain_upload'],
              file_type: value.fieldData['file_name'].split('.').pop().slice(0, 3),
              pfad_s3: value.fieldData['pfad_s3'],
              dokument_gr: byteCalc(value.fieldData['dateigroeße']),
              dokument_datum: formatDateDMY(value.fieldData['erstellt_am']),
            };
          });
        }
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        console.log('er', error);
        const code = _.get(error, 'response.data.messages[0].code', 0);
        if (code == 952) {
          this.$router.push({name: "login"});
        }
      });
    },
    onClick(row) {
      console.log('data', row);
      // this.$router.push({ name: "courseDetail", params: { 'id': row.id} });
    },
    uploadDocument() {
      const formFile = this.$refs.formFile.files[0];
      const recipientName = this.$refs.recipientName.value;
      if (!formFile) {
        this.$message({
          type: "error",
          message: 'No file chosen',
        });
        return;
      }
      // max 20MB
      if (formFile.size > (20 * 1024 * 1024)) {
        this.$message({
          type: "error",
          message: 'File too big (> 4MB)',
        });
        return;
      }

      this.loading = true;
      var formData = new FormData();
      formData.append("document", formFile);
      formData.append("seminar_id", this.$route.params.id);
      uploadDocumentS3(formData).then((res) => {
        if (res != null && res != 'Error') {
          const arrayData = {
            dateigroeße: formFile.size,
            pfad_download: res,
            pfad_s3: res.split('.com').pop(),
            seminar_id: this.$route.params.id,
            name: recipientName ? recipientName : res.split('/').pop(),
            file_name: res.split('/').pop()
          };

          let check_exits = this.data.some(function (item) {
            return item.file_name === arrayData.file_name
          });
          if (!check_exits) {
            seminareResource.addDocumentBySeminar(arrayData).then((res) => {
              if (res != null) {
                let message = _.get(res, 'messages[0].message', '');
                if (message == 'OK') {
                  this.$message({
                    type: "success",
                    message: 'Dokument erfolgreich hochladen!',
                  });
                  this.fetchData(this.query);
                } else {
                  this.$message({
                    type: "error",
                    message: message,
                  });
                }
                this.$refs.Close.click();
              }
            }).catch((error) => {
              let message = _.get(error, 'messages[0].message', error.message);
              this.$message({
                type: "error",
                message: message,
              });
              this.loading = false;
            });
            this.$refs.formFile.value = '';
            this.fetchData(this.query);
          } else {
            this.$refs.Close.click();
            this.loading = false;
            this.$refs.formFile.value = '';
          }
        }
      }).catch((error) => {
        let message = _.get(error, 'messages[0].message', error.message);
        this.$message({
          type: "error",
          message: message,
        });
        this.loading = false;
      });
    },
    removeDocuments3(row) {
      console.log(row);
      this.$confirm(
          "Möchten Sie dieses Dokument wirklich löschen?",
          "Dokument entfernen",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Abbrechen",
            type: "warning",
          })
          .then(() => {
            this.loading = true;
            deleteObjectS3({
              pfad_s3: row.pfad_s3,
              ID_Dokumente: row.id
            }).then((res) => {
              if (res.success == 1) {
                this.$message({
                  type: "success",
                  message: 'Dokument erfolgreich entfernen!',
                });
              }
              this.fetchData(this.query);
            }).catch((error) => {
              let message = _.get(error, 'messages[0].message', error.message);
              this.$message({
                type: "error",
                message: message,
              });
              this.loading = false;
            });
          })
          .catch(() => {
            console.log('canel');
          });
    },
    onFileChange(e) {
      this.files = e.target.files;
    },
  },
};
</script>

<style scoped>

.bigger {
  zoom: 1.5;
  transform: scale(1.5);
  -ms-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -o-transform: scale(1.5);
  -moz-transform: scale(1.5);
  transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
}

.card {
  aspect-ratio: 3 / 4;
}

.file-type {
  background: #464b5c;
  color: #fff;
  border-radius: 0.8rem;
  min-width: 3rem;
  font-size: 1rem;
  padding: 38% 0;
  text-transform: uppercase;
  aspect-ratio: 1 / 1.2;
}

.file-info {
  font-size: 1rem;
}

.btn-download {
  border-color: #808080;
  border-radius: 0.6rem;
  text-align: center;
  font-weight: bold;
  color: #464b5c;
  bottom: 10px;
  width: 100%;
}

.btn-download:hover {
  color: #fff;
}

.card-upload {
  border: 1px #707070 dashed;
  cursor: pointer;
}

.icon-add {
  padding: 7rem 0;
}

.card .card-text {
  min-height: 4rem;
}

.btn-remove-doc {
  position: absolute;
  right: 0%;
  width: 12%;
  height: 7%;
  background: #c4c08e;
  border-top-right-radius: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s;
  visibility: hidden;
  opacity: 0;
  border: solid 0px;
  z-index: 500;
}

.card:hover .btn-remove-doc {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 575px) {
  .card .card-text {
    min-height: 8rem;
    font-size: 1rem;
  }

  .file-type {
    font-size: 2rem;
  }

  .info-size {
    font-size: 1.8rem;
  }

  .info-date {
    font-size: 1.8rem;
  }

  .btn-download {
    font-size: 2.2rem;
  }
}

.btn-custom-upload {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-color: #e9ecef;
  width: 30%;
}

.btn-custom-upload:hover {
  background-color: #ced4da;
}

.label-custom-upload {
  width: 70%;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border: 1px solid #ced4da;
}
</style>
