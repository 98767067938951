import {createWebHistory, createRouter} from "vue-router";

import About from "@/views/About.vue";
import Login from "@/views/Login.vue";
import Layout from "@/views/Layout.vue";
import Dashboard from "@/views/Dashboard.vue";
import StudentPlan from "@/views/StudentPlan.vue";
import NotFound from "@/views/NotFound.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import Credits from "@/views/Credits.vue";
import FurtherCourses from "@/views/courses/FurtherCourses.vue";
import CoursesCart from "@/views/courses/CoursesCart.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import ResetPasswordGuest from "@/views/ResetPasswordGuest.vue";
import Register from "@/views/Register.vue";
import Impressum from "../views/Impressum";
import Datenschutz from "../views/Datenschutz";
import Widerrufsbelehrung from "../views/Widerrufsbelehrung";
import Agb from "../views/Agb";

import course from "./modules/course";

import {setI18nLanguage, loadLocaleMessages, SUPPORT_LOCALES} from '../i18n'


export function setupRouter(i18n) {
    const locale =
        i18n.mode === 'legacy' ? i18n.global.locale : i18n.global.locale.value

    // setup routes
    const routes = [
        {
            path: '/:locale/impressum',
            component: Layout,
            children: [
                {
                    path: '',
                    name: 'impressum',
                    component: Impressum
                },
            ]
        },
        {
            path: '/:locale/datenschutz',
            component: Layout,
            children: [
                {
                    path: '',
                    name: 'datenschutz',
                    component: Datenschutz
                },
            ]
        },
        {
            path: '/:locale/widerrufsbelehrung',
            component: Layout,
            children: [
                {
                    path: '',
                    name: 'widerrufsbelehrung',
                    component: Widerrufsbelehrung
                },
            ]
        },
        {
            path: '/:locale/agb',
            component: Layout,
            children: [
                {
                    path: '',
                    name: 'agb',
                    component: Agb
                },
            ]
        },
        {
            path: '/:locale/',
            name: 'login',
            component: Login
        },
        {
            path: '/:locale/about',
            name: 'about',
            component: About
        },
        {
            path: '/:locale/dashboard',
            component: Layout,
            children: [
                {
                    path: '',
                    name: 'dashboard',
                    component: Dashboard,
                },
            ]
        },
        {
            path: '/:locale/reset-password',
            component: Layout,
            children: [
                {
                    path: '',
                    name: 'resetPassword',
                    component: ResetPassword,
                },
            ]
        },
        ...course,
        {
            path: '/:locale/student-plan',
            component: Layout,
            children: [
                {
                    path: '',
                    name: 'studentPlan',
                    component: StudentPlan,
                },
            ]
        },
        {
            path: '/:locale/credits',
            component: Layout,
            children: [
                {
                    path: '',
                    name: 'credits',
                    component: Credits,
                },
            ]
        },
        {
            path: '/:locale/further-courses',
            component: Layout,
            children: [
                {
                    path: '',
                    name: 'furtherCourses',
                    component: FurtherCourses,
                },
            ]
        },
        {
            path: '/:locale/courses-cart',
            component: Layout,
            children: [
                {
                    path: '',
                    name: 'coursesCart',
                    component: CoursesCart,
                },
            ]
        },

        {
            path: '/:locale/about',
            name: 'about',
            component: About
        },
        {
            path: '/:locale/forgot-password',
            name: 'forgotPassword',
            component: ForgotPassword
        },
        {
            path: '/:locale/reset-password/:token',
            name: 'resetPasswordGuest',
            component: ResetPasswordGuest
        },
        {
            path: '/:locale/register',
            name: 'register',
            component: Register
        },
        {
            path: '/:pathMatch(.*)*',
            redirect: () => `/${locale}`
        },
        {
            path: "/:catchAll(.*)",
            component: NotFound,
        },
    ]

    // create router instance
    const router = createRouter({
        history: createWebHistory(),
        routes
    })

    // navigation guards
    router.beforeEach(async to => {
        const paramsLocale = to.params.locale

        // use locale if paramsLocale is not in SUPPORT_LOCALES
        if (!SUPPORT_LOCALES.includes(paramsLocale)) {
            return `/${locale}`
        }

        // load locale messages
        if (!i18n.global.availableLocales.includes(paramsLocale)) {
            await loadLocaleMessages(i18n, paramsLocale)
        }

        // set i18n language
        setI18nLanguage(i18n, paramsLocale)
    })

    return router
}