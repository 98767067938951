import request from '@/utils/request';

/**
 * eigenerlicher Benutzer-Login
 * @param auth
 * @returns {*}
 */

export function login(auth) {
  return request({
    url: '/get-user-info',
    method : 'post',
    data: auth
  });
}

export function getInfo() {
  return request({
    url: '/user',
    method: 'get',
  });
}

export function logout() {
  return request({
    url: '/auth/logout',
    method: 'post',
  });
}

export function csrf() {
  return request({
    url: '/sanctum/csrf-cookie',
    method: 'get',
  });
}

export function updateUserInfo(id, resource) {
  return request({
    url: '/update-user/'+id,
    method: 'post',
    data: resource,
  });
}

export function uploadImageS3(resource) {
  return request({
    url: '/upload-image-s3',
    method: 'post',
    data: resource,
  });
}

export function deleteObjectS3(resource) {
  return request({
    url: '/delete-object-s3',
    method: 'post',
    data: resource,
  });
}

export function uploadDocumentS3(resource) {
  return request({
    url: '/upload-document-s3',
    method: 'post',
    data: resource,
  });
}

export function forgotPassword(auth) {
  return request({
    url: '/forgot-password',
    method : 'post',
    data: auth
  });
}

export function resetPasswordGuest(data) {
  return request({
    url: '/reset-password-guest',
    method : 'post',
    data: data
  });
}

export function registerUser(data) {
  return request({
    url: '/register-user',
    method : 'post',
    data: data
  });
}

export function sendEmailConfirmBooking(data) {
  return request({
    url: '/send-mail-confirm-booking',
    method : 'post',
    data: data
  });
}

export function sendEmailCancelBooking(data) {
  return request({
    url: '/send-mail-cancel-booking',
    method : 'post',
    data: data
  });
}

export function sendEmailNewPost(data) {
  return request({
    url: '/send-mail-new-post',
    method : 'post',
    data: data
  });
}

export function sendReCaptcha(data) {
  return request({
    url: '/send-recaptcha',
    method : 'post',
    data: data
  });
}