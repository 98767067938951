<template>
  <div class="list-item"  :class="classCustom">
     <div class="row">
       <div class="col-2 col-sm-2 col-md-2 col-lg-1 pe-0  d-flex align-items-center justify-content-center">
        <img class="avatar" @error="imageLoadOnError" :src="'https://diegrueneschule.obs.eu-de.otc.t-systems.com/user/'+item.user.id_person+'/profilepicture.jpeg'" alt="">
       </div>
       <div class="col-10 col-sm-10 col-md-10 col-lg-11 ps-0">
        <div class="content-question">
          <el-row class="space-between title">
            <el-col v-if="hasSubcomment==true" :span="24" :sm="{span: 16}" :md="{span: 18}" :lg="{span: 20}" class="text-start fw-bold" :key="'message-sub-'+item.id+'-'+item.subject">{{ item.subject }}</el-col>
            <el-col v-else :span="24" :sm="{span: 16}" :md="{span: 18}" :lg="{span: 20}" class="text-start" :key="'message-'+item.id+'-'+item.subject">{{ item.message }}</el-col>
            <el-col :span="24" :sm="{span: 8}" :md="{span: 6}" :lg="{span: 4}" class="text-end" v-if="rightHeaders" :offset="0">
              <a v-if="hasSubcomment"
                class="item-space-5 fw-bold message"
                @click="onClick('messages_count')"
              >
                <i
                  class="el-icon-chat-dot-square"
                  v-text="item.messages && item.messages.length"
                ></i>
              </a>
              <a :class="item.has_voted?'item-space-5 fw-bold highlight' : 'item-space-5 fw-bold upvote'" @click="onClick('upvote')">
                <i class="el-icon-star-on" v-text="item.upvote"></i>
              </a>
              <a v-if="item.user.username == $store.state.user.username" :class="'item-space-5 fw-bold edit'" @click="onClick('edit')">
                <i class="el-icon-edit-outline"></i>
              </a>
              <a v-if="$store.state.user.dozent_id!='' && $store.state.user.dozent_id == $store.state.user.course_dozent_select" :class="'item-space-5 fw-bold delete'" @click="onClick('delete')">
                <i class="el-icon-close"></i>
              </a>
            </el-col>
          </el-row>
          <div class="detail">
            <div v-if="hasSubcomment">
              {{ item.message }}
            </div>
            <el-row class="item-space-5 italic text-secondary">
              <span>{{ item.user.username }}</span> &nbsp;
              <span>{{ item.created_at }}</span>
            </el-row>
          </div>
        </div>
       </div>
     </div>
  </div>
</template>

<script>
export default {
  name: "ListItem",
  components: {},
  props: {
    item: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      required: -1,
    },
    rightHeaders: {
      type: Boolean,
      default: false,
    },
    hasSubcomment: {
      type: Boolean,
      default: false,
    },
    action: {
      type: Function,
      default: null,
    },
    classCustom: {
      type: String,
      default: "",
    },
  },
  methods: {
    onClick(column) {
      this.$emit("action", column, this.index, this.item.id);
    },
    imageLoadOnError(e) {
          e.target.src = "/person-circle.svg";
          e.target.style.opacity= 0.5;
      }
  },
};
</script>
<style lang="postcss" scoped>
.space-between {
  justify-content: space-between;
}
.item-space-5 > * {
  /* margin-top: 0.5rem; */
  margin-right: 1rem;
  transition: all 0.5s;
}
.italic {
  font-size: 0.9em;
  font-style: italic;
}
.list-item {
  margin-bottom: 1rem;
}
.list-item .title {
  padding: 0.5rem;
  /* padding-bottom: 0; */
  border-bottom: 1px solid #dddddd;
}
.list-item .detail {
  padding: 0.5rem;
  text-align: left;
}
a {
  cursor: pointer;
}
a.highlight {
  color: #f49b2d;
}

img.avatar{
  width: 3rem;
  /* float: left; */
  border-radius: 50%;
}
/* @media (min-width: 576px) {
  
} */
@media (min-width: 768px) {
  img.avatar{
    width: 4rem;
    height: 4rem;
  }
}
@media (min-width: 992px) {
  img.avatar{
    width: 3.5rem;
    height: 3.5rem;
  }
}

.message:hover {
  color:#0d6efd
}
.upvote:hover {
  color:#f49b2d
}
.edit:hover {
  color:#198754
}
.delete:hover {
  color:#dc3545
}
.content-question{ 
  padding: 1rem;
  transition: all 0.3s;
}
.bggrey {
  transition: all 0.3s;
  border-radius: 1rem;
  background-color: #eeeeee;
}
</style>