<template>
  <div class="font-Cavolini mb-3">
    <h2>Impressum</h2>
  </div>
  <div class="text-start mx-auto">
    <b>Gesetzliche Anbieterkennung:</b> <br/>
    Florian Wittpahl <br/>
    Die Grüne Schule <br/>
    Emilienstr. 19 <br/>
    20259 Hamburg <br/>
    Deutschland <br/>
    Telefon: 040 493207 <br/>
    E-Mail: info@hafn.de <br/> <br/>

    <b>Gesetzliche Berufsbezeichnung:</b> Heilpraktiker (verliehen in Deutschland) <br/> <br/>

    <b>zuständige Aufsichtsbehörde/Kammer:</b>
    Behörde für Gesundheit und Verbraucherschutz <br/>
    Strasse, Hausnummer: Billstraße 80 <br/>
    Postleitzahl: 20539 <br/>
    Stadt: Hamburg <br/>
    Telefon: 040-42837-0 <br/>
    Telefax: 040-4273-10092 <br/> <br/>

    <b>berufsrechtliche Regelungen:</b> Berufsordnung für Heilpraktiker <br/>
    <b>Link zu den berufsrechtlichen Regelungen:</b> <a href="https://bdh-online.de/178.0.html" target="_blank">www.bdh-online.de/178.0.html</a>
    <br/><br/>

    <b> Berufsverbandsmitgliedschaft in: Bund Deutscher Heilpraktiker (BDH) e.V.</b> <br/>

    <b>Alternative Streitbeilegung:</b> <br/>
    Die Europäische Kommission stellt eine Plattform für die außergerichtliche Online-Streitbeilegung (OS-Plattform)
    bereit, aufrufbar unter <a href="https://ec.europa.eu/odr" target="_blank">https://ec.europa.eu/odr</a>. <br/> <br/>

    Wir sind nicht bereit, an Streitbeilegungsverfahren vor Verbraucherschlichtungsstellen teilzunehmen. <br/> <br/>

    <b>Wir sind seit 28.07.2016 Mitglied der Initiative „FairCommerce“.</b> <br/>
    Nähere Informationen hierzu finden Sie unter <a href="https://fair-commerce.de" target="_blank">www.fair-commerce.de</a>.
    <br/> <br/>

    <span class="textdoc">Ergänzende Angaben:</span> <br/> <br/>

    <b>Rechtsgrundlagen:</b>
    Gesetz über die berufsmäßige Ausübung der Heilkunde ohne Bestallung (Heilpraktikergesetz, BGBl. III 2122-2)
    Durchführungsverordnung zum Heilpraktikergesetz (BGBl. III 2122-2-1) <br/>
    Berufsordnung für Heilpraktiker <br/>
    <a href="http://www.udh-bundesverband.de/files/bohmitanhangauszughwguwg.pdf" target="_blank">http://www.udh-bundesverband.de/files/bohmitanhangauszughwguwg.pdf</a>
    <br/> <br/>

    <b>Bildquellen</b> <br/> <br/>

    Neben der Verwendung eigener Bildvorlagen nehmen wir auch andere Bilderdienste in Anspruch, von denen hier aufgrund
    der Lizenzbedingungen www.fotolia.com zu nennen ist. <br/> <br/>

    © Andres Rodriguez – Fotolia.com <br/> <br/>

    <b>Technische Umsetzung und Pflege: </b> <br/> <br/>

    <b>Suchmaschinenoptimierung und Webdesign SottMedia</b> <br/> <br/>

    Wir bedanken uns an dieser Stelle bei den Entwicklern und Weiterentwicklern von WordPress. <br/> <br/>

    <b>Beschwerdeverfahren</b> <br/> <br/>

    <b>Alternative Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO und § 36 VSBG:</b> <br/> <br/>

    Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die du unter
    https://ec.europa.eu/consumers/odr findest. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer
    Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.
  </div>
  <div class="mt-4">
    <input class="btn btn-success btn-lg w-25 fw-bold py-1" type="button" @click="goBack()" value="ZURÜCK">
  </div>
</template>

<script>
export default {
  name: "Impressum",
  methods:{
    goBack(){
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>
.textdoc {
  text-decoration-line: underline;
  font-weight: bold;
}

</style>