<template>
  <div class="font-Cavolini mb-3">
    <h2>Datenschutz</h2>
  </div>
  <div class="text-start">
    Soweit nachstehend keine anderen Angaben gemacht werden, ist die Bereitstellung Ihrer personenbezogenen Daten weder
    gesetzlich oder vertraglich vorgeschrieben, noch für einen Vertragsabschluss erforderlich. Sie sind zur
    Bereitstellung der Daten nicht verpflichtet. Eine Nichtbereitstellung hat keine Folgen. Dies gilt nur soweit bei den
    nachfolgenden Verarbeitungsvorgängen keine anderweitige Angabe gemacht wird. <br/> <br/>

    „Personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche
    Person beziehen. <br/> <br/>

    <b>Server-Logfiles</b> <br/> <br/>

    Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. <br/> <br/>

    Bei jedem Zugriff auf unsere Website werden an uns oder unseren Webhoster / IT-Dienstleister Nutzungsdaten durch
    Ihren Internet Browser übermittelt und in Protokolldaten (sog. Server-Logfiles) gespeichert. Zu diesen gespeicherten
    Daten gehören z.B. der Name der aufgerufenen Seite, Datum und Uhrzeit des Abrufs, die IP-Adresse, die übertragene
    Datenmenge und der anfragende Provider. Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus
    unserem überwiegenden berechtigten Interesse an der Gewährleistung eines störungsfreien Betriebs unserer Website
    sowie zur Verbesserung unseres Angebotes. <br/> <br/>

    <span class="font-Cavolini fw-bold fs-4">Kontakt</span> <br/> <br/> <br/>


    <b>Verantwortlicher</b> <br/>
    Kontaktieren Sie uns auf Wunsch. Die Kontaktdaten des für die Datenverarbeitung Verantwortlichen finden Sie in
    unserem Impressum. <br/> <br/>

    <b>Initiativ-Kontaktaufnahme per E-Mail</b> <br/> <br/>

    Wenn Sie per E-Mail initiativ mit uns in Geschäftskontakt treten, erheben wir Ihre personenbezogenen Daten (Name,
    E-Mail-Adresse, Nachrichtentext) nur in dem von Ihnen zur Verfügung gestellten Umfang. Die Datenverarbeitung dient
    der Bearbeitung und Beantwortung Ihrer Kontaktanfrage. <br/> <br/>

    Wenn die Kontaktaufnahme der Durchführung vorvertraglichen Maßnahmen (bspw. Beratung bei Kaufinteresse,
    Angebotserstellung) dient oder einen bereits zwischen Ihnen und uns geschlossenen Vertrag betrifft, erfolgt diese
    Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. <br/> <br/>

    Erfolgt die Kontaktaufnahme aus anderen Gründen erfolgt diese Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit.
    f DSGVO aus unserem überwiegenden berechtigten Interesse an der Bearbeitung und Beantwortung Ihrer Anfrage. <i><b>In
    diesem Fall haben Sie das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf
    Art. 6 Abs. 1 lit. f DSGVO beruhenden Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.</b></i>
    <br/> <br/>

    Ihre E-Mail-Adresse nutzen wir nur zur Bearbeitung Ihrer Anfrage. Ihre Daten werden anschließend unter Beachtung
    gesetzlicher Aufbewahrungsfristen gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt
    haben. <br/> <br/>

    Erhebung und Verarbeitung bei Nutzung des Kontaktformulars <br/> <br/>

    Bei der Nutzung des Kontaktformulars erheben wir Ihre personenbezogenen Daten (Name, E-Mail-Adresse,
    Nachrichtentext) nur in dem von Ihnen zur Verfügung gestellten Umfang. Die Datenverarbeitung dient dem Zweck der
    Kontaktaufnahme. <br/> <br/>

    Wenn die Kontaktaufnahme der Durchführung vorvertraglichen Maßnahmen (bspw. Beratung bei Kaufinteresse,
    Angebotserstellung) dient oder einen bereits zwischen Ihnen und uns geschlossenen Vertrag betrifft, erfolgt diese
    Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Erfolgt die Kontaktaufnahme aus anderen Gründen
    erfolgt diese Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten
    Interesse an der Bearbeitung und Beantwortung Ihrer Anfrage. In diesem Fall haben Sie das Recht, aus Gründen, die
    sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 Abs. 1 lit. f DSGVO beruhenden
    Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen. Ihre E-Mail-Adresse nutzen wir nur zur
    Bearbeitung Ihrer Anfrage. Ihre Daten werden anschließend unter Beachtung gesetzlicher Aufbewahrungsfristen
    gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben. <br/> <br/>

    <b>Erhebung und Verarbeitung bei Nutzung des Kontaktformulars</b> <br/> <br/>

    Bei der Nutzung des Kontaktformulars erheben wir Ihre personenbezogenen Daten (Name, E-Mail-Adresse,
    Nachrichtentext) nur in dem von Ihnen zur Verfügung gestellten Umfang. Die Datenverarbeitung dient dem Zweck der
    Kontaktaufnahme. <br/>
    Wenn die Kontaktaufnahme der Durchführung vorvertraglichen Maßnahmen (bspw. Beratung bei Kaufinteresse,
    Angebotserstellung) dient oder einen bereits zwischen Ihnen und uns geschlossenen Vertrag betrifft, erfolgt diese
    Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. <br/> <br/>

    Erfolgt die Kontaktaufnahme aus anderen Gründen erfolgt diese Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit.
    f DSGVO aus unserem überwiegenden berechtigten Interesse an der Bearbeitung und Beantwortung Ihrer Anfrage. <i><b>In
    diesem Fall haben Sie das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf
    Art. 6 Abs. 1 lit. f DSGVO beruhenden Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.</b></i>
    Ihre E-Mail-Adresse nutzen wir nur zur Bearbeitung Ihrer Anfrage. Ihre Daten werden anschließend unter Beachtung
    gesetzlicher Aufbewahrungsfristen gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt
    haben. <br/> <br/>

    <span class="font-Cavolini fw-bold fs-4">Kundenkonto / Bestellungen</span> <br/> <br/> <br/>


    <b>Kundenkonto</b> <br/> <br/>

    Bei der Eröffnung eines Kundenkontos erheben wir Ihre personenbezogenen Daten in dem dort angegebenen Umfang. Die
    Datenverarbeitung dient dem Zweck, Ihr Einkaufserlebnis zu verbessern und die Bestellabwicklung zu vereinfachen. Die
    Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO mit Ihrer Einwilligung. Sie können Ihre
    Einwilligung jederzeit durch Mitteilung an uns widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der
    Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird. Ihr Kundenkonto wird anschließend gelöscht. <br/>
    <br/>

    <b>Erhebung, Verarbeitung und Weitergabe personenbezogener Daten bei Bestellungen</b> <br/> <br/>

    Bei der Bestellung erheben und verarbeiten wir Ihre personenbezogenen Daten nur, soweit dies zur Erfüllung und
    Abwicklung Ihrer Bestellung sowie zur Bearbeitung Ihrer Anfragen erforderlich ist. Die Bereitstellung der Daten ist
    für den Vertragsschluss erforderlich. Eine Nichtbereitstellung hat zur Folge, dass kein Vertrag geschlossen werden
    kann. Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO und ist für die Erfüllung eines Vertrags
    mit Ihnen erforderlich. <br/> <br/>

    Eine Weitergabe Ihrer Daten erfolgt dabei beispielsweise an die von Ihnen gewählten Versandunternehmen und
    Dropshipping Anbieter, Zahlungsdienstleister, Diensteanbieter für die Bestellabwicklung und IT-Dienstleister. In
    allen Fällen beachten wir strikt die gesetzlichen Vorgaben. Der Umfang der Datenübermittlung beschränkt sich auf ein
    Mindestmaß. <br/> <br/>

    <span class="font-Cavolini fw-bold fs-4">Werbung</span> <br/> <br/> <br/>


    <b>Verwendung der E-Mail-Adresse für die Zusendung von Newslettern</b> <br/> <br/>

    Wir nutzen Ihre E-Mail-Adresse unabhängig von der Vertragsabwicklung ausschließlich für eigene Werbezwecke zum
    Newsletterversand, sofern Sie dem ausdrücklich zugestimmt haben. Die Verarbeitung erfolgt auf Grundlage des Art. 6
    Abs. 1 lit. a DSGVO mit Ihrer Einwilligung. Sie können die Einwilligung jederzeit widerrufen, ohne dass die
    Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird. Sie können dazu
    den Newsletter jederzeit unter Nutzung des entsprechenden Links im Newsletter oder durch Mitteilung an uns
    abbestellen. Ihre E-Mail-Adresse wird danach aus dem Verteiler entfernt. <br/> <br/>

    Ihre Daten werden dabei an einen Dienstleister für E-Mail-Marketing im Rahmen einer Auftragsverarbeitung
    weitergegeben. Eine Weitergabe an sonstige Dritte erfolgt nicht. <br/> <br/>

    <b>Verwendung der E-Mail-Adresse für die Zusendung von Direktwerbung</b> <br/> <br/>

    Wir nutzen Ihre E-Mail-Adresse, die wir im Rahmen des Verkaufes einer Ware oder Dienstleistung erhalten haben, für
    die elektronische Übersendung von Werbung für eigene Waren oder Dienstleistungen, die denen ähnlich sind, die Sie
    bereits bei uns erworben haben, soweit Sie dieser Verwendung nicht widersprochen haben. Die Bereitstellung der
    E-Mail-Adresse ist für den Vertragsschluss erforderlich. Eine Nichtbereitstellung hat zur Folge, dass kein Vertrag
    geschlossen werden kann. Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem
    überwiegenden berechtigten Interesse an Direktwerbung. Sie können dieser Verwendung Ihrer E-Mail-Adresse jederzeit
    durch Mitteilung an uns widersprechen. Die Kontaktdaten für die Ausübung des Widerspruchs finden Sie im Impressum.
    Sie können auch den dafür vorgesehenen Link in der Werbe-E-Mail nutzen. Hierfür entstehen keine anderen als die
    Übermittlungskosten nach den Basistarifen. <br/> <br/>

    <span class="font-Cavolini fw-bold fs-4">Cookies</span> <br/> <br/> <br/>


    Unsere Website verwendet Cookies. Cookies sind kleine Textdateien, die im Internetbrowser bzw. vom Internetbrowser
    auf dem Computersystem eines Nutzers gespeichert werden. Ruft ein Nutzer eine Website auf, so kann ein Cookie auf
    dem Betriebssystem des Nutzers gespeichert werden. Dieser Cookie enthält eine charakteristische Zeichenfolge, die
    eine eindeutige Identifizierung des Browsers beim erneuten Aufrufen der Website ermöglicht. <br/> <br/>

    Cookies werden auf Ihrem Rechner gespeichert. Daher haben Sie die volle Kontrolle über die Verwendung von Cookies.
    Durch die Auswahl entsprechender technischer Einstellungen in Ihrem Internetbrowser können Sie vor dem Setzen von
    Cookies benachrichtigt werden und über die Annahme einzeln entscheiden sowie die Speicherung der Cookies und
    Übermittlung der enthaltenen Daten verhindern. Bereits gespeicherte Cookies können jederzeit gelöscht werden. Wir
    weisen Sie jedoch darauf hin, dass Sie dann gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich
    werden nutzen können. <br/> <br/>

    Unter den nachstehenden Links können Sie sich informieren, wie Sie die Cookies bei den wichtigsten Browsern
    verwalten (u.a. auch deaktivieren) können: <br/>
    <a href="https://support.google.com/accounts/answer/61416?hl=de" target="_blank">Chrome</a> <br/>
    <a href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies"
       target="_blank">Internet Explorer</a> <br/>
    <a href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen" target="_blank">Mozilla Firefox</a> <br/>
    <a href="https://support.apple.com/de-de/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
       target="_blank"> Safari</a> <br/> <br/>

    <b>Technisch notwendige Cookies</b> <br/> <br/>

    Soweit nachstehend in der Datenschutzerklärung keine anderen Angaben gemacht werden setzen wir nur diese technisch
    notwendigen Cookies zu dem Zweck ein, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Des
    Weiteren ermöglichen Cookies unseren Systemen, Ihren Browser auch nach einem Seitenwechsel zu erkennen und Ihnen
    Services anzubieten. Einige Funktionen unserer Internetseite können ohne den Einsatz von Cookies nicht angeboten
    werden. Für diese ist es erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt wird. <br/>
    <br/>

    Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt auf Grundlage des § 25 Abs. 2 TTDSG. Die
    Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem
    überwiegenden berechtigten Interesse an der Gewährleistung der optimalen Funktionalität der Website sowie einer
    nutzerfreundlichen und effektiven Gestaltung unseres Angebots. <br/>
    <i><b>Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser
      Verarbeitungen
      Sie betreffender personenbezogener Daten zu widersprechen.</b></i> <br/> <br/>

    <b>Verwendung von Borlabs Cookie</b> <br/> <br/>

    Wir verwenden auf unserer Website das Consent-Management-Tool Borlabs Cookie des Anbieters Borlabs – Benjamin A.
    Bornschein (Georg-Wilhelm-Str. 17, 21107 Hamburg; „Borlabs“). <br/> <br/>

    Das Tool ermöglicht es Ihnen, Einwilligungen in Datenverarbeitungen über die Website, insbesondere das Setzen von
    Cookies, zu erteilen sowie von Ihrem Widerrufsrecht für bereits erteilte Einwilligungen Gebrauch zu machen. <br/>
    <br/>

    Die Datenverarbeitung dient dem Zweck, erforderliche Einwilligungen in Datenverarbeitungen einzuholen sowie zu
    dokumentieren und damit gesetzliche Verpflichtungen einzuhalten. <br/> <br/>

    Hierzu können Cookies eingesetzt werden. Dabei können u. a. folgende Informationen erhoben werden: Datum und Uhrzeit
    des Seitenaufrufs, Informationen zu dem von Ihnen verwendeten Browser und das von Ihnen genutzte Gerät, UID
    (zufällig vergebene, anonyme ID), Opt-in- und Opt-out-Daten. Eine Weitergabe dieser Daten an Dritte erfolgt nicht.
    <br/> <br/>

    Die Datenverarbeitung erfolgt zur Erfüllung einer rechtlichen Verpflichtung auf Grundlage des Art. 6 Abs. 1 lit. c
    DSGVO. <br/> <br/>

    Nähere Informationen zum Datenschutz bei Borlabs finden Sie unter:
    <a href="https://de.borlabs.io/borlabs-cookie/" target="_blank">https://de.borlabs.io/borlabs-cookie/ </a> <br/>
    <br/>

    <span class="font-Cavolini fw-bold fs-4">Analyse / Kommunikation</span> <br/> <br/> <br/>


    <b>Verwendung von Google Analytics</b> <br/> <br/>

    Wir verwenden auf unserer Website den Webanalysedienst Google Analytics der Google Google Ireland Limited (Gordon
    House, Barrow Street, Dublin 4, Irland; „Google“). <br/> <br/>

    Die Datenverarbeitung dient dem Zweck der Analyse dieser Website und ihrer Besucher sowie für Marketing- und
    Werbezwecke. Dazu wird Google im Auftrag des Betreibers dieser Website die gewonnenen Informationen benutzen, um
    Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere, mit
    der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen.
    Dabei können u.a. folgende Informationen erhoben werden: IP-Adresse, Datum und Uhrzeit des Seitenaufrufs, Klickpfad,
    Informationen über den von Ihnen verwendeten Browser und das von Ihnen verwendete Device (Gerät), besuchte Seiten,
    Referrer-URL (Webseite, über die Sie unsere Webseite aufgerufen haben), Standortdaten, Kaufaktivitäten. Die im
    Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google
    zusammengeführt. <br/> <br/>

    Google Analytics verwendet Technologien wie Cookies, Webspeicher im Browser und Zählpixel, die eine Analyse der
    Benutzung der Website durch Sie ermöglichen. Die dadurch erzeugten Informationen über Ihre Benutzung dieser Website
    werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Für die USA ist kein
    Angemessenheitsbeschluss der EU-Kommission vorhanden. Die Datenübermittlung erfolgt u.a auf Grundlage von
    Standardvertragsklauseln als geeignete Garantien für den Schutz der personenbezogenen Daten, einsehbar unter:
    <a href="https://policies.google.com/privacy/frameworks" target="_blank">https://policies.google.com/privacy/frameworks</a>
    und <a href="https://business.safety.google/adsprocessorterms/" target="_blank">https://business.safety.google/adsprocessorterms/</a>
    . Sowohl Google
    als auch staatliche US-Behörden haben Zugriff auf Ihre Daten. Ihre Daten können von Google mit anderen Daten, wie
    beispielsweise Ihrem Suchverlauf, Ihren persönlichen Accounts, Ihren Nutzungsdaten anderer Geräte und allen anderen
    Daten, die Google zu Ihnen vorliegen hat, verknüpft werden. <br/> <br/>

    Auf dieser Website ist die IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von
    Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen
    Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA
    übertragen und dort gekürzt. <br/> <br/>

    Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt mit Ihrer Einwilligung auf Grundlage des § 25 Abs.
    1 S. 1 TTDSG i.V.m. Art. 6 Abs. 1 lit. a DSGVO. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt mit Ihrer
    Einwilligung auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO. Sie können die Einwilligung jederzeit widerrufen, ohne
    dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.
    Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter
    <a href="https://www.google.com/analytics/terms/de.html" target="_blank">https://www.google.com/analytics/terms/de.html</a>
    bzw. unter
    <a href="https://www.google.de/intl/de/policies/" target="_blank">https://www.google.de/intl/de/policies/</a>
    sowie unter
    <a href="https://policies.google.com/technologies/cookies?hl=de" target="_blank">https://policies.google.com/technologies/cookies?hl=de</a>.
    <br/> <br/>

    <b>Nutzung von Zoom</b> <br/> <br/>

    Wir nutzen zur Durchführung unserer Online-Seminare/-Kurse (nachfolgend: Online-Seminare) im Rahmen eines
    Auftragsverarbeitungsvertrages das Videokonferenz-Tool Zoom der Zoom Video Communications, Inc. (55 Almaden
    Boulevard, San Jose, CA 95113, USA; “Zoom”). <br/> <br/>

    Die Teilnahme an unseren Online-Seminaren kann über die Zoom-App (nach Download auf der Webseite des Anbieters) oder
    über die Browser-Version auf der Webseite des Anbieters erfolgen. Für die Teilnahme an unseren Online-Seminaren ist
    es also erforderlich, zumindest einmalig die Webseite des Anbieters Zoom zu besuchen. Für die dort stattfindende
    Verarbeitung Ihrer Daten ist Zoom verantwortlich. <br/> <br/>

    Für die im Zusammenhang mit der Durchführung unserer Online-Seminare stattfindende Verarbeitung Ihrer
    personenbezogenen Daten sind wir verantwortlich. <br/> <br/>

    Diese Datenverarbeitung dient dem Zweck, unser Online-Seminar durchzuführen sowie vor- und nachzubereiten.
    Zu diesem Zweck erheben und verarbeiten wir folgende Ihrer personenbezogenen Daten: Name, E-Mail-Adresse, Passwort
    (soweit kein Single-Sign-On verwendet wurde), IP-Adresse, Informationen zum verwendeten Gerät, Datum/Uhrzeit des
    Online-Seminars sowie bei Telefoneinwahl die ein- und ausgehende Rufnummer und das Land. Die Bereitstellung dieser
    Daten ist für die Erfüllung des zwischen Ihnen und uns geschlossenen Vertrages über die Erbringung des
    Online-Seminars erforderlich. Die Nichtbereitstellung dieser Daten hat zur Folge, dass Sie nicht an unserem
    Online-Seminar teilnehmen können. <br/> <br/>

    Darüber hinaus erheben und verarbeiten wir von Ihnen während des Online-Seminars gemachte Texteingaben sowie Video-
    und Audioübertragungen Ihres Geräts. Sie können Ihre Kamera und Ihr Mikrofon vor dem Betreten des Online-Seminars
    und jederzeit während des Online-Seminars deaktivieren. Hierüber findet dann keine Datenerhebung statt. Falls Sie
    weder über die Chat-, Kamera- oder Audiofunktion Daten bereitstellen wollen, ist keine aktive Teilnahme am
    Online-Seminar oder Kommunikation mit anderen Teilnehmern möglich. <br/> <br/>

    Soweit wir unsere Online-Seminare zu Nachbereitungszwecken, insbesondere zum nachträglichen Bereitstellen für die
    jeweiligen Teilnehmer, aufzeichnen, werden Sie hierüber gesondert vor Beginn des Online-Seminars informiert. Sie
    erkennen eine Aufnahme zudem an der Kennzeichnung während des Online-Seminars. Bei einer Aufzeichnung werden Ihr
    Name, ggf. gemachte Texteingaben im Chat sowie Video-/Audioübertragungen Ihres Geräts gespeichert und ggf. im
    Nachgang an die jeweiligen Teilnehmer des Online-Seminars übermittelt. <br/> <br/>

    Die bei der Durchführung unserer Online-Seminare erhobenen Daten werden im Rahmen des Auftragsverarbeitungsvertrages
    an Zoom und damit in die USA übermittelt. Für die USA ist kein Angemessenheitsbeschluss der EU-Kommission vorhanden.
    Die Datenübermittlung erfolgt u.a auf Grundlage von Standardvertragsklauseln als geeignete Garantien für den Schutz
    der personenbezogenen Daten, einsehbar unter:
    <a href="https://zoom.us/docs/doc/Zoom_GLOBAL_DPA.pdf"
       target="_blank">https://zoom.us/docs/doc/Zoom_GLOBAL_DPA.pdf</a>
    Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO und ist für die Erfüllung eines Vertrags mit
    Ihnen erforderlich. <br/>
    Nähere Informationen zur Datenverarbeitung bei Nutzung von Zoom finden Sie unter:
    <a href="https://zoom.us/de-de/privacy.html" target="_blank">https://zoom.us/de-de/privacy.html</a> <br/> <br/>

    <span class="font-Cavolini fw-bold fs-4">Plug-ins und Sonstiges</span> <br/> <br/> <br/>


    <b>Verwendung von GoogleMaps</b> <br/> <br/>

    Wir verwenden auf unserer Website die Funktion zur Einbettung von GoogleMaps-Karten der Google Ireland Limited
    (Gordon House, Barrow Street, Dublin 4, Irland, „Google“). <br/> <br/>

    Die Funktion ermöglicht die visuelle Darstellung von geographischen Informationen und interaktiven Landkarten. Dabei
    werden von Google bei Aufrufen der Seiten, in die GoogleMaps-Karten eingebunden sind, auch Daten der Besucher der
    Websites erhoben, verarbeitet und genutzt. <br/> <br/>

    Ihre Daten werden dabei gegebenenfalls auch in die USA übermittelt. Für die USA ist kein Angemessenheitsbeschluss
    der EU-Kommission vorhanden. Die Datenübermittlung erfolgt u.a auf Grundlage von Standardvertragsklauseln als
    geeignete Garantien für den Schutz der personenbezogenen Daten, einsehbar unter:
    <a href="https://policies.google.com/privacy/frameworks" target="_blank">https://policies.google.com/privacy/frameworks</a>
    . <br/> <br/>

    Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt mit Ihrer Einwilligung auf Grundlage des § 25 Abs.
    1 S. 1 TTDSG i.V.m. Art. 6 Abs. 1 lit. a DSGVO. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt mit Ihrer
    Einwilligung auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO. Sie können die Einwilligung jederzeit widerrufen, ohne
    dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird. <br/>
    <br/>

    Nähere Informationen zur Erhebung und Nutzung der Daten durch Google finden Sie in den Datenschutzhinweisen von
    Google unter
    <a href="https://www.google.com/privacypolicy.html" target="_blank">https://www.google.com/privacypolicy.html</a>.
    Dort haben Sie auch im Datenschutzcenter die Möglichkeit
    Ihre Einstellungen zu verändern, so dass Sie Ihre von Google verarbeiteten Daten verwalten und schützen können.
    <br/> <br/>

    <b>Verwendung von YouTube</b> <br/> <br/>

    Wir verwenden auf unserer Website die Funktion zur Einbettung von YouTube-Videos der Google Ireland Limited (Gordon
    House, Barrow Street, Dublin 4, Irland; „YouTube“).YouTube ist ein mit der Google LLC (1600 Amphitheatre Parkway,
    Mountain View, CA 94043, USA; “Google”) verbundenes Unternehmen. <br/> <br/>

    Die Funktion zeigt bei YouTube hinterlegte Videos in einem iFrame auf der Website an. Dabei ist die Option
    „Erweiterter Datenschutzmodus“ aktiviert. Dadurch werden von YouTube keine Informationen über die Besucher der
    Website gespeichert. Erst wenn Sie sich ein Video ansehen, werden Informationen darüber an YouTube übermittelt und
    dort gespeichert. Ihre Daten werden gegebenenfalls in die USA übermittelt. Für die USA ist kein
    Angemessenheitsbeschluss der EU-Kommission vorhanden. Die Datenübermittlung erfolgt u.a auf Grundlage von
    Standardvertragsklauseln als geeignete Garantien für den Schutz der personenbezogenen Daten, einsehbar unter:
    <a href="https://policies.google.com/privacy/frameworks" target="_blank">https://policies.google.com/privacy/frameworks</a>
    . <br/> <br/>

    Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt mit Ihrer Einwilligung auf Grundlage des § 25 Abs.
    1 S. 1 TTDSG i.V.m. Art. 6 Abs. 1 lit. a DSGVO. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt mit Ihrer
    Einwilligung auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO. Sie können die Einwilligung jederzeit widerrufen, ohne
    dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird. <br/>
    <br/>

    Nähere Informationen zur Erhebung und Nutzung der Daten durch YouTube und Google, über Ihre diesbezüglichen Rechte
    und Möglichkeiten zum Schutz Ihrer Privatsphäre finden Sie in den Datenschutzhinweisen von YouTube unter
    <a href="https://www.youtube.com/t/privacy" target="_blank">https://www.youtube.com/t/privacy</a>
    . <br/> <br/>

    <span class="font-Cavolini fw-bold fs-4">Betroffenenrechte und Speicherdauer</span> <br/> <br/> <br/>


    <b>Dauer der Speicherung</b> <br/> <br/>

    Nach vollständiger Vertragsabwicklung werden die Daten zunächst für die Dauer der Gewährleistungsfrist, danach unter
    Berücksichtigung gesetzlicher, insbesondere steuer- und handelsrechtlicher Aufbewahrungsfristen gespeichert und dann
    nach Fristablauf gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben. <br/>
    <br/>

    <b>Rechte der betroffenen Person</b> <br/> <br/>

    Ihnen stehen bei Vorliegen der gesetzlichen Voraussetzungen folgende Rechte nach Art. 15 bis 20 DSGVO zu: Recht auf
    Auskunft, auf Berichtigung, auf Löschung, auf Einschränkung der Verarbeitung, auf Datenübertragbarkeit. <br/> <br/>

    Außerdem steht Ihnen nach Art. 21 Abs. 1 DSGVO ein Widerspruchsrecht gegen die Verarbeitungen zu, die auf Art. 6
    Abs. 1 f DSGVO beruhen, sowie gegen die Verarbeitung zum Zwecke von Direktwerbung. <br/> <br/>

    <b>Beschwerderecht bei der Aufsichtsbehörde</b> <br/> <br/>

    Sie haben gemäß Art. 77 DSGVO das Recht, sich bei der Aufsichtsbehörde zu beschweren, wenn Sie der Ansicht sind,
    dass die Verarbeitung Ihrer personenbezogenen Daten nicht rechtmäßig erfolgt. <br/> <br/>

    <b>Widerspruchsrecht</b> <br/> <br/>

    Beruhen die hier aufgeführten personenbezogenen Datenverarbeitungen auf Grundlage unseres berechtigten Interesses
    nach Art. 6 Abs. 1 lit. f DSGVO, haben Sie das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben,
    jederzeit diesen Verarbeitungen mit Wirkung für die Zukunft zu widersprechen. <br/> <br/>

    Nach erfolgtem Widerspruch wird die Verarbeitung der betroffenen Daten beendet, es sei denn, wir können zwingende
    schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihren Interessen, Rechten und Freiheiten überwiegen, oder
    wenn die Verarbeitung der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient. <br/> <br/>

    Erfolgt die personenbezogene Datenverarbeitung zu Zwecken der Direktwerbung, können Sie dieser Verarbeitung
    jederzeit durch Mitteilung an uns widersprechen. Nach erfolgtem Widerspruch beenden wir die Verarbeitung der
    betroffenen Daten zum Zwecke der Direktwerbung. <br/> <br/>

    <span class="text-decoration-underline">letzte Aktualisierung: 10.01.2022</span>
  </div>
  <div class="mt-4">
    <input class="btn btn-success btn-lg w-25 fw-bold py-1" type="button" @click="goBack()" value="ZURÜCK">
  </div>
</template>

<script>
export default {
  name: "Datenschutz",
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>

</style>