<template>
  <div class="data-table">
    <div v-if="showSwitches" class="text-right table-bar flex-row">
      <SvgIcon
          :name="listView ? 'list_active' : 'list'"
          @click="changeView(true)"
          size="1.8rem"
      />
      <SvgIcon
          :name="listView ? 'grid' : 'grid_active'"
          @click="changeView(false)"
          size="1.8rem"
      />
    </div>
    <table v-if="listView" class="table table-stripe" :id="id">
      <tr class="table-head">
        <th
            v-for="(column, index) in columns"
            :key="'column' + index"
            v-text="column.field == 'status' ? '' : column.text"
            :style="column.field == 'status' ? 'width:1.5rem' : ''"
        />
      </tr>
      <template v-for="(row, index) in data" :key="'row' + index">
        <tr>
          <td
              v-for="(column, ind) in columns"
              :class="column.field === 'seminar' ? 'fw-bold pointer link' : ''"
              :key="ind + 'data' + index"
              @click="onClick(row, column, index)"
          >
            <span
                v-if="column.field === 'Color'"
                class="status-box"
                :style="'background-color:' + row.status_color"
            />
            <span
                v-else-if="column.field === 'removeCart'"
                class="status-box"
            >
              <i :style="'color:rgb(191, 35, 39)'" class="bi bi-x-lg pointer" @click="removeCart(row.removeCart)"></i>
            </span>
            <span v-else-if="column.field === 'angemeldet'">
              <span v-if="details_course === true">
                <span v-if="row.warteliste == 'n'">
                  Warteliste
                </span>
                <span v-else>
                  <span v-if="row.angemeldet == 6">
                    angemeldet
                  </span>
                  <span v-else>
                    <b>abgemeldet</b>
                  </span>
                </span>
              </span>
              <span v-else>
                <span v-if="row.angemeldet == 'n'">
                  Warteliste
                </span>
                <span v-else>
                <span v-if="row.teilnehmer_status == 6">
                  angemeldet
                </span>
                <span v-else>
                  <b>abgemeldet</b>
                </span>
              </span>
              </span>
            </span>
            <span v-else-if="(column.field === 'gebuehr' || column.field === 'rate') && row[column.field]">
              {{ row[column.field] }} €
            </span>
            <span v-else-if="column.field == 'check_payment_credits'">
             <select v-model="row['payment_value']" class="form-select form-select-sm">
                <option value="invoice">Rechnung</option>
                <option value="ratenzahlung">Ratenzahlung</option>
                <option value="credits" :disabled="row[column.field] == 'invoice'">Credits</option>
              </select>
              <!--              <CustomSwitch-->
              <!--                v-if="row[column.field] == 'credits'"-->
              <!--                v-model="row['payment_value']"-->
              <!--                inline-prompt-->
              <!--                active-color="#13ce66"-->
              <!--                inactive-color="#ff4949"-->
              <!--                active-text="Credits"-->
              <!--                inactive-text="Rechnung"-->
              <!--                active-value="credits"-->
              <!--                inactive-value="invoice"-->
              <!--                class="custom-switch"-->
              <!--                width="85"-->
              <!--                :id="'data_switch_' + index"-->
              <!--              />-->
              <!--              <CustomSwitch-->
              <!--                v-else-->
              <!--                v-model="row['payment_value']"-->
              <!--                inline-prompt-->
              <!--                active-color="#13ce66"-->
              <!--                inactive-color="#ff4949"-->
              <!--                active-text="Credits"-->
              <!--                inactive-text="Rechnung"-->
              <!--                active-value="credits"-->
              <!--                inactive-value="invoice"-->
              <!--                class="custom-switch"-->
              <!--                width="85"-->
              <!--                disabled-->
              <!--                :id="'data_switch_' + index"-->
              <!--              />-->
            </span>
            <span v-else-if="column.field == 'online'">
              <select v-if="row[column.field] == 'Hybrid'" v-model="row['online_check_value']"
                      class="form-select form-select-sm">
                <option value="1">Hybrid</option>
                <option value="2">Online</option>
              </select>
              <span v-else>{{ row[column.field] }}</span>
            </span>
            <span
                v-else-if="column.field == 'register_check'"
                class="cart-box"
            >
              <i v-if="row[column.field] == 'unregistered'" class="bi bi-cart-plus-fill pointer"
                 title="in den Warenkorb legen" @click="addtoCart(row)"></i>
              <i v-else class="bi bi-cart-plus-fill disabled-custom" title="Sie haben sich schon angemeldet"></i>
            </span>
            <span v-else>{{ row[column.field] }}</span>
          </td>
        </tr>
        <tr
            :style="'background-color:' + row.status"
            :class="
            (index == selectedIndex
              ? 'show detail-row show detail-'
              : 'detail-row detail-') + row.id.toString()
          "
        >
          <td colspan="6">
            <div>
              <p
                  v-for="(column, ind) in detailColumns"
                  :key="ind + 'detail' + index"
              >
                <span v-if="column.field == 'seminar'">
                  <strong>{{ row[column.field] }}</strong>
                </span>
                <span v-else>
                  <strong>{{ column.text }}</strong> 
                  {{ row[column.field] }}
                </span>
              </p>
              <div class="close-btn">
                <CloseIcon @onClick="unSelected"/>
              </div>
            </div>
          </td>
        </tr>
      </template>
      <tr v-if="!data.length" class="text-center">
        <td colspan="100%">
          <span>keine Daten zum Anzeigen</span>
        </td>
      </tr>
    </table>
    <!--
    Anfang Kachelansicht
    -->
    <div v-else class="row justify-content-center">
        <CustomCard
            v-for="(row, index) in data"
            :key="'row' + index"
            :data="row"
        />
    </div>
    <el-config-provider :locale="locale">
      <div v-if="pagination!=null" class="block pt-3">
        <el-pagination v-if="showSizes"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       v-model:currentPage="currentPage"
                       :page-sizes="[10, 20, 50, 100]"
                       :page-size="pagination.limit"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="pagination.total"/>
        <el-pagination v-else
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       v-model:currentPage="currentPage"
                       :page-sizes="[10, 20, 50, 100]"
                       :page-size="pagination.limit"
                       layout="total, prev, pager, next, jumper"
                       :total="pagination.total"/>
      </div>
    </el-config-provider>
  </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon.vue";
import CloseIcon from "@/components/CloseIcon.vue";
import CustomCard from "@/components/CustomCard.vue";
import {defineComponent} from 'vue';
// import CustomSwitch from "@/components/CustomSwitch.vue";
import deLang from 'element-plus/lib/locale/lang/de';

export default defineComponent({
  name: "Table",
  components: {
    SvgIcon,
    CloseIcon,
    // CustomSwitch
    CustomCard
  },
  setup() {
    return {
      locale: deLang,
    }
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    abgemeldet: {
      type:Boolean,
      required: false,
    },
    detailColumns: {
      type: Array,
      required: true,
    },
    showSwitches: {
      type: Boolean,
      default: true,
    },
    fetchData: {
      type: Function,
      default: null,
    },
    changeQuery: {
      type: Function,
      default: null,
    },
    pagination: {
      type: Object,
      default: () => {
        return null;
      },
    },
    showPrice: {
      type: Boolean,
      default: true,
    },
    showSizes: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: '',
    },
    details_course: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      listView: true,
      selectedIndex: -1,
      currentPage: 1,
    };
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('changeQuery', val);
      this.$emit('fetchData', {...this.pagination, limit: val});
    },
    handleCurrentChange(val) {
      this.$emit('fetchData', {...this.pagination, page: val});
    },
    unSelected() {
      this.selectedIndex = -1;
    },
    onClick(row, column) {
      // if (window.innerWidth > 575) {
        if (column.field == "seminar") {
          this.$emit("onClick", row);
        }
      // } else {
      //   this.selectedIndex = index;
      //   console.log("selected Index: ", this.selectedIndex);
      // }
    },
    emitEvent(row) {
      this.$emit("onClick", row);
    },
    changeView(view = false) {
      this.listView = view;
    },
    removeCart(id) {
      this.$emit("onRemove", id);
    },
    addtoCart(row) {
      this.$emit("addCourseToCart", row);
    }
  },
});
</script>

<style scoped>

.table > :not(caption) > * > * {
  padding: 0rem 1.3rem 0rem 0rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.close-btn {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.detail-row td > div * {
  display: none;
}

.detail-row td > div {
  width: 100%;
  height: 0;
  max-height: 0;
  padding: 0;
  transition: max-height 1s linear;
}

.detail-row td {
  display: table-cell;
}

.table tr:nth-child(2n + 1) td {
  padding: 0;
}

tr td:nth-child(n + 3), tr th:nth-child(n + 3) {
  display: none;
}

.detail-row.show {
  display: table-row;
}

.detail-row.show td > div {
  height: auto;
  max-height: 1000px;
  position: relative;
  color: #000;
  padding: 1.5rem;
  transition: max-height 2s linear;
}

.detail-row.show td > div * {
  display: block;
}

.status-box {
  padding: 0.1rem 0.9rem;
  border-radius: 0.4rem;
}
.link {
  text-decoration-line: underline;
}

.link:hover {
  text-decoration-line: none;
  color: dimgray;
}

.svg-icon {
  vertical-align: -0.125em;
  line-height: 0;
  display: inline-block;
}

.table {
  text-align: left;
  margin-top: 0.5rem;
}

.table-bar {
  justify-content: flex-end;
}

.table-bar i {
  height: 2.5rem;
  margin: 0.25rem;
  fill: #aaaaaa;
}

.table-bar img.selected {
  fill: #000000;
}

.table tr {
  position: relative;
  vertical-align: top;
}

.table tr td {
  padding: 0.5rem 0.5rem 0.5rem 0;
  line-height: 1.8rem;
  font-size: 1.3rem;
}

.table tr.table-head th {
  font-weight: bold;
  font-size: 1.2rem;
  text-transform: capitalize;
}

.table tr:nth-child(4n -2) td {
  background-color: #fdf7db;
}

.table tr:nth-child(4n -2) td:first-child {
  border-radius: 0.5rem 0 0 0.5rem;
}

.table tr:nth-child(4n -2) td:last-child {
  border-radius: 0 0.5rem 0.5rem 0;
}

.cart-box i {
  color: #464B5C;
  transition: all 0.3s;
}

.cart-box i:hover {
  color: #1c1f23;
}

.disabled-custom {
  color: #a1a1a1 !important;
  border-color: #a1a1a1 !important;
  cursor: not-allowed;
}

@keyframes slideInFromTop {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

img {
  /* This section calls the slideInFromLeft animation we defined above */
  animation: 1.5s ease-out 0s 1 slideInFromTop;
}

@media (max-width: 575px) {
  tr td:first-child, tr th:first-child {
    display: table-cell;
  }

  tr td:nth-child(2), tr th:nth-child(2) {
    display: table-cell;
  }

  tr td:nth-child(3), tr th:nth-child(3) {
    display: table-cell;
  }
}

@media (min-width: 576px) {
  tr td:nth-child(n + 3), tr tH:nth-child(n + 3) {
    display: table-cell;
  }

  .table tr.table-head th {
    padding-right: 0.5rem;
  }
}

@media (min-width: 768px) {
  .table tr td, .table tr th {
    font-size: 1.2rem;
  }
}

@media (min-width: 992px) {
  .table tr.table-head th {
    font-size: 0.9rem;
  }

  .table tr td {
    font-size: 1.1rem;
  }

  #register_course tr td {
    font-size: 1rem !important;
  }
}
</style>
