<template>
  <!--  Dozentenansicht-->
  <div v-if="dozenten_card == true" class="col-auto card-doz mx-3 px-0 mt-2">
    <div v-if="doz_data.profilbild != ''">
      <img :src="doz_data.profilbild" alt="profilbild Dozent" class="w-100 h-50 round-corners">
    </div>
    <div v-else>
      <img :src="require('@/assets/images/no_dozent.png')" alt="profilbild Dozent" class="w-100 h-50 round-corners">
    </div>
    <div class="text-start mt-4 mt-md-3 mt-lg-0 px-5 px-lg-4 py-5 py-md-3">
      <h3 class="fw-bold mb-0">{{ doz_data.v_name }} {{ doz_data.n_name }}</h3>
    </div>
    <div v-html="doz_data.beschreibung" class="px-5 my-2 my-xl-0 fs-5 text-start px-lg-4">
    </div>
  </div>
  <!--  Detail Ansicht-->
  <div v-else-if="detail_ansicht == true" class="col-auto card-sem mx-3 px-0 mt-2 position-relative">
    <div v-if="data.image != ''">
      <img :src="data.image" alt="Seminar-Detail-picture Kachel" class="w-100 h-50 round-corners">
    </div>
    <div v-else>
      <img :src="require('@/assets/images/no_seminar.png')" alt="Seminar-Detail-picture Kachel" class="w-100 h-50 round-corners">
    </div>
    <div class="text-start px-3 mt-3">
      <h5 class="fw-bold">{{ data.seminar }}</h5>
    </div>
    <div class="text-start px-3 mt-4 mt-md-3 mt-lg-0">
      {{ data.seminarleiter }}
    </div>
  </div>
  <!--  Seminaransicht-->
  <div v-else class="col-auto card-sem mx-3 px-0 mt-2 position-relative">
    <div v-if="data.pic != ''">
      <img :src="data.pic" alt="Seminar-picture Kachel" class="w-100 h-50 round-corners">
    </div>
    <div v-else>
      <img :src="require('@/assets/images/no_seminar.png')" alt="Seminar-Detail-picture Kachel" class="w-100 h-50 round-corners">
    </div>
    <div class="corner-datum-box" :style="'background-color:' + data.status_color">
      <div class="text-center fs-5 white-text-color mt-2 mt-lg-1">
        {{ convertmonth(data.datum) }}
      </div>
      <div class="text-center font-size fw-bold white-text-color">
        {{ convertday(data.datum) }}
      </div>
    </div>
    <div class="text-start px-3 py-2 mt-2">
      {{ data.datum }} bis {{ data.endedatum }}
    </div>
    <div class="text-start px-3 mt-3 mt-lg-0">
      <h5 class="fw-bold">{{ data.seminar }}</h5>
    </div>
    <div class="text-start px-3 mt-4 mt-md-3 mt-lg-0" v-if="data.veranstaltungsart == 1">
      Hybrid-Veranstaltung
    </div>
    <div class="text-start px-3 mt-4 mt-md-3 mt-lg-0" v-else>
      Online-veranstaltung
    </div>
  </div>

</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "CustomCard",
  props: {
    dozenten_card: {
      type: Boolean,
      required: false,
      default: false,
    },
    detail_ansicht: {
      type: Boolean,
      required: false,
      default: false,
    },
    doz_data: Object,
    data: Object
  },
  methods: {
    convertmonth(date) {
      const month_name = ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'];
      var month = date.split('.');
      return month_name [month[1] - 1];
    },
    convertday(date) {
      const day = date.split('.')
      return day [0]
    }
  }
});
</script>

<style scoped>

.card-doz, .card-sem {
  width: 280px;
  height: 375px;
  border-radius: 1.5rem;
  background-color: #FFFFFF;
}

.card-doz {
  border: solid 0;
}

.card-sem {
  border: solid 0.5px
}

.round-corners {
  border-radius: 1.5rem 1.5rem 0 0;
}

.corner-datum-box {
  width: 85px;
  height: 85px;
  border-radius: 1.5rem 0 0 0;
  position: absolute;
  left: 0;
  top: 0;
}

.white-text-color {
  color: #FFFFFF;
}

.font-size {
  font-size: 35px;
}
</style>
