<template>
  <div>
    <Header />
    <Navbar
      :selectedIndex="selectedIndex"
      :tabs="tabs"
      :showHomeButton="showHomeButton"
      :disabled="disabled"
    />
    <div class="container-fluid container-sm container-custom d-flex flex-column min-vh-100 mb-4">
      <div :class="showHomeButton ? 'background-linear' : 'background'">
        <div :class="showHomeButton ? '' : 'content' + cntclass">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <AGBFooter />
  </div>
</template>


<script>
import Header from "./components/Header.vue";
import Navbar from "./components/Navbar.vue";
import AGBFooter from "./components/AGBFooter";

export default {
  components: { Header, Navbar, AGBFooter },
  name: "layout",
  created (){
    if (typeof this.$store.state.user.token == 'undefined' || this.$store.state.user.token  === '') {
      this.$router.push({ name: 'login' });
    }
  },
  data() {
    return {
      cntclass: "",
      tabs: [
        { route: { name: "myCourse" }, text: "Meine Kurse" },
        { route: { name: "studentPlan" }, text: "Stundenplan" },
        { route: { name: "dashboard" }, text: "Persönliche Daten" },
        { route: { name: "credits" }, text: "Credits" },
        { route: { name: "furtherCourses" }, text: "Weitere Kurse buchen" },
      ],
      selectedIndex: 2,
      showHomeButton: false,
      disabled: ""
    };
  },
  beforeMount(){
    this.setting();
  },
  beforeUpdate() {
    if(this.$store.state.user.course_dozent_select){
      this.disabled = 'disabled';
    }else{
       this.disabled = '';
    }
    this.setting();
  },
  methods:{
    setting(){
      const name = this.$router.currentRoute.value.name;
      this.showHomeButton = false;
      this.cntclass = "";
      this.tabs = [
        { route: { name: "myCourse" }, text: "Meine Kurse" },
        { route: { name: "studentPlan" }, text: "Stundenplan" },
        { route: { name: "dashboard" }, text: "Persönliche Daten" },
        { route: { name: "credits" }, text: "Credits" },
        { route: { name: "furtherCourses" }, text: "Weitere Kurse buchen" },
      ];
      const tabs_layer = [
          { route: { name: "courseDetail" }, text: "Kurs" },
          { route: { name: "myCourseAppointment" }, text: "Stundenplan" },
          { route: { name: "myCourseMaterial" }, text: "Skript" },
          { route: { name: "myCourseParticipants" }, text: "Teilnehmerliste" },
          { route: { name: "cancelBooking" }, text: "Abmeldung" },
        ];
      switch (name) {
        case "myCourse":
          this.selectedIndex = 0;
          this.cntclass = "br-right";
          break;
        case "courseDetail":
          this.selectedIndex = 0;
          this.showHomeButton = true;
          this.tabs = tabs_layer;
          this.cntclass = "br-right";
          break;
        case "dashboard":
        case "resetPassword":
          this.selectedIndex = 2;
          break;
        case "studentPlan":
          this.selectedIndex = 1;
          this.cntclass = "br-right";
          break;
        case "myCourseAppointment":
          this.selectedIndex = 1;
          this.showHomeButton = true;
          this.tabs = tabs_layer;
          this.cntclass = "br-right";
          break;
        case "myCourseParticipants":
          this.selectedIndex = 3;
          this.showHomeButton = true;
          this.tabs = tabs_layer;
          this.cntclass = "br-right";
          break;
        case "credits":
          this.selectedIndex = 3;
          this.cntclass = "br-right";
          break;
        case "myCourseMaterial":
          this.selectedIndex = 2;
          this.showHomeButton = true;
          this.tabs = tabs_layer;
          this.cntclass = "br-right";
          break;
        case "furtherCourses":
        case "registerCourses":
          this.selectedIndex = 4;
          this.cntclass = "br-right";
          break;
        case "coursesCart":
          this.selectedIndex = -1;
          this.cntclass = "br-right";
          break;
        case "cancelBooking":
          this.selectedIndex = 4;
          this.showHomeButton = true;
          this.tabs = tabs_layer;
          break;
        default:
          this.selectedIndex = 4;
      }
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .background-linear {
  background-image: linear-gradient(to right, #e8e8e8, #c4c08e);
  border-radius: 0 0 1rem 1rem;
} */
.background {
  /* background-color: #e8e8e8; */
  border-radius: 0 0 1rem 1rem;
}
.content {
  border-radius: 1rem;
  background: #ffffff;
  padding: 2rem;
  transition: all 0.3s;
}
/* .br-right{
  border-radius: 0 1rem 0 0;
} */
.br-left{
  border-radius: 1rem 0 0 0;
}

@media (max-width: 575px){
  
  .container-fluid{
    padding:0;
  }
  .content {
    border-radius: 0;
  }
  .br-right, .br-left{
    border-radius: 0;
  }

}

@media (min-width: 576px) {

}

@media (min-width: 1500px){
  .container-custom { 
    max-width: 90vw;
  }
}
</style>

