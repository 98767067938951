<template>
  <div class="container options-page central-content">
    <div class="header">
      <div class="centered">
        <img class="logo" src="/assets/images/logo.png" />
      </div>
      
    </div>
    <img
      class="d-block d-sm-none"
      src="/assets/images/login-img.png"
      alt=""
      srcset=""
    />
    <div class="login text-center">
      <div class="login__title">Passwort vergessen?</div>
      <div class="pt-3" style="text-align: justify;"><p class="mb-2">Bitte gib Deine E-Mail-Adresse ein, wenn Du Dein Passwort vergessen hast und drücke den Button "Abschicken".</p>
      <p>Dein neues Passwort kannst Du später in Deinen Persönlichen Daten ändern.</p>
      </div>
      <el-form :model="obj" :rules="rules" ref="forgotpasswordForm" label-width="0" @submit.prevent @submit="submitFormSendMail">
        <el-form-item prop="username">
          <el-input
            v-model="obj.username"
            placeholder="example@gmail.com"
          ></el-input>
        </el-form-item>
        <div class="d-flex">
          <el-button
            :loading="loading"
            @click="returnLogin"
            type="info"
            style="border-radius: 0.8rem;"
            plain
            class="btn  w-50"
            title="Login-Seite zurückgeben"
          >
            Abbrechen
          </el-button>
          <el-button
            :loading="loading"
            type="primary"
            @click="submitFormSendMail"
            class="btn  btn-lg btn-submit w-50"
          >
            Abschicken
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { forgotPassword } from "@/api/auth";
export default {
  name: "ForgotPassword",
  props: {
    msg: String,
  },
  data() {
    return {
      obj: {
        username: "",
      },
      rules: {
        username: [
          { required: true, message: "Bitte Benutzername eingeben", trigger: "blur" },
          { type: 'email', message: 'Bitte geben Sie die richtige Art von E-Mail-Adresse ein', trigger: ['blur', 'change'] }
        ],
      },
      loading: false,
    };
  },
  created() {
   if (this.$store.state.user.token) {
      this.$router.push({ name: "dashboard" });
    }
  },
  methods: {
    submitFormSendMail() {
      if(this.loading == false){
      this.loading = true;
      this.$refs["forgotpasswordForm"].validate((valid) => {
        if (valid) {
          forgotPassword(this.obj).then((res) => {
            if(res != null){
              if(res.response){
                this.$message({
                  type: "success",
                  message: res.response,
                });
              }else{
                this.$message({
                  type: "error",
                  message: res.error,
                });
              }
            }
          this.loading = false;
          }).catch((error)=>{
            let message = _.get(error, 'messages[0].message', error.message);
            this.$message({
              type: "error",
              message: message,
            });
            this.loading = false;
          });
        } else {
          this.$message({
            type: "error",
            message: 'Fehler abschicken!!',
          });
          this.loading = false;
          return false;
        }
      });
      }
    },
    returnLogin() {
      this.$router.push({ name: 'login' });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.central-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  padding: 0;
}

img {
  max-width: 100%;
}
.container > img {
  width: 100%;
}
.header {
  width: 100%;
  background-color: #c4c08e;
}
.header .title {
  padding: 1em 0;
  font-size: 2.5em;
}
.centered img {
  margin-top: 2rem;
}
form {
  margin-top: 2.5rem;
  font-size: 1rem;
}
button.btn-primary {
  margin-top: 2.2rem;
  width: 100%;
}
.login {
  margin: 2em auto;
  width: 30rem;
  background: #c4c08e;
  color: #ffffff;
  border-radius: 1.2rem;
  font-family: Arial;
  padding: 2.5rem 2.5rem 2.5rem;
  max-width: 100%;
}
.login__title {
  font: bold 1.6rem "Cavolini";
}
.form-control {
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.8rem;
  margin: 1.2rem 0;
}
.form-check .form-check-input {
  float: none;
  margin-left: 0.5em;
  font-size: 1.2rem;
}
label {
  margin-top: 0.1rem;
}
.btn-submit { 
  background-color: #464B5C;
  border-color: #565B5C;
  cursor: pointer;
  border-radius: 0.8rem;
  font-size: 1rem;
  padding: 0.8rem;
  line-height: 1.5;
  color:#fff;
  transition: all 0.3s;
}
.btn-submit:hover, .btn-submit:focus, .btn-submit:active {
  background-color: #565B5C;
  border-color: #464B5C;
  color:#fff;
  box-shadow: none;
}
@media (min-width: 576px) {
  .header {
    background-color: transparent;
  }
}
</style>

