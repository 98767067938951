<template>

  <!--  Desktop Ansicht -->

  <div class="header">
    <div class="container container-custom">
      <div class="row justify-content-center">
        <div class="profile-list d-none d-lg-flex col-md-4">
          <img
              v-if="this.$store.state.user.avatar && this.$store.state.user.avatar != 'https://diegrueneschule.obs.eu-de.otc.t-systems.com'"
              class="logo pe-auto" :src="this.$store.state.user.avatar" @click="goHome()"/>
          <SvgIcon v-else :name="'person'" class="custom-svg" @click="goHome()" size="5rem"/>
          <ul class="text-start">

            <li class="fz-25">Willkommen</li>
            <li class="fw-bold">{{ this.$store.state.user.first_name + ' ' + this.$store.state.user.last_name }}</li>
            <li class="fw-white small" v-if="this.$store.state.user.dozent_id == ''">Schüler</li>
            <li class="fw-white small" v-else>Dozent</li>
          </ul>
        </div>
        <div class="centered col-md-5">
          <img class="logo" src="/hafn-logo2021.png" @click="goHome()"/>
        </div>
        <div class="right-btns d-none d-lg-flex col-md-3">
          <ul class="flex-row flex-bottom align-items-end">
            <li>
              <SvgIcon :name="'Kommentar_grey_white'" size="2.2rem"/>
            </li>
            <li class="pointer">
              <SvgIcon :name="'LogOut_grey_white'" @click="logOut()" size="2.5rem"/>
            </li>
            <li>
              <div class="pointer position-relative">
                <SvgIcon :name="'Warenkorb_grey'" @click="showCart()" size="2.2rem"/>
                <span v-if="this.$store.state.user.cart_course.length"
                      class="fs-badge position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  {{ this.$store.state.user.cart_course.length }}
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <h1 class="title d-md-none">
     
      </h1>
    </div>
  </div>

  <!--  Mobile Ansicht Tablet -->

  <div class="sub-header d-flex d-lg-none">
    <div class="container flex-row">
      <div class="profile-list d-flex">
        <img
            v-if="this.$store.state.user.avatar && this.$store.state.user.avatar != 'https://diegrueneschule.obs.eu-de.otc.t-systems.com'"
            class="pe-auto" :src="this.$store.state.user.avatar" @click="goHome()"/>
        <SvgIcon
            v-else
            :name="'person'"
            class="custom-svg pb-3"
            @click="goHome()"
            size="5rem"
        />
        <ul class="text-start">
          <li class="fz-25">Willkommen</li>
          <li class="fw-bold">{{ this.$store.state.user.first_name + ' ' + this.$store.state.user.last_name }}</li>
          <li class="fw-dark-yellow small" v-if="this.$store.state.user.dozent_id == ''">Schüler</li>
          <li class="fw-dark-yellow small" v-else>Dozent</li>
        </ul>
      </div>
      <div class="right-btns d-flex">
        <ul :class="menuClass">
          <li>
            <div class="mx-3">
              <SvgIcon :name="'Kommentar_grey'" size="3rem"/>
            </div>
            <div class="pointer mx-3 mb-1">
              <SvgIcon :name="'LogOut_grey'" @click="logOut()" size="3rem"/>
            </div>
            <div class="dark-icon pointer mx-3 position-relative">
              <SvgIcon :name="'Warenkorb_grey'" @click="showCart()" size="3rem"/>
              <span v-if="this.$store.state.user.cart_course.length"
                    class="fs-badge position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  {{ this.$store.state.user.cart_course.length }}
                </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!--  Mobile Ansicht -->

  <div class="mobile-header row">
    <div class="col-12 d-flex flex-row justify-content-end me-4">
      <div class="mx-3 pt-1">
        <SvgIcon :name="'Kommentar_grey'" size="4.2rem"/>
      </div>
      <div class="pointer mx-3">
        <SvgIcon :name="'LogOut_grey'" @click="logOut()" size="4.4rem"/>
      </div>
      <div class="pointer mx-3 pt-1 position-relative me-4">
        <SvgIcon :name="'Warenkorb_grey'" @click="showCart()" size="4.2rem"/>
        <span v-if="this.$store.state.user.cart_course.length"
              class="fs-badge position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  {{ this.$store.state.user.cart_course.length }}
                </span>

      </div>
    </div>

    <div class="col-12 mt-4">
      <div class="profile-list ms-4 mb-3 row justify-content-between">
        <div class="col-auto row">
          <div class="col-auto">
            <img
                v-if="this.$store.state.user.avatar && this.$store.state.user.avatar != 'https://diegrueneschule.obs.eu-de.otc.t-systems.com'"
                class="" :src="this.$store.state.user.avatar" @click="goHome()"/>
            <SvgIcon
                v-else
                :name="'person'"
                class="custom-svg pb-3"
                @click="goHome()"
                size="5rem"
            />
          </div>
          <div class="col-auto">
            <ul class="text-start">
              <li class="fs-2">Willkommen</li>
              <li class="fw-bold fs-3">{{
                  this.$store.state.user.first_name + ' ' + this.$store.state.user.last_name
                }}
              </li>
              <li class="fw-dark-yellow small fs-3" v-if="this.$store.state.user.dozent_id == ''">Schüler</li>
              <li class="fw-dark-yellow small fs-3" v-else>Dozent</li>
            </ul>
          </div>
        </div>
        <div class="col-auto">
          <button class="navbar-toggler mt-4 me-4" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarNavAltMarkup"
                  aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <Hamburger/>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import SvgIcon from "@/components/SvgIcon.vue";
import Hamburger from "./Hamburger.vue";

export default {
  components: {
    SvgIcon,
    Hamburger,
  },
  data() {
    return {
      showMenu: false,
      menuClass: 'mobile-nav',
    }
  },
  methods: {
    showSubmenu() {
      if (this.menuClass == 'mobile-nav') {
        this.menuClass = 'mobile-nav show';
      } else {
        this.menuClass = 'mobile-nav';
      }
    },
    logOut() {
      this.$confirm(
          "Möchten Sie sich wirklich abmelden?",
          "Ausloggen",
          {
            confirmButtonText: "Ausloggen",
            cancelButtonText: "Abbrechen",
            type: "warning",
          }
      )
          .then(() => {
            this.$store.dispatch("user/logout");
            if (typeof this.$store.state.user.token == 'undefined' || this.$store.state.user.token === '') {
              this.$router.push({name: 'login'});
            }
          })
          .catch(() => {
            console.log('canel');
          });
    },
    goHome() {
      this.$router.push({name: 'dashboard'});
    },
    showCart() {
      if (this.$store.state.user.cart_course.length) {
        this.$router.push({name: 'coursesCart'});
      } else {
        console.log('k co');
      }
    }
  }
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1.title {
  font-family: "Cavolini";
  color: #ffffff;
  font-weight: bold;
}

.dark-icon {
  padding: 0.4rem;
  background: #464b5c;
  border-radius: 0.5rem;
  margin-bottom: -0.4rem;
  transition: all 0.3s;
}

ul.text-start {
  padding-left: 0.5rem;
}

.header {
  background: #c4c08e;
  padding: 1rem 0;
}

.profile-list ul {
  margin-bottom: 0;
}

.sub-header {
  background: #ffffff;
  padding: 1rem 0;
}

.mobile-header {
  background: #FFFFFF;
  padding: 1rem 0;
}

.sub-header .profile-list img {
  height: 8rem;
  width: 8rem;
  border-radius: 4rem;
}

.sub-header .profile-list ul {
  margin: auto 1rem;
}

.sub-header .profile-list .fw-dark-yellow {
  color: #C4C08E;
  line-height: 2rem;
  font-size: 1.2rem;
  font-weight: bold;
}

.sub-header .profile-list li {
  font-size: 1.4rem;
}

.sub-header .profile-list li.fz-25 {
  font-size: 2rem;
}

.centered .logo {
  max-height: 8rem;
}

.right-btns {
  display: none;
  justify-content: center;
  margin-top: 1rem;
}

.right-btns * {
  display: flex;
  margin-top: auto;
  margin-bottom: 0;
}

.right-btns ul li {
  margin: 0.25rem 0.5rem;
  font-size: 1.8rem;
}

.right-btns ul li > i, .right-btns ul li > div {
  margin-left: 0.5rem;
}

.right-btns ul li input {
  background-image: url(/search_ico_dark.svg);
  background-repeat: no-repeat;
  background-position: 0.6rem;
  background-color: transparent;
  border: 1px solid #000000;
  padding: 0 0 0 1.9rem;
  background-size: 1rem;
  border-radius: 0.3rem;
  font-size: 1rem;
  height: 2rem;
}

.profile-list {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
}

/*.profile-list img,*/
/*.profile-list ul {*/
/*  margin-top: auto;*/
/*}*/

.profile-list img {
  height: 6rem;
  width: 6rem;
  border-radius: 3rem;
}

.header-row {
  margin: 1rem;
}

.right-btns .hamburger {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1rem;
}

.navbar-mobile .navbar-toggler {
  color: transparent;
  border-color: transparent;

}

.navbar-mobile {
  margin-top: 2rem;
  margin-right: 2rem;
  padding: 0.25rem 0.75rem;
}

.navbar-toggler {
  outline: 0;
}

.mobile-nav {
  visibility: hidden;
  display: none;
  width: 70px;
  flex-direction: column;
  transition: visibility 0.5s, width 0.5s linear;
}

.mobile-nav.show {
  width: 120px;
  visibility: visible;
  transition: visibility 0s, width 0.5s linear;
}

.form-control {
  transition: all 0.5s;
}

.fs-badge {
  font-size: 11px;
}

.dark-icon:hover {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
}

@media (max-width: 576px) {

  .sub-header {
    display: none !important;
  }

  .mobile-header {
    display: block;
  }

}

@media (min-width: 576px) {
  .mobile-header {
    display: none;
  }

  .right-btns {
    display: flex;
  }

  .mobile-nav {
    display: flex;
    visibility: visible;
    width: auto;
    flex-direction: row;
  }

  .right-btns ul li {
    margin: 0 0.4rem;
    font-size: 1.8rem;
  }
}

@media (min-width: 992px) {
  .header-row {
    display: flex;
    flex-direction: row;
    margin: 0;
  }

  .right-btns ul li i {
    color: #ffffff;
  }

  .right-btns ul li input {
    background-image: url(/search_ico.svg);
    color: #ffffff;
    border: 1px solid #ffffff;
  }
}

@media (max-width: 1399px) {
  .right-btns ul {
    padding-left: 0;
  }
}


@media (min-width: 1400px) {
  .header .right-btns ul li {
    margin: 0 0.5rem;
  }
}

@media (min-width: 1500px) {
  .container-custom {
    max-width: 90vw;
  }

  .container-custom .header-row {
    justify-content: space-between;
  }

  .container-custom .header-row .profile-list {
    flex-grow: 0;
  }

  .container-custom .header-row .centered {
    left: 10em;
    position: relative;
  }
}

.custom-svg {
  color: #464B5C;
  padding-bottom: 0.2rem;
  cursor: pointer;
}
</style>