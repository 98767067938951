<template>

  <div v-for="item in data" :key="item.id" class="inhalt mb-2 p-3 px-md-5">
    <div class="align-content-start row p-1 px-4">
      <span class="col-auto fs-4 p-0 fw-bold">
        {{ item.seminar }}
      </span>
    </div>

    <div class="row mt-2 px-3 px-md-4">
      <div class="col-4 col-lg-2 p-0">
        <div class="grey p-2 px-2 text-start me-2 h-38px">
          <span class="">
            Kursdaten
          </span>
        </div>
      </div>

      <div class="col-8 col-lg-4">
        <div class="me-lg-3 p-2 grey-border mb-2 h-38px">
          <span class="fw-bold">
            {{ item.kursdaten }}
          </span>
        </div>
      </div>

      <div class="col-4 col-lg-2 p-0">
        <div class="grey p-2 px-2 text-start me-2 mb-2 h-38px">
          <span class="font-size-mobile">
            Unterrichtszeit
          </span>
        </div>
      </div>

      <div class="col-8 col-lg-4">
        <div class="p-2 grey-border mb-2 h-38px">
          <span class="fw-bold">
            {{ item.unterrichtszeiten }} Uhr
          </span>
        </div>
      </div>
    </div>

    <!--    Zweite Zeile-->
    <div class="row px-3 px-md-4">
      <div class="col-4 col-lg-2 p-0">
        <div class="grey p-2 px-2 text-start me-2 h-38px">
          <span class="">
            Ort
          </span>
        </div>
      </div>

      <div class="col-8 col-lg-4">
        <div class="me-lg-3 p-2 grey-border mb-2 h-38px">
          <span class="fw-bold">
            {{ item.ort }}
          </span>
        </div>
      </div>

      <div class="col-4 col-lg-2 p-0">
        <div class="grey p-2 px-2 text-start me-2 mb-2 h-38px">
          <span class="">
            Raum
          </span>
        </div>
      </div>

      <div class="col-8 col-lg-4">
        <div class="p-2 grey-border mb-2 h-38px px-sm-3">
          <div class="fw-bold">
            <div v-if="item.raum.length >= 25" class="font-size-small">
              <span>
                {{ item.raum }}
              </span>
            </div>
            <div v-else>
              <span>
                {{ item.raum }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    dritte Zeile-->
    <div class="row px-3 px-md-4">
      <div class="col-4 col-lg-2 p-0">
        <div class="grey p-2 px-2 text-start me-2 align-middle h-38px">
          <span class="">
            Vor Ort/ Online
          </span>
        </div>
      </div>

      <div class="col-8 col-lg-4">
        <div class="me-lg-3 mb-2 grey-border p-2 h-38px fw-bold text-center" v-if="item.online == '1'">
          <div v-if="item.online_check_value == '1'">
            <span>Hybrid Veranstaltung</span>
          </div>
          <div v-else-if="item.online_check_value == '2'">
            <span>Online Veranstaltung</span>
          </div>
        </div>
        <div class="me-lg-3 mb-2 grey-border p-2 h-38px fw-bold text-center" v-else-if="item.online == '2'">
          <span>Online Veranstaltung</span>
        </div>
        <div class="me-lg-3 mb-2 grey-border p-2 h-38px fw-bold text-center" v-else-if="item.online == '3'">
          <span>Vor Ort Veranstaltung</span>
        </div>
      </div>

      <div class="col-4 col-lg-2 p-0">
        <div class="grey p-2 px-2 text-start me-2 mb-2 h-38px">
          <span class="">
            Zahlart
          </span>
        </div>
      </div>

      <div class="col-8 col-lg-4">
        <div class="p-2 grey-border mb-2 h-38px">
          <div class="fw-bold" v-if="item.payment_value === 'invoice'">
            <span>per Rechnung</span>
          </div>
          <div class="fw-bold" v-else-if="item.payment_value === 'ratenzahlung'">
            <span>per Ratenzahlung</span>
          </div>
          <div class="fw-bold" v-else-if="item.payment_value === 'credits'">
            <span>per Credits</span>
          </div>
        </div>
      </div>
    </div>

    <!--    vierte Zeile-->
    <div class="row px-3 px-md-4">
      <div class="col-4 col-lg-2 p-0">
        <div class="grey p-2 px-2 text-start me-2 h-38px">
          <span class="">
            Kosten
          </span>
        </div>
      </div>

      <div class="col-8 col-lg-4">
        <div class="me-lg-3 p-2 grey-border mb-2 h-38px">
          <div class="fw-bold">
            <div v-if="item.payment_value === 'invoice'">
              <div v-if="item.online_check_value == '1'">
                <span>
                  {{ formatPrice(item.gebuehr) }} €
                </span>
              </div>
              <div v-else-if="item.online_check_value == '2'">
                <span>
                  <div v-if="item.rabatt > 0">
                    {{ formatPrice(item.gebuehr - ((item.gebuehr * item.rabatt) / 100)) }} €
                  </div>
                  <div v-else>
                    {{ formatPrice(item.gebuehr) }} €
                  </div>
                </span>
              </div>
            </div>
            <div v-else-if="item.payment_value === 'ratenzahlung'">
              <div v-if="item.online_check_value == '1'">
                <span>
                  {{ item.rate_anzahl }} x {{ formatPrice(item.rate_monatlich) }} € = {{ formatPrice(item.rate_gesamt) }} €
                </span>
              </div>
              <div v-else-if="item.online_check_value == '2'">
                <span>
                  {{ item.rate_anzahl }} x {{ formatPrice(item.rate_monatlich - (item.rate_monatlich * item.rabatt / 100)) }} € = {{
                      formatPrice(item.rate_gesamt - (item.rate_gesamt * item.rabatt / 100))
                  }} €
                </span>
              </div>
            </div>
            <div v-else-if="item.payment_value == 'credits'">
              Credits: {{ item.credits }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <input class="w-75 h-38px fw-bold btn-color" type="button" value="Aus dem Warenkorb entfernen"
          @click="removeCart(item.id)">
      </div>
    </div>
  </div>

</template>

<script>
import { defineComponent } from 'vue';
//import { NumberFormat } from 'vue-i18n';

export default defineComponent({
  name: "CartListView",
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  methods: {
    removeCart(id) {
      this.$emit("onRemove", id);
    },
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
  }
})
</script>

<style scoped>
.inhalt {
  border-radius: 4rem;
  border: solid 1rem #464b5c;
}

.col-auto {
  padding-right: 0;
}

.grey {
  background-color: #464b5c;
  color: #FFFFFF;
}

.grey-border {
  border: solid #464b5c 1.5px;
}

.h-38px {
  height: 38px;
}

.btn-color {
  background-color: #c4c08e;
  border: #c4c08e;
}

.font-size-small {
  font-size: 10px;
}


@media (max-width: 767px) {
  .font-size-mobile {
    font-size: 7px;
  }
}

@media (max-width: 425px) {
  .dot {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
  }
}
</style>