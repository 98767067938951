<template>
  <div v-for="item in data" :key="item.ds_id">
      <img :src="item.portal_header" alt="header bild grüne schule" class="w-100">

      <div v-if="item.color === 'blue'" class="blue-background">
        <h1 class="font-Cavolini w-100 fs-2 fw-bolder">
          {{ item.name }}
        </h1>
      </div>
      <div v-else-if="item.color === 'green'" class="green-background">
        <h1 class="font-Cavolini w-100 fs-2 fw-bolder">
          {{ item.name }}
        </h1>
      </div>
      <div v-else-if="item.color === 'yellow'" class="yellow-background">
        <h1 class="font-Cavolini w-100 fs-2 fw-bolder">
          {{ item.name }}
        </h1>
      </div>
      <div v-else-if="item.color === 'red'" class="red-background">
        <h1 class="font-Cavolini w-100 fs-2 fw-bolder">
          {{ item.name }}
        </h1>
      </div>
      <div class="w-100 inhalt mt-4 two-col text-start p-5 fs-5">
        <p v-html="item.portal_beschreibung"></p>
      </div>
      <h2 class="font-Cavolini mt-5 fs-2 fw-bolder">
        Alles auf einen Blick
      </h2>
    <span v-if="item.ds_id != 69">
      <div class="row mt-3 px-4">
        <div class="col-12 col-lg-4 p-0 mt-3 mt-lg-0">
          <div class="inhalt mx-2 p-4 text-start align-top mb-1 h-100 fs-5">
            <b>
              Ausbildungsdauer
            </b>
          </div>
        </div>
        <div class="col-12 col-lg-8 p-0 mt-3 mt-lg-0">
          <div class="inhalt mx-2 p-4 fs-5 text-start">
            <p v-html="item.portal_ausbildungsdauer"></p>
          </div>
        </div>
      </div>

      <div class="row px-4 mt-2">
        <div class="col-12 col-lg-4 p-0 mt-3 mt-lg-0">
          <div class="inhalt mx-2 text-start align-top p-4 h-100 fs-5">
            <b>
              Kursbeginn
            </b>
          </div>
        </div>
        <div class="col-12 col-lg-8 p-0 mt-3 mt-lg-0">
          <div class="inhalt mx-2 p-4 fs-5 text-start">
            <p v-html="item.portal_kursbeginn"></p>
          </div>
        </div>
      </div>

      <div class="row px-4 mt-2">
        <div class="col-12 col-lg-4 p-0 mt-3 mt-lg-0">
          <div class="inhalt mx-2 text-start align-top p-4 h-100 fs-5">
            <b>
              Unterrichtszeit
            </b>
          </div>
        </div>
        <div class="col-12 col-lg-8 p-0 mt-3 mt-lg-0">
          <div class="inhalt mx-2 p-4 fs-5 text-start">
            <p v-html="item.portal_unterrichtszeit"></p>
          </div>
        </div>
      </div>

      <div class="row px-4 mt-2">
        <div class="col-12 col-lg-4 p-0 mt-3 mt-lg-0">
          <div class="inhalt mx-2 text-start align-top p-4 h-100 fs-5">
            <b>
              Unterrichtsstunden
            </b>
          </div>
        </div>
        <div class="col-12 col-lg-8 p-0 mt-3 mt-lg-0">
          <div class="inhalt mx-2 p-4 fs-5 text-start">
            <p v-html="item.portal_unterrichtsstunden"></p>
          </div>
        </div>
      </div>

      <div v-if="item.portal_optional_wichtige_info != ''">
        <div class="row px-4 mt-2">
          <div class="col-12 col-lg-4 p-0 mt-3 mt-lg-0">
            <div class="inhalt mx-2 text-start align-top p-4 h-100 fs-5">
              <b>
                Wichtige Informationen
              </b>
            </div>
          </div>
          <div class="col-12 col-lg-8 p-0 mt-3 mt-lg-0">
            <div class="inhalt mx-2 p-4 fs-5 text-start">
              <p v-html="item.portal_optional_wichtige_info"></p>
            </div>
          </div>
        </div>
      </div>

      <div v-if="item.portal_optional_weitere_info != ''">
        <div class="row px-4 mt-2">
          <div class="col-12 col-lg-4 p-0 mt-3 mt-lg-0">
            <div class="inhalt mx-2 text-start align-top p-4 h-100 fs-5">
              <b>
                Weitere Informationen
              </b>
            </div>
          </div>
          <div class="col-12 col-lg-8 p-0 mt-3 mt-lg-0">
            <div class="inhalt mx-2 p-4 fs-5 text-start">
              <p v-html="item.portal_optional_weitere_info"></p>
            </div>
          </div>
        </div>
      </div>

      <div v-if="item.portal_dropdown_aktivieren == 1" class="row px-4 mt-4">
        <div class="col-6">
          <div class="inhalt me-2 text-start p-4 fs-5">
            <div class="font-Cavolini">
              {{ item.portal_dropdown_links_ueberschrift }}
            </div>
            <div>
              <details>
                <summary class="red-bold fs-6 mt-2">Weiterlesen ...</summary>
                <p class="mt-3" v-html="item.portal_dropdown_links_text"></p>
              </details>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="inhalt ms-2 text-start p-4 fs-5">
            <div class="font-Cavolini">
              {{ item.portal_dropdown_rechts_ueberschrift }}
            </div>
            <div>
              <details>
                <summary class="red-bold fs-6 mt-2">Weiterlesen ...</summary>
                <p class="mt-3" v-html="item.portal_dropdown_rechts_text"></p>
              </details>
            </div>
          </div>
        </div>
      </div>

      <div v-if="item.portal_optional_bild1_desktop != ''">
        <div v-if="this.windowWidth < 769">
          <img :src="item.portal_optional_bild1_mobil" alt="opt_bild_mobil" class="w-100 mt-3">
        </div>
        <div v-else>
          <img :src="item.portal_optional_bild1_desktop" alt="opt_bild_desktop" class="w-100 mt-3">
        </div>
      </div>

      <div v-if="item.portal_optional_bild2_desktop != ''">
        <div v-if="this.windowWidth < 769">
          <img :src="item.portal_optional_bild2_mobil" alt="opt_bild_mobil" class="w-100 mt-3">
        </div>
        <div v-else>
          <img :src="item.portal_optional_bild2_desktop" alt="opt_bild_desktop" class="w-100 mt-3">
        </div>
      </div>

      <!--    Dozenten Ansicht startet-->
      <div class="mt-2 px-4 row justify-content-center">
        <CustomCard
            v-for="doz in dozenten"
            :key="doz.doz_id"
            :doz_data="doz"
            :dozenten_card="true"
          />
      </div>
    </span>
  </div>
</template>

<script>

import {defineComponent} from 'vue';
import CustomCard from "@/components/CustomCard.vue";

export default defineComponent({
  name: "OnlineGroupDetailView",
  components: {
    CustomCard
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    dozenten: {
      type: Array,
    }
  },
  data() {
    return {
      windowWidth: Window.innerWidth
    }
  }
});
</script>

<style scoped>

.blue-background {
  background-color: #2a3579;
  color: #FFFFFF;
  padding: 2rem 0 2rem 0;
}

.red-background {
  background-color: #bf2327;
  color: #FFFFFF;
  padding: 2rem 0 2rem 0;
}

.green-background {
  background-color: #007f49;
  color: #FFFFFF;
  padding: 2rem 0 2rem 0;
}

.yellow-background {
  background-color: #f49a01;
  color: #FFFFFF;
  padding: 2rem 0 2rem 0;
}

.inhalt {
  background-color: #FFFFFF;
  border-radius: 1rem;
}

.two-col {
  column-count: 2;
  column-gap: 2.5rem;
}

summary::after {
  content: "+";
  float: right;
  right: 1px;
}

details > summary {
  list-style: none;
}

details summary::-webkit-details-marker {
  display: none;
}

.red-bold {
  color: #bf2327;
  font-weight: bolder;
}

@media (max-width: 425px) {
  .two-col {
    column-count: 1;
    column-gap: 0rem;
  }

}

</style>