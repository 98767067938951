<template>
  <div class="row">
    <div class="col-12 col-lg-6">
      <div class="course__subtitle  full-width" v-text="title" />
    </div>
    <div class="col-12 col-lg-6 course__statistic justify-content-end">
      <span>
        <span class="btn-sm btn-default me-3">{{ credit }}</span>
        <span class="me-3"> Credits zur Verfügung</span>
      </span>
    </div>
  </div>
  <Table_noclick
    v-if="showTable"
    :data="data"
    :showSwitches="false"
    :columns="columns"
    :detailColumns="detailColumns"
  />
</template>
<script>
import Table_noclick from "@/components/Table_noclick.vue";
export default {
  components: {
    Table_noclick,
  },
  props: {
    title: {
      type: String,
      require: true,
    },
    data: {
      type: Array,
      require: true,
    },
    columns: {
      type: Array,
      require: true,
    },
    showTable: {
      type: Boolean,
      default: true,
    },
    credit: {
      type: Number,
      require: false,
      default: 0
    },
     credits_positiv: {
      type: Number,
      require: false,
      default: 0
    },
    credits_negativ: {
      type: Number,
      require: false,
      default: 0
    },
    detailColumns: {
      type: Array,
      require: false,
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.course__subtitle {
  font-size: 0.8rem;
  text-align: left;
  line-height: 0.9rem;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  background-color: #464B5C;
  color: #FFFFFF;
  font-weight: bold;
}
.course__statistic {
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding-top: 0.5rem;
  padding-left: 0.7rem;
}
.btn-default { 
  border: 1px solid #707070;
  padding: 0.2rem 0.4rem;
}
@media (min-width: 768px){
  html{
    font-size: 14px;
  }
}
@media (min-width: 992px){
  .course__statistic{
    padding: 0;
  }
}
@media (min-width: 1200px){
  html{
    font-size: 18px;
  }
}
</style>