<template>
  <div class="list">
    <div class="item" v-for="(item, ind) in data" :key="'rows-'+item.id +'-'+ ind">
      <ListItem :item="item" @action="onClick" :rightHeaders="rightHeaders" :hasSubcomment="hasSubcomment" :index="ind"/>
    </div>
    <div v-if="pagination != null && pagination.total > pagination.limit" class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        v-model:currentPage="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pagination.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import ListItem from "./ListItem.vue";
export default {
  name: "List",
  components: { ListItem },
  props: {
    data: {
      type: Array,
      required: true,
    },
    rightHeaders: {
      type: Boolean,
      default: false,
    },
    hasSubcomment: {
      type: Boolean,
      default: false,
    },
    action: {
      type: Function,
      default: null,
    },
    fetchData: {
      type: Function,
      default: null,
    },
    pagination: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      selectedIndex: -1,
      currentPage: 1,
    };
  },
  methods: {
    handleSizeChange(val) {
      this.$emit("fetchData", { ...this.pagination, limit: val });
    },
    handleCurrentChange(val) {
      this.$emit("fetchData", { ...this.pagination, page: val });
    },
    unSelected() {
      this.selectedIndex = -1;
    },
    onClick(column, index, id) {
      this.$emit("action", column, index, id);
    },
  },
};
</script>
<style lang="postcss" scoped>
.list .item:nth-child(odd) {
  background-color: #eeeeee;
}
.list .item {
  transition: all 0.3s;
  border-radius: 1rem;
}
.list .item:hover{
  background-color: #00000013;
}
</style>